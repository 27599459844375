import React, { Component } from 'react'
import { connect } from 'react-redux'
import './DiamondDetail.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { Tab, Nav } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import { fetchDiamondById } from '../../../redux/actions/DiamondActions';
import Spin from '@gull/components/Spin';
import { storageUrl } from 'environment/env';
import Image from 'app/GullLayout/SharedComponents/Image';
import { FILE_FORMATS, VIDEO_FORMATS } from '@utils'

export class DiamondDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diamondId: this.props?.match?.params?.stoneid,
            loading: false
        }
    }
    componentDidMount() {
        if (this.state.diamondId) {
            this.props.fetchDiamondById(this.state.diamondId);
        }
    }
    render() {
        const { loading, diamondDetails } = this.props
        let diamond = {};
        if (diamondDetails?.length) {
            diamond = diamondDetails[0];
        }
        return (
            <div className="diamond-page">
                <Breadcrumb
                    routeSegments={[
                        { name: "Diamond Details", path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={loading}>
                    <div className="row">
                        <div className="col-md-6">
                            <SimpleCard title="Supplier Info" className="mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Supplier Name</b></div>
                                            <div className="col-md-6">{diamond?.supplier_name ? diamond?.supplier_name : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Stone No</b></div>
                                            <div className="col-md-6">{diamond?.stone_id ? diamond?.stone_id : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Shape</b></div>
                                            <div className="col-md-6">{diamond?.shape ? diamond?.shape : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Color</b></div>
                                            <div className="col-md-6">{diamond?.color ? diamond?.color : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Clarity</b></div>
                                            <div className="col-md-6">{diamond?.clarity ? diamond?.clarity : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Carat</b></div>
                                            <div className="col-md-6">{diamond?.carat ? diamond?.carat : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Cut</b></div>
                                            <div className="col-md-6">{diamond?.cut ? diamond?.cut : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Polish</b></div>
                                            <div className="col-md-6">{diamond?.polish ? diamond?.polish : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Symmetry</b></div>
                                            <div className="col-md-6">{diamond?.symmetry ? diamond?.symmetry : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Fluorescence</b></div>
                                            <div className="col-md-6">{diamond?.fluo ? diamond?.fluo : '-'}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Rap</b></div>
                                            <div className="col-md-6">{diamond?.rap ? diamond?.rap : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Discount</b></div>
                                            <div className="col-md-6">{diamond?.discount ? diamond?.discount : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Price/Carat</b></div>
                                            <div className="col-md-6">{diamond?.price ? diamond?.price : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Amount</b></div>
                                            <div className="col-md-6">{diamond?.total_amount ? diamond?.total_amount : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Lab</b></div>
                                            <div className="col-md-6">{diamond?.lab ? diamond?.lab : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleCard>
                            <SimpleCard title="Additional Info" className="mb-3">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6"><b>Certificate No</b></div>
                                        <div className="col-md-6">{diamond?.certificate_no ? diamond?.certificate_no : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Location</b></div>
                                        <div className="col-md-6">{diamond?.location ? diamond?.location : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Measurement</b></div>
                                        <div className="col-md-6">{diamond?.measurement ? diamond?.measurement : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Depth</b></div>
                                        <div className="col-md-6">{diamond?.depth ? diamond?.depth : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Table</b></div>
                                        <div className="col-md-6">{diamond?.table ? diamond?.table : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Ratio</b></div>
                                        <div className="col-md-6">{diamond?.ratio ? diamond?.ratio : '-'}</div>
                                    </div>
                                </div>
                            </SimpleCard>
                        </div>
                        <div className="col-md-6">
                            <SimpleCard title="Jinagna's Pricing" className="mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Discount</b></div>
                                            <div className="col-md-6">{diamond?.j_discount ? diamond?.j_discount : '-'} %</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Price/Carat</b></div>
                                            <div className="col-md-6">{diamond?.j_price ? diamond?.j_price : '-'}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Total Amount</b></div>
                                            <div className="col-md-6">{diamond?.j_total_amount ? diamond?.j_total_amount : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleCard>
                            <SimpleCard>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                    <Nav variant="pills" className="d-flex px-2">
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Video</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.CERTI}>Certificate</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE} className="text-center">
                                            {
                                                !!diamond?.img_url && FILE_FORMATS.find(i => { return diamond?.img_url ? diamond?.img_url.includes(i) : false })
                                                    ? <Image src={`${storageUrl}${diamond?.img_url}`} class="img-fluid" alt={diamond?.stone_id} />
                                                    : !!diamond?.img_url ? <iframe title="image" src={diamond?.img_url} width={'100%'} style={{ height: '60vh' }} /> : <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                            }

                                        </Tab.Pane>
                                        <Tab.Pane eventKey={MEDIA_TAB.VIDEO} className="text-center">
                                            {
                                                !!diamond?.video_url && VIDEO_FORMATS.find(i => { return diamond?.video_url ? diamond?.video_url.includes(i) : false })
                                                    ? <video width={200} muted className="img-fluid" src={`${storageUrl}${diamond?.video_url}`} autoPlay loop />
                                                    : !!diamond?.video_url ? <iframe title="video" src={diamond?.video_url} width={'100%'} style={{ height: '60vh' }} /> : <img width="100px" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                            }
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={MEDIA_TAB.CERTI} className="text-center">
                                            {
                                                !!diamond?.certi_url && FILE_FORMATS.find(i => { return diamond?.certi_url ? diamond?.certi_url.includes(i) : false })
                                                    ? <Image src={`${storageUrl}${diamond?.certi_url}`} class="img-fluid" alt={diamond?.stone_id} />
                                                    : !!diamond?.certi_url ? <iframe title="certi" src={!!diamond?.certi_url.includes('.pdf') ? `${storageUrl}${diamond?.certi_url}` :diamond?.certi_url} width={'100%'} style={{ height: '60vh' }} /> : <img width="100px" class="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                            }
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </SimpleCard>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    diamondDetails: state.diamond.diamondDetails,
    loading: state.diamond.loading,
})

const mapDispatchToProps = {
    fetchDiamondById
}

export default connect(mapStateToProps, mapDispatchToProps)(DiamondDetail)
