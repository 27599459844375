import React, { Component } from 'react'
import { Breadcrumb } from '@gull'
import { connect } from 'react-redux';
import './AdminLayoutStyle.scss';
import { fetchLayouts, deleteLayouts } from '../../../redux/actions/DiamondActions';
import ReactPaginate from 'react-paginate';
import Spin from '@gull/components/Spin';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Table from 'app/GullLayout/SharedComponents/table/Table';
import AppModal from 'app/GullLayout/SharedComponents/modal/Modal';

class AdminLayouts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            columns: [
                {
                    title: 'Details',
                    dataIndex: 'Details',
                    render: (data, row) => (<>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Edit
                            </Tooltip>
                            }
                        >
                            <Link to={`/layout/form/${btoa(row.id)}`}>
                                <i className="far fa-edit mr-2" />
                            </Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Delete
                            </Tooltip>
                            }
                        >
                            <i className={`fa fa-trash`} style={{ cursor: 'pointer' }} onClick={e => this.onDeleteLayout(row)}></i>

                        </OverlayTrigger>
                    </>)
                },
                {
                    title: 'Shape',
                    dataIndex: 'shape'
                },
                {
                    title: 'Weight in Cts.',
                    dataIndex: 'weight_cts'
                },
                {
                    title: 'Size in Cts.',
                    dataIndex: 'size_cts'
                },
                {
                    title: 'Color',
                    dataIndex: 'color'
                },
                {
                    title: 'Clarity',
                    dataIndex: 'clarity'
                },
                {
                    title: 'Lab',
                    dataIndex: 'lab'
                },
                {
                    title: 'Floro.',
                    dataIndex: 'floro'
                },
                {
                    title: 'Length in Cms.',
                    dataIndex: 'length_cms'
                },
                {
                    title: 'No. of Pcs.',
                    dataIndex: 'no_of_pcs'
                },
                {
                    title: 'Price$',
                    dataIndex: 'price'
                },
                {
                    title: 'Discount%',
                    dataIndex: 'discount'
                },
                {
                    title: 'Total$',
                    dataIndex: 'total'
                },
            ],
            deleteLoading: false,
            isOpenDeleteBox: false,
            pageNo: 1
        }

        this.props.fetchLayouts(1)
    }

    onPageChange = page => {
        this.setState({
            pageNo: page
        })
        this.props.fetchLayouts((page + 1))
    }

    onDeleteLayout = (row) => {
        this.setState({
            isOpenDeleteBox: true,
            selectedLayout: row
        })
    }

    confirmDeleteAction = () => {
        this.setState({
            deleteLoading: true
        }, () => {
            const payload = {
                id: btoa(this.state.selectedLayout.id)
            }
            this.props.deleteLayouts(payload).then(resp => {
                this.setState({
                    deleteLoading: false,
                    isOpenDeleteBox: false
                })
                this.props.fetchLayouts(this.state.pageNo)
            })
        })
    }

    render() {
        const { loading, LayoutList, currentPage, totalRow, perPage } = this.props;
        const { columns, deleteLoading, isOpenDeleteBox } = this.state;
        const buttonList = [
            <Button
                key={1}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/layout/form')}
            >
                Add New Layout
          </Button>
        ]
        const buttonListmerge = [
            <Button
                key={1}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/layout/form')}
            >
                Add New Layout
          </Button>
        ]
        let switchlist = []
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="admin-layout">
                <Breadcrumb
                    routeSegments={[
                        { name: "Layout", path: "/layout" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <div className="row">
                    <div className="col-12">
                        <Spin loading={loading}>
                            <Table
                                dataSource={LayoutList}
                                bordered={true}
                                columns={columns}
                                paginate={false}
                            />
                        </Spin>
                    </div>
                </div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
                <AppModal
                    show={isOpenDeleteBox}
                    title={'Alert!'}
                    size="md"
                    onHide={() => this.setState({ isOpenDeleteBox: false })}
                    footer={
                        <Spin loading={deleteLoading}>
                            <Button variant={`outline-primary`} onClick={(e) => this.setState({ isOpenDeleteBox: false })} className="ml-1">NO</Button>
                            <Button className="ml-1" onClick={e => this.confirmDeleteAction()}>YES</Button>
                        </Spin>
                    }
                >
                    Are you sure, you want to delete selected layout?
                </AppModal>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loading: state.diamond.loadingLayouts,
    LayoutList: state.diamond.layoutList?.data,
    currentPage: state.diamond.layoutList?.current_page,
    totalRow: state.diamond.layoutList?.total,
    perPage: state.diamond.layoutList?.per_page,
    error: state.diamond.layoutError,
});
const mapDispatchToProps = {
    fetchLayouts,
    deleteLayouts
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayouts);