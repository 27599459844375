import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdminUsersStyle.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { Tab, Nav, Badge } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import { fetchUsers, createNewUser, updateNewUser, fetchUserEnquiry, fetchUserOrders } from '../../../redux/actions/UserActions';
import { fetchCountries, fetchStates, fetchCities } from '../../../redux/actions/MiscActions';
import Spin from '@gull/components/Spin';
import { Formik } from "formik";
import * as yup from "yup";
import moment from 'moment';
import { storageUrl } from 'environment/env';
import AppModal from 'app/GullLayout/SharedComponents/modal/Modal';
import Table from 'app/GullLayout/SharedComponents/table/Table';
import { Link } from 'react-router-dom';
import Image from 'app/GullLayout/SharedComponents/Image';

const UserFormSchema = yup.object().shape({
    "name": yup
        .string()
        .required("Name is required"),
    "username": yup
        .string()
        .required("Username is required"),
    "email": yup
        .string()
        .required("Email is required"),
    "mobile_no": yup
        .string()
        .required("Mobile No is required"),
    "whatsapp_no": yup
        .string()
        .required("Whatsapp No is required"),
    "company_name": yup
        .string()
        .required("Company Name is required"),
    "company_address1": yup
        .string()
        .required("Company Address1 is required"),
    "company_address2": yup
        .string(),
    "company_city_id": yup
        .string()
        .required("Company City is required"),
    "company_zipcode": yup
        .string()
        .required("Company Zipcode is required"),
});

class UserDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid: this.props?.match?.params?.userid,
            formLoading: false,
            formData: {
                "name": "",
                "username": "",
                "email": "",
                "mobile_no": "",
                "whatsapp_no": "",
                "profile_img": "",
                "company_name": "",
                "company_address1": "",
                "company_address2": "",
                "company_zipcode": "",
                "company_profile_img": "",
                "company_city_id": ""
            },
            columns: [

                {
                    title: 'Stone No',
                    dataIndex: 'stone_id',
                    render: (data, row) => (data)
                },
                {
                    title: 'Shape',
                    dataIndex: 'shape',
                },
                {
                    title: 'Carat',
                    dataIndex: 'carat',
                },
                {
                    title: 'Color',
                    dataIndex: 'color',
                },
                {
                    title: 'Clarity',
                    dataIndex: 'clarity',
                },
                // {
                //     title: 'Finish',
                //     dataIndex: 'Finish',
                // },
                {
                    title: 'Cut',
                    dataIndex: 'cut',
                },
                {
                    title: 'Polish',
                    dataIndex: 'polish',
                },
                {
                    title: 'Sym',
                    dataIndex: 'symmetry',
                },
                {
                    title: 'Fluo.',
                    dataIndex: 'fluo',
                },
                {
                    title: 'Lab',
                    dataIndex: 'lab',
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                },
                {
                    title: 'Discount',
                    dataIndex: 'discount',
                },
                {
                    title: 'Price/Ct.',
                    dataIndex: 'price',
                },
                {
                    title: 'Total Amt.',
                    dataIndex: 'total_amount',
                },
                {
                    title: 'Measurements',
                    dataIndex: 'measurement',
                },
                {
                    title: 'Table%',
                    dataIndex: 'table',
                },
                {
                    title: 'Depth%',
                    dataIndex: 'depth',
                },
                // {
                //     title: 'Ratio',
                //     dataIndex: 'ratio',
                // },
                {
                    title: 'Certificate No.',
                    dataIndex: 'certificate_no',
                },
            ],
            loading: false,
            openDiamondModal: false,
            selectedRow: []
        }
        this.props.fetchUserEnquiry({ userid: atob(this.state.userid), isLatestRecord: true, recordLimit: 5 })
        this.props.fetchUserOrders({ userid: atob(this.state.userid), isLatestRecord: true, recordLimit: 5 })
    }

    componentDidMount() {
        this.props.fetchCountries({})
        if (this.state.userid) {
            this.props.fetchUsers({ id: this.state.userid });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userData !== prevProps.userData) {
            const { userData } = this.props
            let user = {};
            if (userData?.length) {
                user = userData[0];
                this.props.fetchStates({ countryId: user.company_country.id })
                this.props.fetchCities({ stateId: user.company_state.id })
                this.setState({
                    formData: {
                        ...this.state.formData,
                        "name": user.name ? user.name : "",
                        "username": user.username ? user.username : "",
                        "email": user.email ? user.email : "",
                        "mobile_no": user.mobile_no ? user.mobile_no : "",
                        "whatsapp_no": user.whatsapp_no ? user.whatsapp_no : "",
                        "profile_img": user.profile_img ? user.profile_img : "",
                        "company_name": user.company_name ? user.company_name : "",
                        "company_address1": user.company_address1 ? user.company_address1 : "",
                        "company_address2": user.company_address2 ? user.company_address2 : "",
                        "company_zipcode": user.company_zipcode ? user.company_zipcode : "",
                        "company_profile_img": user.company_profile_img ? user.company_profile_img : "",
                        "company_city_id": user.company_city.id ? user.company_city.id : "",
                        "company_state_id": user.company_state.id ? user.company_state.id : "",
                        "company_country_id": user.company_country.id ? user.company_country.id : ""
                    }
                })
            }
        }
    }
    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {
            const payload = {
                ...that.state.formData,
                ...value,
            }

            if (that.state.userid) {
                payload['id'] = atob(that.state.userid)
                payload['updated_at'] = moment()
            }

            const resp = that.state.userid ? that.props.updateNewUser : that.props.createNewUser
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/users')
                    })
                } else {
                    that.setState({
                        formLoading: false
                    })
                }
            })
                .catch(e => {
                    console.error(e);
                    that.setState({
                        formLoading: false
                    })
                });
        })
    };

    onUploadFinish = (url, type) => {
        let key = "profile_img";
        switch (type) {
            case "profile": key = "profile_img"; break;
            case "company": key = "company_profile_img"; break;
            default: key = "profile_img";
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: url,
            }
        })
    }
    handleFormValueChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            console.log(this.props.errors);
            if (e.target.name === 'company_country_id') {
                this.props.fetchStates({ countryId: e.target.value })
            }
            if (e.target.name === 'company_state_id') {
                this.props.fetchCities({ stateId: e.target.value })
            }
        })

    }

    toggleDiamondModal = (row) => {
        this.setState({
            openDiamondModal: !this.state.openDiamondModal,
            selectedRow: row
        })
    }
    render() {
        const { ordersData, ordersLoading, enquiryData, enquiryLoading, loading, countryList, stateList, cityList, countryloading, cityloading, stateloading } = this.props
        const { formLoading, selectedRow, openDiamondModal, columns } = this.state
        return (
            <div className="user-details">
                <Breadcrumb
                    routeSegments={[
                        { name: `User Detail`, path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={(loading | formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={UserFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Personal Info" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Name</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.name}
                                                                        placeholder="Name"
                                                                        readOnly
                                                                    />
                                                                    {(errors.name && touched.name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Username</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="username"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.username}
                                                                        placeholder="Username"
                                                                        readOnly
                                                                    />
                                                                    {(errors.username && touched.username) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.username}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Email</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        placeholder="Email"
                                                                        readOnly
                                                                    />
                                                                    {(errors.email && touched.email) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.email}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Mobile No</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="mobile_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.mobile_no}
                                                                        placeholder="Mobile No"
                                                                        readOnly
                                                                    />
                                                                    {(errors.mobile_no && touched.mobile_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.mobile_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Whatsapp No</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="whatsapp_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.whatsapp_no}
                                                                        placeholder="Whatsapp No"
                                                                        readOnly
                                                                    />
                                                                    {(errors.whatsapp_no && touched.whatsapp_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.whatsapp_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard title="Company Info" className="mb-3">
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Company Name</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_name}
                                                                        readOnly
                                                                        placeholder="Company Name"
                                                                    />
                                                                    {(errors.company_name && touched.company_name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Company Zipcode</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_zipcode"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_zipcode}
                                                                        placeholder="Company Zipcode"
                                                                        readOnly
                                                                    />
                                                                    {(errors.company_zipcode && touched.company_zipcode) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_zipcode}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Company Address 1</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_address1"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_address1}
                                                                        placeholder="Company Address"
                                                                        readOnly
                                                                    />
                                                                    {(errors.company_address1 && touched.company_address1) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_address1}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Company Address 2</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_address2"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_address2}
                                                                        placeholder="Company Address 2"
                                                                        readOnly
                                                                    />
                                                                    {(errors.company_address2 && touched.company_address2) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_address2}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-4"><b>Country</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_country_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_country_id}
                                                                        disabled
                                                                    >
                                                                        <option>{countryloading ? 'Loading..' : 'Select Country'}</option>
                                                                        {
                                                                            countryList && countryList.map(country => (
                                                                                <option value={country.id}>{country.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_country_id && touched.company_country_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_country_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>State</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_state_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_state_id}
                                                                        disabled
                                                                    >
                                                                        <option>{stateloading ? 'Loading..' : 'Select State'}</option>
                                                                        {
                                                                            stateList && stateList.map(state => (
                                                                                <option value={state.id}>{state.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_state_id && touched.company_state_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_state_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4"><b>City</b></div>
                                                            <div className="col-md-8">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_city_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_city_id}
                                                                        disabled
                                                                    >
                                                                        <option>{cityloading ? 'Loading..' : 'Select City'}</option>
                                                                        {
                                                                            cityList && cityList.map(city => (
                                                                                <option value={city.id}>{city.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_city_id && touched.company_city_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_city_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        <div className="col-md-6">
                                            <SimpleCard className="mb-3">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                                    <Nav variant="pills" className="d-flex px-2">
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Profile</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Company Profile</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">

                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                                                            <Image width={150} src={`${storageUrl}${this.state.formData?.profile_img}`} alt={this.state.formData?.name} />
                                                            {/* <FileUpload initalData={this.state.formData?.profile_img} onUploadFinish={this.onUploadFinish} filepath="profile" /> */}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={MEDIA_TAB.VIDEO}>
                                                            <Image width={150} src={`${storageUrl}${this.state.formData?.company_profile_img}`} alt={this.state.formData?.company_name} />
                                                            {/* <FileUpload initalData={this.state.formData?.company_profile_img} onUploadFinish={this.onUploadFinish} filepath="profile" /> */}
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </SimpleCard>
                                            <SimpleCard title="Recent Enquery Info" className="mb-3">
                                                <div className="scroller">
                                                    <Spin loading={enquiryLoading}>
                                                        {
                                                            !!enquiryData?.length && <div key={0} className="d-flex border justify-content-between bg-primary text-white p-3 mr-3 ml-3 mb-1" >
                                                                <div className="flex-grow-1 w-50">
                                                                    <span className="text-small">
                                                                        Remarks
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <span className="text-small">
                                                                        Created On
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            enquiryData?.length
                                                                ? enquiryData.map(item => (
                                                                    <div key={item.id} className="d-flex border justify-content-between p-3 mr-3 ml-3 mb-1" >
                                                                        <div className="flex-grow-1 w-50" style={{ cursor: 'pointer' }} onClick={e => this.toggleDiamondModal(item.diamonds)}>
                                                                            <span className="text-small text-primary">
                                                                                {item.remark}
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <span className="text-small">
                                                                                {item.created_at}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                : (
                                                                    <div className="d-flex justify-content-center" >
                                                                        <div className="text-center">
                                                                            <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                                                        </div>
                                                                    </div>
                                                                )
                                                        }
                                                    </Spin>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard title="Recent Order Info" className="mb-3">
                                                <div className="scroller">
                                                    <Spin loading={ordersLoading}>
                                                        {
                                                            !!ordersData?.length && <div key={0} className="d-flex border justify-content-between bg-primary text-white p-3 mr-3 ml-3 mb-1" >
                                                                <div className="flex-grow-1 w-50">
                                                                    <span className="text-small">
                                                                        Order ID
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1 w-50">
                                                                    <span className="text-small">
                                                                        Stone ID
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1 w-50">
                                                                    <span className="text-small">
                                                                        Status
                                                                    </span>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <span className="text-small">
                                                                        Created On
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            ordersData?.length
                                                                ? ordersData.map(item => (
                                                                    <div key={item.id} className="d-flex border justify-content-between p-3 mr-3 ml-3 mb-1" >
                                                                        <div className="flex-grow-1 w-50">
                                                                            <span className="text-small">
                                                                                <Link to={{
                                                                                    pathname: '/orders',
                                                                                    state: { orderId: item.id }
                                                                                }}>
                                                                                    {item.id}
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-grow-1 w-50">
                                                                            <span className="text-small">
                                                                                {item.diamonds?.length ? item.diamonds[0].stone_id : '-'}
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-grow-1 w-50">
                                                                            <span className="text-small">
                                                                                {
                                                                                    Number(item.status) === 1
                                                                                        ? <Badge key={1} className={`badge-warning text-white p-2`}>
                                                                                            {'PENDING'}
                                                                                        </Badge>
                                                                                        : Number(item.status) === 3
                                                                                            ? <Badge key={0} className={`badge-danger p-2`}>
                                                                                                {'REJCTED'}
                                                                                            </Badge>
                                                                                            : Number(item.status) === 4
                                                                                                ? <Badge key={4} className={`badge-danger p-2`}>
                                                                                                    {'CANCELED'}
                                                                                                </Badge>
                                                                                                : <Badge key={2} className={`badge-success p-2`}>
                                                                                                    {'ACCEPTED'}
                                                                                                </Badge>
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <span className="text-small">
                                                                                {item.created_at}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                : (
                                                                    <div className="d-flex justify-content-center" >
                                                                        <div className="text-center">
                                                                            <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                                                        </div>
                                                                    </div>
                                                                )
                                                        }
                                                    </Spin>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Spin>
                <AppModal
                    show={openDiamondModal}
                    title={'Diamond List'}
                    size="lg"
                    onHide={() => this.setState({ openDiamondModal: false })}
                >
                    <Table
                        dataSource={selectedRow}
                        bordered={true}
                        columns={columns}
                        paginate={false}
                    />
                </AppModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userList?.data,
    loading: state.user.loading,
    enquiryData: state.user.userEnquiryData,
    enquiryLoading: state.user.userEnquiryLoading,
    ordersData: state.user.userOrdersData?.data,
    ordersLoading: state.user?.userOrdersLoading,
    user: state.user,
    countryloading: state.misc?.countryloading,
    countryList: state.misc?.countryList,
    stateloading: state.misc?.stateloading,
    stateList: state.misc?.stateList,
    cityloading: state.misc?.cityloading,
    cityList: state.misc?.cityList,
})

const mapDispatchToProps = {
    fetchUsers,
    createNewUser,
    updateNewUser,
    fetchCountries,
    fetchStates,
    fetchCities,
    fetchUserEnquiry,
    fetchUserOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);