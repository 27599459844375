import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdminUsersStyle.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { Tab, Nav, Button } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import { fetchUsers, createNewUser, updateNewUser } from '../../../redux/actions/UserActions';
import { fetchCountries, fetchStates, fetchCities } from '../../../redux/actions/MiscActions';
import Spin from '@gull/components/Spin';
import { Formik } from "formik";
import * as yup from "yup";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import moment from 'moment';

const UserFormSchema = yup.object().shape({
    "name": yup
        .string()
        .required("Name is required"),
    "username": yup
        .string()
        .required("Username is required"),
    "email": yup
        .string()
        .required("Email is required"),
    "mobile_no": yup
        .string()
        .required("Mobile No is required"),
    "whatsapp_no": yup
        .string()
        .required("Whatsapp No is required"),
    "company_name": yup
        .string()
        .required("Company Name is required"),
    "company_address1": yup
        .string()
        .required("Company Address1 is required"),
    "company_address2": yup
        .string(),
    "company_city_id": yup
        .string()
        .required("Company City is required"),
    "company_zipcode": yup
        .string()
        .required("Company Zipcode is required"),
});

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userid: this.props?.match?.params?.userid,
            formLoading: false,
            formData: {
                "name": "",
                "username": "",
                "email": "",
                "mobile_no": "",
                "whatsapp_no": "",
                "profile_img": "",
                "company_name": "",
                "company_address1": "",
                "company_address2": "",
                "company_zipcode": "",
                "company_profile_img": "",
                "company_city_id": ""
            },
            loading: false
        }
    }

    componentDidMount() {
        this.props.fetchCountries({})
        if (this.state.userid) {
            this.props.fetchUsers({ id: this.state.userid });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userData !== prevProps.userData) {
            const { userData } = this.props
            let user = {};
            if (userData?.length) {
                user = userData[0];
                this.props.fetchStates({ countryId: user.company_country.id })
                this.props.fetchCities({ stateId: user.company_state.id })
                this.setState({
                    formData: {
                        ...this.state.formData,
                        "name": user.name ? user.name : "",
                        "username": user.username ? user.username : "",
                        "email": user.email ? user.email : "",
                        "mobile_no": user.mobile_no ? user.mobile_no : "",
                        "whatsapp_no": user.whatsapp_no ? user.whatsapp_no : "",
                        "profile_img": user.profile_img ? user.profile_img : "",
                        "company_name": user.company_name ? user.company_name : "",
                        "company_address1": user.company_address1 ? user.company_address1 : "",
                        "company_address2": user.company_address2 ? user.company_address2 : "",
                        "company_zipcode": user.company_zipcode ? user.company_zipcode : "",
                        "company_profile_img": user.company_profile_img ? user.company_profile_img : "",
                        "company_city_id": user.company_city.id ? user.company_city.id : "",
                        "company_state_id": user.company_state.id ? user.company_state.id : "",
                        "company_country_id": user.company_country.id ? user.company_country.id : ""
                    }
                })
            }
        }
    }
    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {

            const payload = {
                ...that.state.formData,
                ...value,
            }

            if (that.state.userid) {
                payload['id'] = atob(that.state.userid)
                payload['updated_at'] = moment()
            } else {
                const phoneCode = that.props.countryList.find(item => Number(item.id) === Number(that.state.formData.company_country_id))?.phonecode
                const whatsapp_no = `+${phoneCode}${value.whatsapp_no}`
                const mobile_no = `+${phoneCode}${value.mobile_no}`
                payload['whatsapp_no'] = whatsapp_no;
                payload['mobile_no'] = mobile_no;
            }

            const resp = that.state.userid ? that.props.updateNewUser : that.props.createNewUser
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/users')
                    })
                } else {
                    that.setState({
                        formLoading: false
                    })
                }
            })
                .catch(e => {
                    console.error(e);
                    that.setState({
                        formLoading: false
                    })
                });
        })
    };

    onUploadFinish = (url, type) => {
        let key = "profile_img";
        switch (type) {
            case "profile": key = "profile_img"; break;
            case "company": key = "company_profile_img"; break;
            default: key = "profile_img";
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: url,
            }
        })
    }
    handleFormValueChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            console.log(this.props.errors);
            if (e.target.name === 'company_country_id') {
                this.props.fetchStates({ countryId: e.target.value })
            }
            if (e.target.name === 'company_state_id') {
                this.props.fetchCities({ stateId: e.target.value })
            }
        })

    }
    render() {
        const { loading, countryList, stateList, cityList, countryloading, cityloading, stateloading } = this.props
        const { formLoading, userid } = this.state
        return (
            <div className="diamond-form">
                <Breadcrumb
                    routeSegments={[
                        { name: `${userid ? 'Edit' : 'Add'} User`, path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={(loading | formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={UserFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Personal Info" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Full Name</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.name}
                                                                        placeholder="Name"
                                                                    />
                                                                    {(errors.name && touched.name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Username</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="username"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.username}
                                                                        placeholder="Username"
                                                                    />
                                                                    {(errors.username && touched.username) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.username}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Email</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        placeholder="Email"
                                                                    />
                                                                    {(errors.email && touched.email) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.email}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Password</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="password"
                                                                        name="password"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.password}
                                                                        placeholder="Password"
                                                                    />
                                                                    {(errors.password && touched.password) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.password}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Mobile No</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="mobile_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.mobile_no}
                                                                        placeholder="Mobile No"
                                                                    />
                                                                    {(errors.mobile_no && touched.mobile_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.mobile_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Whatsapp No</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="whatsapp_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.whatsapp_no}
                                                                        placeholder="Whatsapp No"
                                                                    />
                                                                    {(errors.whatsapp_no && touched.whatsapp_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.whatsapp_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard title="Company Info" className="mb-3">
                                                <div className="row">

                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Company Name</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_name}
                                                                        placeholder="Company Name"
                                                                    />
                                                                    {(errors.company_name && touched.company_name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Company Zipcode</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_zipcode"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_zipcode}
                                                                        placeholder="Company Zipcode"
                                                                    />
                                                                    {(errors.company_zipcode && touched.company_zipcode) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_zipcode}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Company Address 1</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_address1"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_address1}
                                                                        placeholder="Company Address"
                                                                    />
                                                                    {(errors.company_address1 && touched.company_address1) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_address1}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Company Address 2</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="company_address2"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_address2}
                                                                        placeholder="Company Address 2"
                                                                    />
                                                                    {(errors.company_address2 && touched.company_address2) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_address2}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Country</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_country_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_country_id}
                                                                    >
                                                                        <option>{countryloading ? 'Loading..' : 'Select Country'}</option>
                                                                        {
                                                                            countryList && countryList.map(country => (
                                                                                <option value={country.id}>{country.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_country_id && touched.company_country_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_country_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>State</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_state_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_state_id}
                                                                    >
                                                                        <option>{stateloading ? 'Loading..' : 'Select State'}</option>
                                                                        {
                                                                            stateList && stateList.map(state => (
                                                                                <option value={state.id}>{state.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_state_id && touched.company_state_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_state_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>City</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="company_city_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.company_city_id}
                                                                    >
                                                                        <option>{cityloading ? 'Loading..' : 'Select City'}</option>
                                                                        {
                                                                            cityList && cityList.map(city => (
                                                                                <option value={city.id}>{city.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.company_city_id && touched.company_city_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.company_city_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        <div className="col-md-6">
                                            <SimpleCard className="mb-3">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                                    <Nav variant="pills" className="d-flex px-2">
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Profile</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Company Profile</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">

                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                                                            <FileUpload initalData={this.state.formData?.profile_img} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="profile" fileSizeLimitInMb={2} />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={MEDIA_TAB.VIDEO}>
                                                            <FileUpload initalData={this.state.formData?.company_profile_img} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="profile" fileSizeLimitInMb={2} />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </SimpleCard>
                                        </div>
                                    </div>
                                    <div className="sticky-footer">
                                        <div className="container">
                                            <div className="">
                                                <Button
                                                    key={'primary3'}
                                                    variant={`primary`}
                                                    className="btn-rounded m-1 text-capitalize"
                                                    style={{ float: 'right' }}
                                                    type="submit"
                                                >
                                                    Upload User
                                                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userData: state.user.userList?.data,
    loading: state.user.loading,
    user: state.user,
    countryloading: state.misc?.countryloading,
    countryList: state.misc?.countryList,
    stateloading: state.misc?.stateloading,
    stateList: state.misc?.stateList,
    cityloading: state.misc?.cityloading,
    cityList: state.misc?.cityList,
})

const mapDispatchToProps = {
    fetchUsers,
    createNewUser,
    updateNewUser,
    fetchCountries,
    fetchStates,
    fetchCities
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);