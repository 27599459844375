import React, { Component } from "react";
import { SimpleCard } from "@gull";
import Spin from "@gull/components/Spin";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Link } from "react-router-dom";
import Axios from "axios";
import { apiUrl } from "environment/env";
import { NotificationManager } from "react-notifications";

class CheckEnquiry extends Component {
    state = {
        columns: [
            // {
            //     title: 'Details',
            //     dataIndex: 'Details',
            //     render: (data, row) => (<><i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.VIDEO }) }} className="i-Video-5"></i>&nbsp;<i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.IMAGE }) }} className="i-Camera"></i>&nbsp;<i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.CERTI }) }} className="i-Loading-3"></i></>)
            // },
            {
                title: 'Stone Id',
                dataIndex: 'stone_id',
                render: (data) => (<Link to={`/diamond/${window.btoa(data)}`}>{data}</Link>)
            },
            {
                title: 'Shape',
                dataIndex: 'shape',
            },
            {
                title: 'Carat',
                dataIndex: 'carat',
            },
            {
                title: 'Color',
                dataIndex: 'color',
            },
            {
                title: 'Clarity',
                dataIndex: 'clarity',
            },
            // {
            //     title: 'Finish',
            //     dataIndex: 'Finish',
            // },
            {
                title: 'Cut',
                dataIndex: 'cut',
            },
            {
                title: 'Polish',
                dataIndex: 'polish',
            },
            {
                title: 'Symmentry',
                dataIndex: 'symmetry',
            },
            {
                title: 'Fluo.',
                dataIndex: 'fluo',
            },
            {
                title: 'Lab',
                dataIndex: 'lab',
            },
            {
                title: 'Location',
                dataIndex: 'location',
            },
            {
                title: 'Price/Ct.',
                dataIndex: 'price',
            },
            {
                title: 'Total Amt.',
                dataIndex: 'total_amount',
            },
            {
                title: 'Measurements',
                dataIndex: 'measurement',
            },
            {
                title: 'Table%',
                dataIndex: 'table',
            },
            {
                title: 'Depth%',
                dataIndex: 'depth',
            },
            // {
            //     title: 'Ratio',
            //     dataIndex: 'ratio',
            // },
            {
                title: 'Certificate No.',
                dataIndex: 'certificate_no',
            },
        ],
        loading: true,
        UserData: { name: 'Guest' }
    };

    componentDidMount() {
        Axios({
            url: `${apiUrl}public/check/enquiry`,
            method: 'GET',
            params: {
                userid: this.props.match.params.enquiryId
            }
        }).then(resp => {
            this.setState({
                loading: false,
                DiamondList: resp.data.data,
                UserData: resp.data.user[0] ? resp.data.user[0] : { name: 'Guest' }
            })
        }).catch(error => {
            const errMsg = (error.response.data.error);
            NotificationManager.error(errMsg)
            this.setState({
                loading: false
            })
        })
    }
    render() {
        const { DiamondList, columns, loading, UserData } = this.state;
        return (
            <div className="m-5 mt-3">
                <Spin loading={loading}>
                    <SimpleCard title="Enquiry Report" subtitle={`Enquiry created by ${UserData.name}`}>
                        <Table
                            dataSource={DiamondList}
                            bordered={true}
                            columns={columns}
                        />
                    </SimpleCard>
                </Spin>
            </div>
        );
    }
}

export default CheckEnquiry;
