/* eslint-disable */ 
import React, { Fragment, useState, useEffect } from "react";
import './styles.scss';

const Switch = (props) => {
    const [isActive, setIsActive] = useState(props.value);
    useEffect(() => {
        setIsActive(props.value);
    }, [props.value]);

    const onChange = e => {
        const currVal = !isActive;
        setIsActive((currVal))
        props.onChange(currVal)
    }

    return (
        <div className="switch">
            <div className="toggle">
                <input type="radio" checked={!isActive} onChange={e => onChange(e)} id="choice1" name="choice" value="grid" />
                <label className="icon" htmlFor="choice1"> <i className="i-Posterous" /> </label>
                <input type="radio" checked={isActive} onChange={e => onChange(e)} id="choice2" name="choice" value="list" />
                <label className="icon" htmlFor="choice2"> <i className="i-Align-Justify-All" /> </label>
                <div id="flap"><span className="content"> {isActive ? <i className="i-Align-Justify-All" /> : <i className="i-Posterous" />} </span></div>
            </div>
        </div>
    );
};

export default Switch;