import React from "react";
import { Redirect } from "react-router-dom";
// import dashboardRoutes from "./views/dashboard/dashboardRoutes";
// import uiKitsRoutes from "./views/ui-kits/uiKitsRoutes";
// import formsRoutes from "./views/forms/formsRoutes";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
// import widgetsRoute from "./views/widgets/widgetsRoute";
// import chartsRoute from "./views/charts/chartsRoute";
// import dataTableRoute from "./views/dataTable/dataTableRoute";
// import extraKitsRoutes from "./views/extra-kits/extraKitsRoutes";
// import pagesRoutes from "./views/pages/pagesRoutes";
// import iconsRoutes from "./views/icons/iconsRoutes";
// import invoiceRoutes from "./views/app/invoice/invoiceRoutes";
// import inboxRoutes from "./views/app/inbox/inboxRoutes";
// import chatRoutes from "./views/app/chat/chatRoutes";
// import calendarRoutes from "./views/app/calendar/calendarRoutes";
// import taskManagerRoutes from "./views/app/task-manager/taskManagerRoutes";
// import ecommerceRoutes from "./views/app/ecommerce/ecommerceRoutes";
// import contactRoutes from "./views/app/contact/contactRoutes";
import { authRoles } from "./auth/authRoles";

// import Dashboard from "./views/dashboard/dashboard/Dashboard";
// import SearchReport from "./views/pages/search-report/SearchReport";
import Profile from "./views/pages/profile/Profile";
import Login from "./views/pages/login/login";
import CheckEnquiry from "./views/pages/check-enquiry/CheckEnquiry";
import { PublicDiamondDetail } from "./views/pages/PublicDiamondDetail";
import AdminDashboard from "./views/dashboard/admin-dashboard/AdminDashboard";
import AdminDiamonds from "./views/pages/admin-diamonds/AdminDiamonds";
import AdminLayouts from "./views/pages/admin-layouts/AdminLayouts";
import DiamondForm from "./views/pages/admin-diamonds/DiamondForm";
import LayoutForm from "./views/pages/admin-layouts/LayoutForm";
import AdminUsers from "./views/pages/admin-users/AdminUsers";
import UserForm from "./views/pages/admin-users/UserForm";
import UserDetail from "./views/pages/admin-users/UserDetail";
import AdminEnquiries from "./views/pages/admin-enquiries/AdminEnquiries";
import AdminOrders from "./views/pages/admin-orders/AdminOrders";
import DiamondDetail from "./views/pages/diamond-detail/DiamondDetail";
import AdminUserDemands from "./views/pages/admin-user-demands/AdminUserDemands";
import SearchDiamond from "./views/pages/search-diamond/SearchDiamond";
import UserApprovalList from "./views/pages/admin-users/UserApprovalList";
import AdminJewellery from "./views/pages/admin-jewellery/AdminJewellery";
import AdminCategory from "./views/pages/admin-category/AdminCategory";
import JewelleryForm from "./views/pages/admin-jewellery/JewelleryForm";
import AdminUploadVideo from "./views/pages/admin-upload-videos/AdminUploadVideo";
import Settings from "./views/pages/settings/Settings";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/404" />
  }
];

const publicRoutes = [
  {
    path: "/check/enquiry/:enquiryId",
    component: CheckEnquiry,
  },
  {
    path: "/diamond-details/:stoneid",
    component: PublicDiamondDetail,
  },
]

// const userRoutes = [
//   {
//     path: "/dashboard",
//     component: Dashboard,
//     auth: authRoles.user
//   },
//   {
//     path: "/search",
//     component: () => <SearchDiamond searchType="Search" />,
//     auth: authRoles.user
//   },
//   {
//     path: "/fancy",
//     component: () => <SearchDiamond searchType="Fancy" />,
//     auth: authRoles.user
//   },
//   {
//     path: "/report",
//     component: SearchReport,
//     auth: authRoles.user
//   },
//   {
//     path: "/diamond/:stoneid",
//     component: DiamondDetail,
//     auth: authRoles.user
//   },
//   {
//     path: "/recommended",
//     component: () => <SearchReport pageTitle={'Recommended Diamond'} />,
//     auth: authRoles.user
//   },
//   {
//     path: "/new-diamonds",
//     component: () => <SearchReport pageTitle={'New Stock'} />,
//     auth: authRoles.user
//   },
//   {
//     path: "/best-diamonds",
//     component: () => <SearchReport pageTitle={'Best of Jinagna'} />,
//     auth: authRoles.user
//   },
//   {
//     path: "/profile",
//     component: Profile,
//     auth: authRoles.user
//   },
//   {
//     path: "/layout",
//     component: LayoutPage,
//     auth: authRoles.user
//   },
//   {
//     path: "/compare",
//     component: Compare,
//     auth: authRoles.user
//   },
// ]

const adminRoutes = [
  {
    path: "/dashboard",
    component: AdminDashboard,
    auth: authRoles.admin,
  },
  {
    path: "/settings",
    component: Settings,
    auth: authRoles.admin,
  },
  {
    path: "/upload/videos",
    component: AdminUploadVideo,
    auth: authRoles.admin,
  },
  {
    path: "/enquiries",
    component: AdminEnquiries,
    auth: authRoles.admin
  },
  {
    path: "/orders",
    component: AdminOrders,
    auth: authRoles.admin
  },
  {
    path: "/demands",
    component: AdminUserDemands,
    auth: authRoles.admin
  },
  {
    path: "/search/diamonds",
    component: () =>  <SearchDiamond searchType="Search,Admin" />,
    auth: authRoles.admin
  },
  {
    path: "/diamonds",
    component: AdminDiamonds,
    auth: authRoles.admin
  },
  {
    path: "/diamond/form/:stoneid?",
    component: DiamondForm,
    auth: authRoles.admin
  },
  {
    path: "/diamond/:stoneid",
    component: DiamondDetail,
    auth: authRoles.admin
  },
  {
    path: "/layouts",
    component: AdminLayouts,
    auth: authRoles.admin
  },
  {
    path: "/layout/form/:layoutid?",
    component: LayoutForm,
    auth: authRoles.admin
  },
  {
    path: "/users",
    component: AdminUsers,
    auth: authRoles.admin
  },
  {
    path: "/user/approval",
    component: UserApprovalList,
    auth: authRoles.admin
  },
  {
    path: "/user/form/:userid?",
    component: UserForm,
    auth: authRoles.admin
  },
  {
    path: "/user/:userid",
    component: UserDetail,
    auth: authRoles.admin
  },
  {
    path: "/profile",
    component: Profile,
    auth: authRoles.admin
  },
  {
    path: "/jewellery/form/:id?",
    component: JewelleryForm,
    auth: authRoles.admin
  },
  {
    path: "/jewellery",
    component: AdminJewellery,
    auth: authRoles.admin
  },
  {
    path: "/category",
    component: AdminCategory,
    auth: authRoles.admin
  },
]

const routes = [
  ...sessionsRoutes,
  ...publicRoutes,
  {
    path: "/login",
    component: Login
  },
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...adminRoutes,

      // ...dashboardRoutes,
      // ...uiKitsRoutes,
      // ...formsRoutes,
      // ...widgetsRoute,
      // ...chartsRoute,
      // ...dataTableRoute,
      // ...extraKitsRoutes,
      // ...pagesRoutes,
      // ...iconsRoutes,
      // ...invoiceRoutes,
      // ...inboxRoutes,
      // ...chatRoutes,
      // ...taskManagerRoutes,
      // ...calendarRoutes,
      // ...ecommerceRoutes,
      // ...contactRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
