import React, { Component } from 'react'
import { ReactComponent as Cushion } from './Cushion.svg';
import { ReactComponent as CushionB } from './Cushion-B.svg';
import { ReactComponent as Emerald } from './Emerald.svg';
import { ReactComponent as Heart } from './Heart.svg';
import { ReactComponent as Marquise } from './Marquise.svg';
import { ReactComponent as Oval } from './Oval.svg';
import { ReactComponent as Pear } from './Pear.svg';
import { ReactComponent as Princess } from './Princess.svg';
import { ReactComponent as Radiant } from './Radiant.svg';
import { ReactComponent as Round } from './Round.svg';
import { ReactComponent as Lradiant } from './L Radiant.svg';
import { ReactComponent as SqEmerald } from './Sq Emerald.svg';
import { ReactComponent as SqRadiant } from './Sq Radiant.svg';

export default class DiamondShape extends Component {
    render() {
        switch (this.props.name) {
            case 'Cushion': return <Cushion />
            case 'Cushion B': return <CushionB />
            case 'Emerald': return <Emerald />
            case 'Heart': return <Heart />
            case 'Marquise': return <Marquise />
            case 'Oval': return <Oval />
            case 'Pear': return <Pear />
            case 'Princess': return <Princess />
            case 'Radiant': return <Radiant />
            case 'Round': return <Round />
            case 'L Radiant': return <Lradiant />
            case 'Sq Emerald': return <SqEmerald />
            case 'Sq Radiant': return <SqRadiant />
            default: return <></>
        }
    }
}
