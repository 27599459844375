import React, { Component } from 'react'
import { Breadcrumb } from '@gull';
import { Tab, Nav, Accordion } from "react-bootstrap";
import Table from 'app/GullLayout/SharedComponents/table/Table';
import { connect } from 'react-redux';
import { fetchUserEnquiry } from '../../../redux/actions/UserActions';
import ReactPaginate from 'react-paginate';
import Spin from '@gull/components/Spin';
import RightArrowAccordion from '@gull/components/accordions/RightArrowAccordion';

class AdminEnquiries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            columns: [
                {
                    title: 'Supplier Name',
                    dataIndex: 'supplier_name'
                },
                {
                    title: 'Stone No',
                    dataIndex: 'stone_id',
                },
                {
                    title: 'Shape',
                    dataIndex: 'shape',
                },
                {
                    title: 'Carat',
                    dataIndex: 'carat',
                },
                {
                    title: 'Color',
                    dataIndex: 'color',
                },
                {
                    title: 'Clarity',
                    dataIndex: 'clarity',
                },
                // {
                //     title: 'Finish',
                //     dataIndex: 'Finish',
                // },
                {
                    title: 'Cut',
                    dataIndex: 'cut',
                },
                {
                    title: 'Polish',
                    dataIndex: 'polish',
                },
                {
                    title: 'Sym',
                    dataIndex: 'symmetry',
                },
                {
                    title: 'Fluo.',
                    dataIndex: 'fluo',
                },
                {
                    title: 'Lab',
                    dataIndex: 'lab',
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                },
                {
                    title: 'Supplier Discount',
                    dataIndex: 'discount',
                },
                {
                    title: 'Supplier Price/Ct.',
                    dataIndex: 'price',
                },
                {
                    title: 'Supplier Total Amt.',
                    dataIndex: 'total_amount',
                },
                {
                    title: 'Jinagna Discount',
                    dataIndex: 'j_discount',
                },
                {
                    title: 'Jinagna Price/Ct.',
                    dataIndex: 'j_price',
                },
                {
                    title: 'Jinagna Total Amt.',
                    dataIndex: 'j_total_amount',
                },
                {
                    title: 'Jinagna Retail Discount',
                    dataIndex: 'retail_discount',
                },
                {
                    title: 'Jinagna Retail Price/Ct.',
                    dataIndex: 'retail_price',
                },
                {
                    title: 'Jinagna Retail Total Amt.',
                    dataIndex: 'retail_total_amount',
                },
                {
                    title: 'Measurements',
                    dataIndex: 'measurement',
                },
                {
                    title: 'Table%',
                    dataIndex: 'table',
                },
                {
                    title: 'Depth%',
                    dataIndex: 'depth',
                },
                {
                    title: 'Ratio',
                    dataIndex: 'ratio',
                },
                {
                    title: 'Certificate No.',
                    dataIndex: 'certificate_no',
                },
            ],
        }
        const payload = { page: 1 }
        if (this.props.location?.state?.enquiryId)
            payload['id'] = this.props.location.state.enquiryId
        this.props.fetchUserEnquiry(payload, true)
    }
    onPageChange = page => {
        this.props.fetchUserEnquiry({ page: (page + 1) }, true)
    }
    render() {
        const { columns } = this.state;
        const { enquiryData, enquiryLoading, currentPage, totalRow, perPage } = this.props;
        return this.state.windowWidth > 600 ? (
            <div className="admin-enquiry">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Enquiry List', path: "/enquiries" }
                    ]}
                    extra={[]}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={[]}
                ></Breadcrumb>
                <Spin loading={enquiryLoading}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                        <div className="row mb-5">
                            <div className="col-md-3 p-0">
                                <Nav variant="pills" className="d-flex" style={{ height: '60vh', overflowY: 'scroll' }}>
                                    {
                                        enquiryData?.length !== 0
                                            ? enquiryData?.map((item, index) => {

                                                return <Nav.Item className="w-100" key={item.id}>
                                                    <Nav.Link style={{ color: 'black' }} eventKey={index}>
                                                        <p className="m-0 p-0 text-primary">{item.user[0].name}</p>
                                                        <p className="m-0 p-0">Whatsapp No: {item.user[0].whatsapp_no}</p>
                                                        <span className="text-black">Remark:</span> {item.remark}
                                                        <p className="m-0 p-0 text-primary">{item.created_at}</p>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            })
                                            : <Nav.Item className="w-100" key={1}>
                                                <Nav.Link style={{ color: 'black' }} eventKey={1}>
                                                    <div className="text-center m-5">
                                                        <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                                    </div>
                                                </Nav.Link>
                                            </Nav.Item>
                                    }
                                </Nav>
                            </div>
                            <div className="col-md-9">
                                <Tab.Content className="p-0">
                                    {
                                        enquiryData?.length !== 0
                                            ? enquiryData?.map((item, index) => {

                                                return <Tab.Pane key={item.id} eventKey={index}>
                                                    <Table
                                                        dataSource={item.diamonds}
                                                        bordered={true}
                                                        columns={columns}
                                                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                                        paginate={false}
                                                    />
                                                </Tab.Pane>
                                            })
                                            : <div className="text-center m-5">
                                                <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                            </div>
                                    }
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                    <div className="sticky-footer">
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <ReactPaginate
                                    previousLabel={<i className="i-Previous"></i>}
                                    nextLabel={<i className="i-Next1"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={5}
                                    onPageChange={(page) => this.onPageChange(page.selected)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        ) : (
                <div className="admin-enquiry">
                    <Breadcrumb
                        routeSegments={[
                            { name: 'Enquiry List', path: "/enquiries" }
                        ]}
                        extra={[]}
                        patchingclass={'pad-2rem-petch'}
                        switchlist_bc={[]}
                    ></Breadcrumb>
                    <Spin loading={enquiryLoading}>
                        <Accordion className="mb-5">
                            {enquiryData?.map((item, index) => (
                                <RightArrowAccordion
                                    key={index}
                                    title={<div> <p className="m-0 p-0">Created By: {item.user[0].name}</p><p className="m-0 p-0">Whatsapp No: {item.user[0].whatsapp_no}</p> <p className="m-0 p-0">Remarks: {item.remark} </p> </div>}
                                    eventKey={item.id}
                                    className="mb-3"
                                >
                                    <Table
                                        dataSource={item.diamonds}
                                        width={'100%'}
                                        bordered={true}
                                        columns={columns}
                                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                        paginate={false}
                                    />
                                </RightArrowAccordion>
                            ))}
                        </Accordion>
                        <div className="sticky-footer">
                            <div className="container">
                                <div className="d-flex justify-content-center mt-3 mb-3">
                                    <ReactPaginate
                                        previousLabel={<i className="i-Previous"></i>}
                                        nextLabel={<i className="i-Next1"></i>}
                                        breakLabel={"..."}
                                        breakClassName={"break-me"}
                                        pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={5}
                                        onPageChange={(page) => this.onPageChange(page.selected)}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                        forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            )
    }
}
const mapStateToProps = (state) => ({
    enquiryData: state.user?.userEnquiryData?.data ? state.user?.userEnquiryData?.data.length ? state.user?.userEnquiryData?.data : Object.keys(state.user?.userEnquiryData?.data).map(item => (state.user?.userEnquiryData?.data[item])) : [],
    enquiryLoading: state.user?.userEnquiryLoading,
    currentPage: state.user?.userEnquiryData?.current_page,
    totalRow: state.user?.userEnquiryData?.total,
    perPage: state.user?.userEnquiryData?.per_page,
})

const mapDispatchToProps = {
    fetchUserEnquiry
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminEnquiries);