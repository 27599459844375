/* eslint-disable */
import React, { Component } from "react";
import './searchreport.scss';
import { Breadcrumb, Loading } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, Form, Tab, Nav } from "react-bootstrap";
// import { Button, Form, Tab, Tabs, Nav } from "react-bootstrap";
import Switch from "app/GullLayout/SharedComponents/switch/Switch";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import { fetchFeaturedDiamond, fetchRecommendedDiamond, fetchSearchedDiamond, fetchNewDiamond, createDiamondEnquiry, createDiamondPlaceOrder } from '../../../redux/actions/DiamondActions';
import { NotificationManager } from "react-notifications";
import { Formik } from "formik";
import * as yup from "yup";
import { OpenNewWindow } from "@utils";
import { whatsappNo } from "environment/env";
import localStorageService from "app/services/localStorageService";

export const MEDIA_TAB = Object.freeze({
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    CERTI: 'CERTIFICATE'
})

const EnquirySchema = yup.object().shape({
    remark: yup
        .string()
        .required("remarks is required")
});

class SearchReport extends Component {
    state = {
        windowWidth: window.innerWidth,
        columns: [
            {
                title: 'Details',
                dataIndex: 'Details',
                render: (data, row) => (<><i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.VIDEO }) }} className="i-Video-5"></i>&nbsp;<i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.IMAGE }) }} className="i-Camera"></i>&nbsp;<i onClick={e => { this.setState({ selectedRow: row, openModal: true, selectedKey: MEDIA_TAB.CERTI }) }} className="i-Loading-3"></i></>)
            },
            {
                title: 'Stone Id',
                dataIndex: 'stone_id',
                render: (data, row) => (<Link to={`/diamond/${window.btoa(row.id)}`}>{data}</Link>)
            },
            {
                title: 'Shape',
                dataIndex: 'shape',
            },
            {
                title: 'Carat',
                dataIndex: 'carat',
            },
            {
                title: 'Color',
                dataIndex: 'color',
            },
            {
                title: 'Clarity',
                dataIndex: 'clarity',
            },
            // {
            //     title: 'Finish',
            //     dataIndex: 'Finish',
            // },
            {
                title: 'Cut',
                dataIndex: 'cut',
            },
            {
                title: 'Polish',
                dataIndex: 'polish',
            },
            {
                title: 'Symmentry',
                dataIndex: 'symmetry',
            },
            {
                title: 'Fluo.',
                dataIndex: 'fluo',
            },
            {
                title: 'Lab',
                dataIndex: 'lab',
            },
            {
                title: 'Location',
                dataIndex: 'location',
            },
            {
                title: 'Discount',
                dataIndex: 'discount',
            },
            {
                title: 'Price/Ct.',
                dataIndex: 'price',
            },
            {
                title: 'Total Amt.',
                dataIndex: 'total_amount',
            },
            {
                title: 'Measurements',
                dataIndex: 'measurement',
            },
            {
                title: 'Table%',
                dataIndex: 'table',
            },
            {
                title: 'Depth%',
                dataIndex: 'depth',
            },
            // {
            //     title: 'Ratio',
            //     dataIndex: 'ratio',
            // },
            {
                title: 'Certificate No.',
                dataIndex: 'certificate_no',
            },
        ],
        orderListColumns: [
            {
                title: 'Stone Id',
                dataIndex: 'stone_id'
            },
            {
                title: 'Shape',
                dataIndex: 'shape',
            },
            {
                title: 'Carat',
                dataIndex: 'carat',
            },
            {
                title: 'Color',
                dataIndex: 'color',
            },
            {
                title: 'Clarity',
                dataIndex: 'clarity',
            },
            {
                title: 'Price/Ct.',
                dataIndex: 'price',
            },
            {
                title: 'Total Amt.',
                dataIndex: 'total_amount',
            }
        ],
        isListView: true,
        openModal: false,
        openEnquiryModal: false,
        openPlaceOrderModal: false,
        selectedList: [],
        pageTitle: 'Report',
        isDiamondSelected: false,
        enquiryPayload: {
            remark: ''
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            pageTitle: this.props.pageTitle ? this.props.pageTitle : this.state.pageTitle,
        }, () => {
            this.getDiamondDataByScreen(this.state.pageTitle)
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.enquiryData !== this.props.enquiryData) {
            this.props.enquiryData.map(item => {
                if (item.success) {
                    NotificationManager.success(this.state.wasEnquiry ? 'Enquiry Saved Successfully.' : 'Order Placed Successfully.');
                    if (this.state.wasEnquiry) {
                        const msg = `Hi, I am ${localStorageService.getItem('auth_user').name}, I have send a enquiry for diamonds, Please check below link\
                        ${window.location.origin}/check/enquiry/${window.btoa(localStorageService.getItem('auth_user').id)}`;
                        const link = window.encodeURI(`https://api.whatsapp.com/send?phone=${whatsappNo}&text=${msg}&app_absent=true`)
                        OpenNewWindow(link)
                    } else {
                    }
                    NotificationManager.error(this.state.wasEnquiry ? 'Failed to Saved enquiry. Please try again' : 'Failed to place Order. Please try again');
                }
            })
            this.setState({
                openEnquiryModal: false,
                openPlaceOrderModal: false
            })
        }
    }

    getDiamondDataByScreen = type => {
        switch (type) {
            case 'Recommended Diamond':
                this.props.fetchRecommendedDiamond()
                break;
            case 'New Stock':
                this.props.fetchNewDiamond()
                break;
            case 'Best of Jinagna':
                this.props.fetchFeaturedDiamond()
                break;
            default:
                const { filters } = this.props.location.state ? this.props.location.state : { filters: {} }
                this.props.fetchSearchedDiamond(1, filters)
                break;
        }
    }

    onPageChange = page => {
        const { filters } = this?.props?.location?.state
        this.props.fetchSearchedDiamond((page + 1), filters ? filters : {})
    }

    showSelectedDiamonds = isShowing => {
        let isDiamondSelected = !isShowing
        if (this.state.selectedList.length === 0) {
            NotificationManager.warning(
                "Please select any diamond to show",
                "Warning",
                5000
            );
            isDiamondSelected = false
        }

        this.setState({
            isDiamondSelected
        })
    }

    showCompareDiamonds = e => {
        if (this.state.selectedList.length === 0) {
            NotificationManager.warning(
                "Please select any diamond to compare",
                "Warning",
                5000
            );
            return
        }
        if (this.state.selectedList.length <= 1) {
            NotificationManager.warning(
                "Please select minimum 2 diamond to compare",
                "Warning",
                5000
            );
            return
        }

        this.props.history.push('/compare', { diamonds: this.state.selectedList })
    }

    onRowSelected = (isChecked, diamond) => {
        let selectedList = this.state.selectedList
        if (isChecked) {
            selectedList.push(diamond)
        } else {
            selectedList = selectedList.filter(item => item.id !== diamond.id);
        }
        this.setState({
            selectedList
        })
    }

    openEnquiryModal = () => {
        if (this.state.selectedList.length === 0) {
            NotificationManager.warning(
                "Please select 1 or more diamond",
                "Warning",
                5000
            );
            return
        }

        this.setState({
            openEnquiryModal: true
        })
    }

    openPlaceOrderModal = () => {
        if (this.state.selectedList.length === 0) {
            NotificationManager.warning(
                "Please select 1 or more diamond",
                "Warning",
                5000
            );
            return
        }

        this.setState({
            openPlaceOrderModal: true
        })
    }

    handleEnquirySubmit = (value, { isSubmitting }) => {
        const payload = {
            "remark": value.remark,
            "diamondIdWithAmount": this.state.selectedList.map(item => (item.id))
        }
        this.setState({
            wasEnquiry: true
        }, () => this.props.createDiamondEnquiry(payload))
    }
    handlePlaceOrderSubmit = (value, { isSubmitting }) => {
        const payload = {
            "remark": value.remark,
            "diamondIdWithAmount": this.state.selectedList.map(item => ({ id: item.id, amount: item.total_amount }))
        }
        this.setState({
            wasEnquiry: false
        }, () => this.props.createDiamondPlaceOrder(payload))
    }

    render() {
        const { orderListColumns, openPlaceOrderModal, enquiryPayload, pageTitle, columns, isListView, openModal, selectedKey, selectedRow, isDiamondSelected, selectedList, openEnquiryModal } = this.state;
        const { loading, diamondList, currentPage, totalRow, perPage } = this.props;
        const DiamondList = isDiamondSelected ? selectedList : diamondList;
        const buttonList = [
            <Button
                key={1}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                style={{ float: 'right' }}
                onClick={e => this.openPlaceOrderModal()}
            >
                Place Order
          </Button>,
            <Button
                key={2}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                onClick={e => this.openEnquiryModal()}
            >
                Enquiry
            </Button>,
            <Button
                key={3}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                onClick={e => this.props.history.push('/search', { filters: this?.props?.location?.state?.filters })}
            >
                Modify Search
            </Button>,
            <Button
                key={4}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                onClick={e => this.showSelectedDiamonds(isDiamondSelected)}
            >
                {isDiamondSelected ? 'Hide' : 'Show'} Selected
            </Button>,
            <Button
                key={5}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                onClick={e => this.showCompareDiamonds()}
            >
                Compare
            </Button>
        ]
        const buttonListmerge = [
            <Button
                key={1}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                style={{ float: 'right' }}
                onClick={e => this.openPlaceOrderModal()}
            >
                Place Order
          </Button>,
            <Button
                key={2}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                onClick={e => this.openEnquiryModal()}
            >
                Enquiry
            </Button>,
            <Button
                key={3}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                onClick={e => this.props.history.push('/search', { filters: this?.props?.location?.state?.filters })}
            >
                Modify Search
            </Button>,
            <Button
                key={4}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                onClick={e => this.showSelectedDiamonds(isDiamondSelected)}
            >
                {isDiamondSelected ? 'Hide' : 'Show'} Selected
            </Button>,
            <Button
                key={5}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                onClick={e => this.showCompareDiamonds()}
            >
                Compare
            </Button>
        ]
        var switchlist = [
            <Switch key={0} value={isListView} onChange={val => this.setState({ isListView: val })} />
        ]
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="report">
                <Breadcrumb
                    routeSegments={[
                        { name: pageTitle, path: "/report" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                {
                    isListView
                        ? <div className="row pad-2rem-petch">
                            <div className="col-lg-12 col-md-12">
                                <Spin loading={loading}>
                                    <Table
                                        dataSource={DiamondList}
                                        bordered={true}
                                        columns={columns}
                                        isCheckbox={true}
                                        onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                        paginate={false}
                                        selectedRows={selectedList}
                                    />
                                </Spin>
                            </div>
                        </div>
                        : <div className="row">
                            {
                                DiamondList?.length ? DiamondList.map(diamond => (
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card mb-4 o-hidden">
                                            <img
                                                className="card-img-top"
                                                src="https://c4.wallpaperflare.com/wallpaper/118/384/933/diamond-heart-wallpaper-preview.jpg"
                                                alt=""
                                            />
                                            <div class="card-img-overlay text-right">
                                                <Form.Check
                                                    name="checkbox1"
                                                    onChange={e => this.onRowSelected(e.target.checked, diamond)}
                                                    type="checkbox"
                                                    checked={selectedList.find(d => d.stone_id === diamond.stone_id)}
                                                    value={diamond}
                                                />
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <label className="title">
                                                            <Link to={`/diamond/${window.btoa(diamond.stone_id)}`}>
                                                                <b>{diamond.stone_id}</b>
                                                            </Link>
                                                        </label>
                                                    </div>
                                                    <div className="col-4 text-right set-color">
                                                        <span className="text-cirle-dot"></span> <b>{diamond.lab}</b>
                                                    </div>
                                                    <div className="col-12 small-desc">
                                                        <b>{diamond.carat} - Carat {diamond.shape}</b>
                                                    </div>
                                                    <div className="col-12 small-desc">
                                                        {diamond.color} {diamond.clarity} {diamond.cut} {diamond.polish} {diamond.symmetry}
                                                    </div>
                                                    <div className="col-8 mt-3">
                                                        $ {diamond.total_amount}
                                                    </div>
                                                    <div className="col-4 text-right mt-3">
                                                        <Link to={`/diamond/${window.btoa(diamond.stone_id)}`} >More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                    : <div className="col-lg-3 col-md-3 offset-lg-4">
                                        <div className="d-flex justify-content-md-center align-items-center">
                                            <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                        </div>
                                    </div>
                            }
                            <div className="col-md-12">

                            </div>
                        </div>
                }
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
                <AppModal
                    show={openModal}
                    title={selectedRow ? selectedRow.id : ''}
                    footer={false}
                    centered={true}
                    size="md"
                    onHide={() => this.setState({ openModal: false })}
                >
                    <Tab.Container id="left-tabs-example" activeKey={selectedKey} defaultActiveKey="IMAGE" onSelect={(k) => this.setState({ selectedKey: k })}>
                        <Nav variant="pills" className="d-flex px-2">
                            <Nav.Item className="mr-2 flex-grow-1 text-center">
                                <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center">
                                <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Video</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="mr-2 flex-grow-1 text-center">
                                <Nav.Link eventKey={MEDIA_TAB.CERTI}>Certificate</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={MEDIA_TAB.IMAGE} className="text-center">
                                {
                                    !!selectedRow?.img_url && FILE_FORMATS.find(i => { return selectedRow?.img_url ? selectedRow?.img_url.includes(i) : false })
                                        ? <Image src={`${storageUrl}${selectedRow?.img_url}`} class="img-fluid" alt={selectedRow?.stone_id} />
                                        : !!selectedRow?.img_url ? <iframe title="image" src={selectedRow?.img_url} width={'100%'} style={{ height: '60vh' }} /> : <Image width="100px" className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                }

                            </Tab.Pane>
                            <Tab.Pane eventKey={MEDIA_TAB.VIDEO} className="text-center">
                                {
                                    !!selectedRow?.video_url && VIDEO_FORMATS.find(i => { return selectedRow?.video_url ? selectedRow?.video_url.includes(i) : false })
                                        ? <video width={200} muted className="img-fluid" src={`${storageUrl}${selectedRow?.video_url}`} autoPlay loop />
                                        : !!selectedRow?.video_url ? <iframe title="video" src={selectedRow?.video_url} width={'100%'} style={{ height: '60vh' }} /> : <Image width="100px" className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey={MEDIA_TAB.CERTI} className="text-center">
                                {
                                    !!selectedRow?.certi_url && FILE_FORMATS.find(i => { return selectedRow?.certi_url ? selectedRow?.certi_url.includes(i) : false })
                                        ? <Image src={`${storageUrl}${selectedRow?.certi_url}`} class="img-fluid" alt={selectedRow?.stone_id} />
                                        : !!selectedRow?.certi_url ? <iframe title="certi" src={!!selectedRow?.certi_url.includes('.pdf') ? `${storageUrl}${selectedRow?.certi_url}` :selectedRow?.certi_url} width={'100%'} style={{ height: '60vh' }} /> : <Image width="100px" className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </AppModal>
                <AppModal
                    show={openEnquiryModal}
                    title={'Enquiry'}
                    size="md"
                    onHide={() => this.setState({ openEnquiryModal: false })}
                >
                    <Formik
                        initialValues={enquiryPayload}
                        validationSchema={EnquirySchema}
                        onSubmit={this.handleEnquirySubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="remark">Remarks</label>
                                        <textarea
                                            className="form-control position-relative"
                                            onChange={handleChange}
                                            name={'remark'}
                                        />
                                        {errors.remark && (
                                            <div className="text-danger mt-1 ml-2">
                                                {errors.remark}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-rounded btn-primary pull-right mt-2 ml-2"
                                        type="submit"
                                        style={{ float: 'right' }}
                                        disabled={isSubmitting}
                                    >
                                        Send Inquiry
                                    </button>
                                    <button
                                        className="btn btn-rounded btn-secondary pull-right mt-2"
                                        type="button"
                                        style={{ float: 'right' }}
                                        onClick={() => this.setState({ openEnquiryModal: false })}
                                    >
                                        Cancel
                                    </button>

                                </form>
                            )}
                    </Formik>
                </AppModal>
                <AppModal
                    show={openPlaceOrderModal}
                    title={'Place Order'}
                    size="md"
                    onHide={() => this.setState({ openPlaceOrderModal: false })}
                >
                    <Formik
                        initialValues={enquiryPayload}
                        validationSchema={EnquirySchema}
                        onSubmit={this.handlePlaceOrderSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <Table
                                            dataSource={selectedList}
                                            bordered={true}
                                            columns={orderListColumns}
                                            isCheckbox={false}
                                            paginate={false}
                                            width={"200px"}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="remark">Remarks</label>
                                        <textarea
                                            className="form-control position-relative"
                                            onChange={handleChange}
                                            name={'remark'}
                                        />
                                        {errors.remark && (
                                            <div className="text-danger mt-1 ml-2">
                                                {errors.remark}
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="btn btn-rounded btn-primary pull-right mt-2 ml-2"
                                        type="submit"
                                        style={{ float: 'right' }}
                                        disabled={isSubmitting}
                                    >
                                        Place Order
                                    </button>
                                    <button
                                        className="btn btn-rounded btn-secondary pull-right mt-2"
                                        type="button"
                                        style={{ float: 'right' }}
                                        onClick={() => this.setState({ openPlaceOrderModal: false })}
                                    >
                                        Cancel
                                    </button>
                                </form>
                            )}
                    </Formik>
                </AppModal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.diamond.loading,
    diamondList: state.diamond.data,
    currentPage: state.diamond.current_page,
    totalRow: state.diamond.total,
    perPage: state.diamond.per_page,
    enquiryLoading: state.diamond.enquiryLoading,
    enquiryData: state.diamond.enquiryData,
});
const mapDispatchToProps = {
    fetchFeaturedDiamond,
    fetchRecommendedDiamond,
    fetchSearchedDiamond,
    fetchNewDiamond,
    createDiamondEnquiry,
    createDiamondPlaceOrder
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchReport));