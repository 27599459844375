const { apiAdapter } = require("./ApiAdapter")

const getJewelleries = (filters) => {
    return apiAdapter({
        url: 'jewellerys',
        method: 'POST',
        data: filters
    })
}

const createJewellery = (data) => {
    return apiAdapter({
        url: 'jewellery',
        method: 'POST',
        data
    })
}

const updateJewellery = (data) => {
    return apiAdapter({
        url: 'jewellery',
        method: 'PUT',
        data
    })
}

const deleteJewellery = (data) => {
    return apiAdapter({
        url: 'jewellery',
        method: 'DELETE',
        data
    })
}
const getCategory = (filters) => {
    return apiAdapter({
        url: 'categories',
        method: 'GET',
        data: filters
    })
}

const createCategory = (data) => {
    return apiAdapter({
        url: 'category',
        method: 'POST',
        data
    })
}

const updateCategory = (data) => {
    return apiAdapter({
        url: 'category',
        method: 'PUT',
        data
    })
}

const deleteCategory = (data) => {
    return apiAdapter({
        url: 'category',
        method: 'DELETE',
        data
    })
}

const createSubCategory = (data) => {
    return apiAdapter({
        url: 'subcategory',
        method: 'POST',
        data
    })
}

const updateSubCategory = (data) => {
    return apiAdapter({
        url: 'subcategory',
        method: 'PUT',
        data
    })
}

const deleteSubCategory = (data) => {
    return apiAdapter({
        url: 'subcategory',
        method: 'DELETE',
        data
    })
}


module.exports = {
    getJewelleries,
    createJewellery,
    updateJewellery,
    deleteJewellery,
    getCategory,
    createCategory,
    updateCategory,
    deleteCategory,
    createSubCategory,
    updateSubCategory,
    deleteSubCategory,
}