import React, { Component } from 'react'
import { connect } from 'react-redux'
import './AdminDiamondStyle.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { Tab, Nav, Button } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import { fetchDiamondById, createNewDiamond, updateNewDiamond } from '../../../redux/actions/DiamondActions';
import Spin from '@gull/components/Spin';
import { Formik, withFormik } from "formik";
import * as yup from "yup";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import moment from 'moment';
import { storageUrl } from 'environment/env';
import Image from 'app/GullLayout/SharedComponents/Image';
import { FILE_FORMATS, VIDEO_FORMATS } from '@utils'

const DiamondFormSchema = yup.object().shape({
    "supplier_name": yup
        .string()
        .required("Supplier Name is required"),
    "stone_id": yup
        .string()
        .required("Stone No is required"),
    "shape": yup
        .string(),
    "carat": yup
        .string(),
    "color": yup
        .string(),
    "clarity": yup
        .string(),
    "cut": yup
        .string(),
    "polish": yup
        .string(),
    "symmetry": yup
        .string(),
    "fluo": yup
        .string(),
    "lab": yup
        .string(),
    "price": yup
        .number()
        .required("Price is required"),
    "discount": yup
        .number()
        .required("Discount is required"),
    "total_amount": yup
        .number()
        .required("Total Amount is required"),
    "j_price": yup
        .number()
        .required("Jinagna Price is required"),
    "j_discount": yup
        .number()
        .required("Jinagna Discount is required"),
    "j_total_amount": yup
        .number()
        .required("Jinagna Total Amount is required"),
    "retail_price": yup
        .number()
        .required("Jinagna Retail Price is required"),
    "retail_discount": yup
        .number()
        .required("Jinagna Retail Discount is required"),
    "retail_total_amount": yup
        .number()
        .required("Jinagna Retail Total Amount is required"),
    "location": yup
        .string()
        .required("Location is required"),
    "measurement": yup
        .string(),
    "depth": yup
        .string(),
    "table": yup
        .string(),
    "ratio": yup
        .string(),
    "featured": yup
        .string(),
    "certificate_no": yup
        .string()
});

export class DiamondForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diamondId: this.props?.match?.params?.stoneid,
            formLoading: false,
            formData: {
                "supplier_name": "",
                "stone_id": "",
                "shape": "",
                "carat": "",
                "color": "",
                "clarity": "",
                "cut": "",
                "polish": "",
                "symmetry": "",
                "fluo": "",
                "lab": "",
                "rap": "",
                "price": "",
                "discount": "",
                "total_amount": "",
                "j_price": "",
                "j_discount": "",
                "j_total_amount": "",
                "retail_price": "",
                "retail_discount": "",
                "retail_total_amount": "",
                "location": "",
                "measurement": "",
                "depth": "",
                "table": "",
                "ratio": "",
                "certificate_no": "",
                "status": "1",
                "featured": "0"
            },
            loading: false,
            isImageEdit: false,
            isVideoEdit: false,
            isCertiEdit: false,
        }
    }
    componentDidMount() {
        if (this.state.diamondId) {
            this.props.fetchDiamondById(this.state.diamondId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.diamondDetails !== prevProps.diamondDetails) {
            const { diamondDetails } = this.props
            let diamond = {};
            if (diamondDetails?.length) {
                diamond = diamondDetails[0];
                this.props.setFieldValue("stone_id", diamond.stone_id)
                this.setState({
                    formData: {
                        ...this.state.formData,
                        "supplier_name": diamond.supplier_name ? diamond.supplier_name : '',
                        "stone_id": diamond.stone_id ? diamond.stone_id : '',
                        "shape": diamond.shape ? diamond.shape : '',
                        "carat": diamond.carat ? diamond.carat : '',
                        "color": diamond.color ? diamond.color : '',
                        "clarity": diamond.clarity ? diamond.clarity : '',
                        "cut": diamond.cut ? diamond.cut : '',
                        "polish": diamond.polish ? diamond.polish : '',
                        "symmetry": diamond.symmetry ? diamond.symmetry : '',
                        "fluo": diamond.fluo ? diamond.fluo : '',
                        "lab": diamond.lab ? diamond.lab : '',
                        "rap": diamond.rap ? diamond.rap : '',
                        "price": diamond.price ? diamond.price : '',
                        "discount": diamond.discount ? diamond.discount : '',
                        "total_amount": diamond.total_amount ? diamond.total_amount : '',
                        "j_price": diamond.j_price ? diamond.j_price : '',
                        "j_discount": diamond.j_discount ? diamond.j_discount : '',
                        "j_total_amount": diamond.j_total_amount ? diamond.j_total_amount : '',
                        "retail_price": diamond.retail_price ? diamond.retail_price : '',
                        "retail_discount": diamond.retail_discount ? diamond.retail_discount : '',
                        "retail_total_amount": diamond.retail_total_amount ? diamond.retail_total_amount : '',
                        "location": diamond.location ? diamond.location : '',
                        "measurement": diamond.measurement ? diamond.measurement : '',
                        "depth": diamond.depth ? diamond.depth : '',
                        "table": diamond.table ? diamond.table : '',
                        "ratio": diamond.ratio ? diamond.ratio : '',
                        "certificate_no": diamond.certificate_no ? diamond.certificate_no : '',
                        "img_url": diamond.img_url ? diamond.img_url : '',
                        "video_url": diamond.video_url ? diamond.video_url : '',
                        "certi_url": diamond.certi_url ? diamond.certi_url : '',
                        "featured": diamond.featured ? (Number(diamond.featured)) : ''
                    }
                })
            }
        }
    }

    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {
            const payload = {
                ...that.state.formData,
                ...value,
            }

            if (that.state.diamondId) {
                payload['id'] = atob(that.state.diamondId)
                payload['updated_at'] = moment()
            }

            const resp = that.state.diamondId ? that.props.updateNewDiamond : that.props.createNewDiamond
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/diamonds')
                    })
                }
            });
        })
    };

    onUploadFinish = (url, type) => {
        let key = "img_url";
        switch (type) {
            case "diamond": key = "img_url"; break;
            case "videos": key = "video_url"; break;
            case "certi": key = "certi_url"; break;
            default: key = "img_url";
        }
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: url,
            }
        })
    }

    handleFormValueChange = e => {
        const rap = (e.target.name === 'rap') ? e.target.value : this.state.formData.rap
        const carat = (e.target.name === 'carat') ? e.target.value : this.state.formData.carat

        // supplier calc
        const discount = (e.target.name === 'discount') ? e.target.value : this.state.formData.discount
        const price = (rap - ((discount / 100) * rap))
        const total_amount = (Number(price) * Number(carat))
        // trade calc
        const j_discount = (e.target.name === 'j_discount') ? e.target.value : this.state.formData.j_discount
        const j_price = (rap - ((j_discount / 100) * rap))
        const j_total_amount = (Number(j_price) * Number(carat))
        // retail calc
        const retail_discount = (e.target.name === 'retail_discount') ? e.target.value : this.state.formData.retail_discount
        const retail_price = (rap - ((retail_discount / 100) * rap))
        const retail_total_amount = (Number(retail_price) * Number(carat))

        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
                price: price,
                total_amount: total_amount,
                j_price: j_price,
                j_total_amount: j_total_amount,
                retail_price: Number(retail_price),
                retail_total_amount: Number(retail_total_amount)
            }
        }, () => console.log(this.props.errors))

    }
    render() {
        const { loading, diamondDetails } = this.props
        const { formLoading } = this.state
        return (
            <div className="diamond-form">
                <Breadcrumb
                    routeSegments={[
                        { name: `${diamondDetails ? 'Edit' : 'Add'} Diamond`, path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={(loading | formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={DiamondFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Supplier Info" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Supplier Name</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="supplier_name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.supplier_name}
                                                                        placeholder="Supplier Name"
                                                                    />
                                                                    {(errors.supplier_name && touched.supplier_name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.supplier_name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Stone No</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="stone_id"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.stone_id}
                                                                        placeholder="Stone No"
                                                                    />
                                                                    {(errors.stone_id && touched.stone_id) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.stone_id}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Shape</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="shape"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.shape}
                                                                        placeholder="Shape"
                                                                    />
                                                                    {(errors.shape && touched.shape) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.shape}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Color</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="color"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.color}
                                                                        placeholder="Color"
                                                                    />
                                                                    {(errors.color && touched.color) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.color}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Clarity</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="clarity"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.clarity}
                                                                        placeholder="Clarity"
                                                                    />
                                                                    {(errors.clarity && touched.clarity) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.clarity}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Carat</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="carat"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.carat}
                                                                        placeholder="Carat"
                                                                    />
                                                                    {(errors.carat && touched.carat) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.carat}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Cut</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="cut"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.cut}
                                                                        placeholder="Cut"
                                                                    />
                                                                    {(errors.cut && touched.cut) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.cut}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Polish</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="polish"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.polish}
                                                                        placeholder="Polish"
                                                                    />
                                                                    {(errors.polish && touched.polish) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.polish}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Symmetry</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="symmetry"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.symmetry}
                                                                        placeholder="Symmetry"
                                                                    />
                                                                    {(errors.symmetry && touched.symmetry) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.symmetry}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Fluorescence</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="fluo"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.fluo}
                                                                        placeholder="Fluorescence"
                                                                    />
                                                                    {(errors.fluo && touched.fluo) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.fluo}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>RAP</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="rap"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.rap}
                                                                        placeholder="RAP"
                                                                    />
                                                                    {(errors.rap && touched.rap) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.rap}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Discount</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="discount"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.discount}
                                                                        placeholder="Discount"
                                                                    />
                                                                    {(errors.discount && touched.discount) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.discount}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Price/Carat</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.price}
                                                                        placeholder="Price"
                                                                    />
                                                                    {(errors.price && touched.price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Amount</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="total_amount"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.total_amount}
                                                                        placeholder="Amount"
                                                                    />
                                                                    {(errors.total_amount && touched.total_amount) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.total_amount}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Lab</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="lab"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.lab}
                                                                        placeholder="Lab"
                                                                    />
                                                                    {(errors.lab && touched.lab) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.lab}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard title="Additional Info" className="mb-3">
                                                <div className="row">

                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Certificate No</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="certificate_no"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.certificate_no}
                                                                        placeholder="Certificate"
                                                                    />
                                                                    {(errors.certificate_no && touched.certificate_no) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.certificate_no}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Location</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="location"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.location}
                                                                        placeholder="Location"
                                                                    />
                                                                    {(errors.location && touched.location) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.location}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Measurement</b></div>
                                                            <div className="col-md-6"><div className="form-group">
                                                                <input
                                                                    className="form-control position-relative"
                                                                    type="text"
                                                                    name="measurement"
                                                                    onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                    onBlur={handleBlur}
                                                                    value={values.measurement}
                                                                    placeholder="Measurement"
                                                                />
                                                                {(errors.measurement && touched.measurement) && (
                                                                    <div className="text-danger mt-1 ml-1">
                                                                        {errors.measurement}
                                                                    </div>
                                                                )}
                                                            </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Depth</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="depth"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.depth}
                                                                        placeholder="Depth"
                                                                    />
                                                                    {(errors.depth && touched.depth) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.depth}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Table</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="table"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.table}
                                                                        placeholder="Table"
                                                                    />
                                                                    {(errors.table && touched.table) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.table}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Ratio</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="ratio"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.ratio}
                                                                        placeholder="Ratio"
                                                                    />
                                                                    {(errors.ratio && touched.ratio) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.ratio}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Is Featured</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        id="picker1"
                                                                        className="form-control"
                                                                        name="featured"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.featured}
                                                                    >
                                                                        <option value="1">Yes</option>
                                                                        <option value="0">No</option>
                                                                    </select>
                                                                    {(errors.featured && touched.featured) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.featured}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        <div className="col-md-6">
                                            <SimpleCard title="Jinagna's Pricing" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-6"><b>Jinagna Discount</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="j_discount"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.j_discount}
                                                                placeholder="Discount"
                                                            />
                                                            {(errors.j_discount && touched.j_discount) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.j_discount}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6"><b>Jinagna Price/Carat</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="j_price"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.j_price}
                                                                placeholder="Price"
                                                            />
                                                            {(errors.j_price && touched.j_price) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.j_price}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6"><b>Jinagna Amount</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="j_total_amount"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.j_total_amount}
                                                                placeholder="Amount"
                                                            />
                                                            {(errors.j_total_amount && touched.j_total_amount) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.j_total_amount}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard title="Jinagna's Retail Pricing" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-6"><b>Retail Discount</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="retail_discount"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.retail_discount}
                                                                placeholder="Discount"
                                                            />
                                                            {(errors.retail_discount && touched.retail_discount) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.retail_discount}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6"><b>Retail Price/Carat</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="retail_price"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.retail_price}
                                                                placeholder="Price"
                                                            />
                                                            {(errors.retail_price && touched.retail_price) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.retail_price}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6"><b>Retail Amount</b></div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input
                                                                className="form-control position-relative"
                                                                type="text"
                                                                name="retail_total_amount"
                                                                onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                onBlur={handleBlur}
                                                                value={values.retail_total_amount}
                                                                placeholder="Amount"
                                                            />
                                                            {(errors.retail_total_amount && touched.retail_total_amount) && (
                                                                <div className="text-danger mt-1 ml-1">
                                                                    {errors.retail_total_amount}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                            <SimpleCard>
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                                    <Nav variant="pills" className="d-flex px-2">
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Video</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.CERTI}>Certificate</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                                                            {
                                                                !this.state.isImageEdit
                                                                    ? !!this.state.formData?.img_url && FILE_FORMATS.find(i => { return this.state.formData?.img_url ? this.state.formData?.img_url.includes(i) : false })
                                                                        ? <Image src={`${storageUrl}${this.state.formData?.img_url}`} class="img-fluid" alt={'stone image'} />
                                                                        : !!this.state.formData?.img_url ? <iframe title="image" src={this.state.formData?.img_url} width={'100%'} style={{ height: '60vh' }} /> : <img width="100px" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                                                    : <FileUpload initalData={this.state.formData?.img_url} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="diamond" fileSizeLimitInMb={2} />
                                                            }<br />
                                                            <Button
                                                                variant={`primary`}
                                                                className="btn-rounded m-1 text-capitalize"
                                                                onClick={e => this.setState({ isImageEdit: !this.state.isImageEdit })}
                                                            >
                                                                {this.state.isImageEdit ? 'Cancel' : 'Edit Image'}
                                                            </Button>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={MEDIA_TAB.VIDEO}>
                                                            {
                                                                !this.state.isVideoEdit
                                                                    ? !!this.state.formData?.video_url && VIDEO_FORMATS.find(i => { return this.state.formData?.video_url ? this.state.formData?.video_url.includes(i) : false })
                                                                        ? <video width={200} className="img-fluid" controls muted src={`${storageUrl}${this.state.formData?.video_url}`} autoPlay loop />
                                                                        : !!this.state.formData?.video_url ? <iframe title="video" src={this.state.formData?.video_url} width={'100%'} style={{ height: '60vh' }} /> : <img width="100px" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                                                    : <FileUpload allowedFiles={[...VIDEO_FORMATS]} initalData={this.state.formData?.video_url} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="videos" />
                                                            }<br />
                                                            <Button
                                                                variant={`primary`}
                                                                className="btn-rounded m-1 text-capitalize"
                                                                onClick={e => this.setState({ isVideoEdit: !this.state.isVideoEdit })}
                                                            >
                                                                {this.state.isVideoEdit ? 'Cancel' : 'Edit Video'}
                                                            </Button>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey={MEDIA_TAB.CERTI}>
                                                            {
                                                                !this.state.isCertiEdit
                                                                    ? !!this.state.formData?.certi_url && FILE_FORMATS.find(i => { return this.state.formData?.certi_url ? this.state.formData?.certi_url.includes(i) : false })
                                                                        ? <Image src={`${storageUrl}${this.state.formData?.certi_url}`} class="img-fluid" alt={'CERTI IMAGE'} />
                                                                        : !!this.state.formData?.certi_url ? <iframe title="certi" src={!!this.state.formData?.certi_url.includes('.pdf') ? `${storageUrl}${this.state.formData?.certi_url}` : this.state.formData?.certi_url} width={'100%'} style={{ height: '60vh' }} /> : <img width="100px" class="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                                                    : <FileUpload allowedFiles={[...FILE_FORMATS, '.pdf']} initalData={this.state.formData?.certi_url} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="certi" fileSizeLimitInMb={2} />
                                                            }<br />
                                                            <Button
                                                                variant={`primary`}
                                                                className="btn-rounded m-1 text-capitalize"
                                                                onClick={e => this.setState({ isCertiEdit: !this.state.isCertiEdit })}
                                                            >
                                                                {this.state.isCertiEdit ? 'Cancel' : 'Edit Certificate'}
                                                            </Button>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </SimpleCard>
                                        </div>
                                    </div>
                                    <div className="sticky-footer">
                                        <div className="container">
                                            <div className="">
                                                <Button
                                                    key={'primary3'}
                                                    variant={`primary`}
                                                    className="btn-rounded m-1 text-capitalize"
                                                    style={{ float: 'right' }}
                                                    type="submit"
                                                >
                                                    Upload Diamond
                                                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    diamondDetails: state.diamond.diamondDetails,
    loading: state.diamond.loading,
    user: state.user,
})

const mapDispatchToProps = {
    fetchDiamondById,
    createNewDiamond,
    updateNewDiamond
}

export default connect(mapStateToProps, mapDispatchToProps)(withFormik({})(DiamondForm))