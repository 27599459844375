import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
// import ScrumBoardReducer from "./ScrumBoardReducer";
// import NotificationReducer from "./NotificationReducer";
// import EcommerceReducer from "./EcommerceReducer";
import searchDiamondReducer from "./SearchDiamondReducer";
import DiamondReducer from "./DiamondReducer";
import FileStorageReducer from "./FileStorageReducer";
import MiscReducer from "./MiscReducer";
import JewelleryReducer from "./JewelleryReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  searchDiamond: searchDiamondReducer,
  diamond: DiamondReducer,
  jewellery: JewelleryReducer,
  fileStorage: FileStorageReducer,
  misc: MiscReducer
});

export default RootReducer;
