import React, { Component } from 'react'
import { connect } from 'react-redux';
import './AdminLayoutStyle.scss';
import { Breadcrumb, SimpleCard } from '@gull'
import { Tab, Nav, Button } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import { createNewLayout, updateNewLayout, fetchLayouts } from '../../../redux/actions/DiamondActions';
import Spin from '@gull/components/Spin';
import { Formik } from "formik";
import * as yup from "yup";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import moment from 'moment';
import { FILE_FORMATS } from '@utils';

const LayoutFormSchema = yup.object().shape({
    "shape": yup
        .string(),
    "weight_cts": yup
        .string().nullable(true),
    "size_cts": yup
        .string().nullable(true),
    "color": yup
        .string().nullable(true),
    "clarity": yup
        .string().nullable(true),
    "lab": yup
        .string().nullable(true),
    "floro": yup
        .string().nullable(true),
    "length_cms": yup
        .string().nullable(true),
    "no_of_pcs": yup
        .number()
        .required("No. of Pcs is required"),
    "price": yup
        .number()
        .required("Price is required"),
    "discount": yup
        .number()
        .required("Discount is required"),
    "total": yup
        .number()
        .required("Total Amount is required"),
    "j_price": yup
        .number()
        .required("Jinagna Price is required"),
    "j_discount": yup
        .number()
        .required("Jinagna Discount is required"),
    "j_total": yup
        .number()
        .required("Jinagna Total Amount is required"),
    "supplier_name": yup
        .string()
        .required("Supplier name is required"),
});

export class LayoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layoutid: this.props?.match?.params?.layoutid,
            formLoading: false,
            formData: {
                "supplier_name": "",
                "weight_cts": "",
                "size_cts": "",
                "color": "",
                "clarity": "",
                "shape": "",
                "price": "",
                "discount": "",
                "j_price": "",
                "j_discount": "",
                "lab": "",
                "floro": "",
                "lenght_cms": "",
                "no_of_pcs": "",
                "total": "",
                "j_total": "",
                "imageUrl": "",
                "status": "",
            },
            loading: false
        }
        if (this.state.layoutid) {
            this.props.fetchLayouts(1, this.props?.match?.params?.layoutid)
        }
    }
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.layoutData !== prevProps.layoutData) {
            this.setState({
                formData: { ...this.props.layoutData }
            })
        }
    }

    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {
            const payload = {
                ...that.state.formData,
                ...value,
            }
            if (that.state.layoutid) {
                payload['id'] = atob(that.state.layoutid)
                payload['updated_at'] = moment()
            }

            const resp = that.state.layoutid ? that.props.updateNewLayout : that.props.createNewLayout
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/layouts')
                    })
                }
            });
        })
    };

    onUploadFinish = (url, type) => {
        let key = "imageUrl";
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: url,
            }
        })
    }

    handleFormValueChange = e => {
        // const discount = (e.target.name === 'discount') ? e.target.value : this.state.formData.discount
        // const jdiscount = (e.target.name === 'j_discount') ? e.target.value : this.state.formData.j_discount
        // const price = (e.target.name === 'price') ? e.target.value : this.state.formData.price
        // const jprice = (e.target.name === 'j_price') ? e.target.value : this.state.formData.j_price

        // const total = (price - ((discount / 100) * price))
        // const jtotal = (jprice - ((jdiscount / 100) * jprice))
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value,
                // total: total,
                // j_total: jtotal
            }
        }, () => console.log(this.props.errors))

    }
    render() {
        const { loading, layoutData } = this.props
        const { formLoading } = this.state
        return (
            <div className="diamond-form">
                <Breadcrumb
                    routeSegments={[
                        { name: `${layoutData ? 'Edit' : 'Add'} Layout`, path: "/" }
                    ]}
                    isBackButton={true}
                ></Breadcrumb>
                <Spin loading={(loading | formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={LayoutFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Information" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Supplier Name</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="supplier_name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.supplier_name}
                                                                        placeholder="Supplier Name"
                                                                    />
                                                                    {(errors.supplier_name && touched.supplier_name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.supplier_name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Shape</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="shape"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.shape}
                                                                        placeholder="Shape"
                                                                    />
                                                                    {(errors.shape && touched.shape) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.shape}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Size in cts.</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="size_cts"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.size_cts}
                                                                        placeholder="Size in cts."
                                                                    />
                                                                    {(errors.size_cts && touched.size_cts) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.size_cts}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Color</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="color"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.color}
                                                                        placeholder="Color"
                                                                    />
                                                                    {(errors.color && touched.color) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.color}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Clarity</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="clarity"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.clarity}
                                                                        placeholder="Clarity"
                                                                    />
                                                                    {(errors.clarity && touched.clarity) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.clarity}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Weight in Cts.</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="weight_cts"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.weight_cts}
                                                                        placeholder="Weight in Cts."
                                                                    />
                                                                    {(errors.weight_cts && touched.weight_cts) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.weight_cts}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Lab</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="lab"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.lab}
                                                                        placeholder="Lab"
                                                                    />
                                                                    {(errors.lab && touched.lab) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.lab}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>floro</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="floro"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.floro}
                                                                        placeholder="floro"
                                                                    />
                                                                    {(errors.floro && touched.floro) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.floro}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Length in Cms.</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="length_cms"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.length_cms}
                                                                        placeholder="Length in Cms."
                                                                    />
                                                                    {(errors.length_cms && touched.length_cms) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.length_cms}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>No. of Pcs.</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="no_of_pcs"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.no_of_pcs}
                                                                        placeholder="No. of Pcs."
                                                                    />
                                                                    {(errors.no_of_pcs && touched.no_of_pcs) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.no_of_pcs}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Discount%</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="discount"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.discount}
                                                                        placeholder="Discount"
                                                                    />
                                                                    {(errors.discount && touched.discount) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.discount}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Price/Carat</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.price}
                                                                        placeholder="Price"
                                                                    />
                                                                    {(errors.price && touched.price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Amount</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="total"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.total}
                                                                        placeholder="Amount"
                                                                    />
                                                                    {(errors.total && touched.total) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.total}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Jinagna Discount%</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="j_discount"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.j_discount}
                                                                        placeholder="Discount"
                                                                    />
                                                                    {(errors.j_discount && touched.j_discount) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.j_discount}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Jinagna Price/Carat</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="j_price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.j_price}
                                                                        placeholder="Price"
                                                                    />
                                                                    {(errors.j_price && touched.j_price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.j_price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Jinagna Amount</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="j_total"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.j_total}
                                                                        placeholder="Amount"
                                                                    />
                                                                    {(errors.j_total && touched.j_total) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.j_total}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        <div className="col-md-6">
                                            <SimpleCard>
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                                    <Nav variant="pills" className="d-flex px-2">
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                                                            <FileUpload allowedFiles={FILE_FORMATS} initalData={this.state.formData?.imageUrl} isServerUpload={true} onUploadFinish={this.onUploadFinish} filepath="layout" fileSizeLimitInMb={2} />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </SimpleCard>
                                        </div>
                                    </div>
                                    <div className="sticky-footer">
                                        <div className="container">
                                            <div className="">
                                                <Button
                                                    key={'primary3'}
                                                    variant={`primary`}
                                                    className="btn-rounded m-1 text-capitalize"
                                                    style={{ float: 'right' }}
                                                    type="submit"
                                                >
                                                    Upload Layout
                                                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    layoutData: state.diamond.layoutList?.data[0],
    user: state.user,
})

const mapDispatchToProps = {
    fetchLayouts,
    createNewLayout,
    updateNewLayout
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutForm);