import React, { Component } from 'react'
import { Breadcrumb, SimpleCard } from "@gull";
import * as yup from "yup";
import Spin from '@gull/components/Spin';
import { Formik } from 'formik';
import { Tab, Nav, Button } from 'react-bootstrap';
import { MEDIA_TAB } from '../search-report/SearchReport';
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import { GetCategory, getJewellery, UpdateJewellery, CreateJewellery } from '../../../redux/actions/JewelleryAction';
import { connect } from 'react-redux';
import moment from 'moment';
import Image from 'app/GullLayout/SharedComponents/Image';
import { storageUrl } from 'environment/env';
import { DIAMOND_QUALITY, FILE_FORMATS, Metal_SET, VIDEO_FORMATS } from '@utils'

const JewelleryFormSchema = yup.object().shape({
    "supplier_name": yup
        .string()
        .required("supplier name is required"),
    "stockId": yup
        .string()
        .required("stock-id is required"),
    "title": yup
        .string()
        .required("title is required"),
    "description": yup
        .string()
        .required("description is required"),
    "gender": yup
        .string()
        .required("gender is required"),
    "categoryId": yup
        .string()
        .required("category is required"),
    "sub_categoryId": yup
        .string()
        .required("sub category is required"),
    "gold_weight": yup
        .number()
        .required("gold weight is required"),
    "gold_color": yup
        .string()
        .required("gold color is required"),
    "metal": yup
        .string()
        .required("metal is required"),
    "diamond_weight": yup
        .number()
        .required("diamond weigth is required"),
    "diamond_shape": yup
        .string()
        .required("diamond shape is required"),
    "diamond_color": yup
        .string()
        .required("diamond color is required"),
    "diamond_clarity": yup
        .string()
        .required("diamond clarity is required"),
    "selling_price": yup
        .number()
        .required("selling price is required"),
    "cost_price": yup
        .number()
        .required("cost price is required"),
    "j_selling_price": yup
        .number()
        .required("selling price is required"),
    "j_discount": yup
        .number()
        .required("cost price is required"),
    "is_featured": yup
        .boolean(),
});

class JewelleryForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            windowWidth: window.innerWidth,
            jewelleryId: this.props?.match?.params?.id,
            formData: {
                "supplier_name": "",
                "stockId": "",
                "title": "",
                "description": "",
                "gender": "male",
                "categoryId": "",
                "sub_categoryId": "",
                "gold_weight": "",
                "gold_color": "",
                "metal": "",
                "diamond_weight": "",
                "diamond_shape": "",
                "diamond_color": "",
                "diamond_clarity": "",
                "selling_price": "",
                "cost_price": "",
                "j_selling_price": "",
                "j_discount": "",
                "discount": "",
                "is_featured": 0,
                "images": []
            },
            formLoading: false
        }
    }

    componentDidMount() {
        if (this.state.jewelleryId) {
            this.props.getJewellery({ id: atob(this.state.jewelleryId) })
        }
        this.props.GetCategory()
    }

    componentDidUpdate(oldProps) {
        if (oldProps.jewelleryList !== this.props.jewelleryList) {
            // eslint-disable-next-line
            const selectedCategory = this.props.categoryList?.find(item => item.id == this.props.jewelleryList[0].categoryId);
            delete this.props.jewelleryList[0].category;
            delete this.props.jewelleryList[0].subCategory;
            this.setState({
                formData: {
                    ...this.props.jewelleryList[0]
                }
            }, () => {
                setTimeout(() => this.setState({
                    subCategoryList: selectedCategory?.subCategory || [],
                }), 200)
            })
        }
    }

    handleFormValueChange = e => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        }, () => {
            console.log(this.props.errors);
            if (e.target.name === 'categoryId') {
                // eslint-disable-next-line
                const selectedCategory = this.props.categoryList?.find(item => item.id == e.target.value);
                this.setState({
                    subCategoryList: selectedCategory['subCategory']
                })
            }
        })

    }

    handleSubmit = (value, { isSubmitting }) => {
        const that = this
        this.setState({
            formLoading: true
        }, () => {

            const payload = {
                ...that.state.formData,
                ...value,
            }

            if (that.state.jewelleryId) {
                payload['id'] = atob(that.state.jewelleryId)
                payload['updated_at'] = moment()
            } else {
                payload['images'] = payload['images']?.filter(item => !item.isDeleted)
            }

            const resp = that.state.jewelleryId ? that.props.UpdateJewellery : that.props.CreateJewellery
            resp(payload).then(resp => {
                if (resp) {
                    that.setState({
                        formLoading: false
                    }, () => {
                        that.props.history.push('/jewellery')
                    })
                } else {
                    that.setState({
                        formLoading: false
                    })
                }
            })
                .catch(e => {
                    console.error(e);
                    that.setState({
                        formLoading: false
                    })
                });
        })
    };

    onUploadFinish = (url, type) => {
        if (!url) return
        const key = 'images'
        const values = this.state.formData?.images || []
        let file_type = FILE_FORMATS.includes(`.${url.split('.')[1]}`) ? 'images' : 'videos'
        this.setState({
            formData: {
                ...this.state.formData,
                [key]: [...values, { imageUrl: url, file_type }],
            }
        })
    }

    render() {
        const buttonList = [

        ]
        const buttonListmerge = [

        ]
        let switchlist = []
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        const { jewelleryId, formLoading, subCategoryList } = this.state;
        const { categoryloading, categoryList, loading } = this.props;
        return (
            <div className="jewellery-form">
                <Breadcrumb
                    routeSegments={[
                        {
                            name: <> <i className="fa fa-arrow-left" onClick={e => this.props.history.goBack()} /> {jewelleryId ? 'Edit' : 'Create'}
                         Jewellery</>, path: "/jewellery/form"
                        }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <Spin loading={(loading || formLoading)}>
                    <Formik
                        enableReinitialize
                        initialValues={this.state.formData}
                        validationSchema={JewelleryFormSchema}
                        onSubmit={this.handleSubmit}
                        handleChange={this.handleFormValueChange}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setValues,
                            isSubmitting
                        }) => {
                            return (
                                <form onSubmit={e => { console.log(errors); handleSubmit(e) }}>
                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <SimpleCard title="Jewellery Info" className="mb-3">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Stock Id</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="stockId"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.stockId}
                                                                        placeholder="stockId"
                                                                    />
                                                                    {(errors.stockId && touched.stockId) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.stockId}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Title</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="title"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.title}
                                                                        placeholder="Title"
                                                                    />
                                                                    {(errors.title && touched.title) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.title}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Description</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="description"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.description}
                                                                        placeholder="description"
                                                                    />
                                                                    {(errors.description && touched.description) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.description}
                                                                        </div>
                                                                    )}
                                                                </div></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Gender</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control"
                                                                        name="gender"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.gender}
                                                                    >
                                                                        <option value="male">Male</option>
                                                                        <option value="female">Female</option>
                                                                        <option value="unisex">Unisex</option>
                                                                    </select>
                                                                    {(errors.gender && touched.gender) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.gender}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Category</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control"
                                                                        name="categoryId"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.categoryId}
                                                                    >
                                                                        <option>{categoryloading ? 'Loading..' : 'Select Category'}</option>
                                                                        {
                                                                            categoryList && categoryList.filter(c => c.name !== 'Diamond').map(item => (
                                                                                <option value={item.id}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.categoryId && touched.categoryId) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.categoryId}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Sub-Category</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control"
                                                                        name="sub_categoryId"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.sub_categoryId}
                                                                    >
                                                                        <option>{categoryloading ? 'Loading..' : 'Select Sub-Category'}</option>
                                                                        {
                                                                            subCategoryList && subCategoryList.map(item => (
                                                                                <option value={item.id}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.sub_categoryId && touched.sub_categoryId) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.sub_categoryId}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Gold Weight</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="gold_weight"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.gold_weight}
                                                                        placeholder="Gold Weight"
                                                                    />
                                                                    {(errors.gold_weight && touched.gold_weight) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.gold_weight}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Gold Color</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="gold_color"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.gold_color}
                                                                        placeholder="Gold Color"
                                                                    />
                                                                    {(errors.gold_color && touched.gold_color) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.gold_color}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Metal</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control"
                                                                        name="metal"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.metal}
                                                                    >
                                                                        <option>{'Select Metal'}</option>
                                                                        {
                                                                            Metal_SET.map(item => (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.metal && touched.metal) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.metal}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Diamond Weight</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="diamond_weight"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.diamond_weight}
                                                                        placeholder="Diamond Weight"
                                                                    />
                                                                    {(errors.diamond_weight && touched.diamond_weight) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.diamond_weight}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Diamond Shape</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="diamond_shape"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.diamond_shape}
                                                                        placeholder="Diamond Shape"
                                                                    />
                                                                    {(errors.diamond_shape && touched.diamond_shape) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.diamond_shape}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Diamond Color</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="diamond_color"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.diamond_color}
                                                                        placeholder="Diamond Color"
                                                                    />
                                                                    {(errors.diamond_color && touched.diamond_color) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.diamond_color}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Diamond Clarity</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                {/* DIAMOND_QUALITY */}
                                                                <select
                                                                        className="form-control"
                                                                        name="diamond_clarity"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.diamond_clarity}
                                                                    >
                                                                        <option>{'Select Clarity'}</option>
                                                                        {
                                                                            DIAMOND_QUALITY.map(item => (
                                                                                <option value={item.value}>{item.label}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {(errors.diamond_clarity && touched.diamond_clarity) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.diamond_clarity}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Supplier Name</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="text"
                                                                        name="supplier_name"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.supplier_name}
                                                                        placeholder="Supplier Name"
                                                                    />
                                                                    {(errors.supplier_name && touched.supplier_name) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.supplier_name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Selling Price</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="selling_price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.selling_price}
                                                                        placeholder="selling price"
                                                                    />
                                                                    {(errors.selling_price && touched.selling_price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.selling_price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Cost Price</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="cost_price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.cost_price}
                                                                        placeholder="Cost Price"
                                                                    />
                                                                    {(errors.cost_price && touched.cost_price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.cost_price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Jinagna's Selling Price</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="j_selling_price"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.j_selling_price}
                                                                        placeholder="Jinagna's Selling Price"
                                                                    />
                                                                    {(errors.j_selling_price && touched.j_selling_price) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.j_selling_price}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Jinagna's Discount</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <input
                                                                        className="form-control position-relative"
                                                                        type="number"
step="any"
                                                                        name="j_discount"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e) }}
                                                                        onBlur={handleBlur}
                                                                        value={values.j_discount}
                                                                        placeholder="Jinagna's discount"
                                                                    />
                                                                    {(errors.j_discount && touched.j_discount) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.j_discount}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6"><b>Featured</b></div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <select
                                                                        className="form-control"
                                                                        name="is_featured"
                                                                        onChange={e => { this.handleFormValueChange(e); handleChange(e); }}
                                                                        onBlur={handleBlur}
                                                                        value={values.is_featured}
                                                                    >
                                                                        <option value={0}>No</option>
                                                                        <option value={1}>Yes</option>
                                                                    </select>
                                                                    {(errors.is_featured && touched.is_featured) && (
                                                                        <div className="text-danger mt-1 ml-1">
                                                                            {errors.is_featured}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SimpleCard>
                                        </div>
                                        <div className="col-md-6">
                                            <SimpleCard className="mb-3">
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                                    <Nav variant="pills" className="d-flex px-2">
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Images</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                                            {/* <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Upload Image/Video</Nav.Link> */}
                                                        </Nav.Item>
                                                    </Nav>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE}>
                                                            <div className="row">
                                                                {
                                                                    this.state.formData?.images && this.state.formData.images?.filter(item => !item?.isDeleted)?.map((item, i) => (
                                                                        <div key={i} className="col-sm-3 mb-3">
                                                                            {
                                                                                FILE_FORMATS.find(i => { return item.imageUrl ? item.imageUrl.includes(i) : false }) && <Image width={100} src={`${storageUrl}${item.imageUrl}`} alt={`image${i}`} />
                                                                            }
                                                                            {
                                                                                VIDEO_FORMATS.find(i => { return item?.imageUrl ? item?.imageUrl.includes(i) : false }) && <video controls muted width={100} src={`${storageUrl}${item?.imageUrl}`} autoPlay />
                                                                            }
                                                                            <br />
                                                                            <Button
                                                                                key={'image-delete'}
                                                                                variant={`outline-danger`}
                                                                                className="btn-rounded m-1 text-capitalize"
                                                                                type="button"
                                                                                size="sm"
                                                                                block
                                                                                onClick={e => this.setState({ formData: { ...this.state.formData, images: this.state.formData.images.map(vItem => (vItem.imageUrl === item.imageUrl) ? ({ ...vItem, isDeleted: true }) : vItem) } })}
                                                                            >
                                                                                {`x remove`}
                                                                            </Button>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {
                                                                    this.state.formData?.images?.filter(item => !item?.isDeleted)?.length < 5 && <div className="col-sm-12">
                                                                        <FileUpload fileSizeLimitInMb={2} isServerUpload={true} allowedFiles={[...FILE_FORMATS, ...VIDEO_FORMATS]} onUploadFinish={this.onUploadFinish} filepath="jewellery" />
                                                                    </div>
                                                                }
                                                                <div className="col-sm-12 text-danger">
                                                                    Note:   Maximum 4 images are allowed to upload and 1 video
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </SimpleCard>
                                        </div>
                                    </div>
                                    <div className="sticky-footer">
                                        <div className="container">
                                            <div className="">
                                                <Button
                                                    key={'primary3'}
                                                    variant={`primary`}
                                                    className="btn-rounded m-1 text-capitalize"
                                                    style={{ float: 'right' }}
                                                    type="submit"
                                                >
                                                    Upload Jewellery
                                                    </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.jewellery.loading,
    jewelleryList: state.jewellery?.data,
    categoryloading: state.jewellery.categoryloading,
    categoryList: state.jewellery?.category,
})

const mapDispatchToProps = {
    GetCategory,
    getJewellery,
    UpdateJewellery,
    CreateJewellery
}

export default connect(mapStateToProps, mapDispatchToProps)(JewelleryForm);