/* eslint-disable */
import axios from "axios";
import localStorageService from "./localStorageService";
import { apiAdapter } from "./ApiAdapter";
import { NotificationManager } from "react-notifications";

class JwtAuthService {

  resetPassword = payload => {
    return apiAdapter({
      url: 'auth/reset',
      method: 'POST',
      data: payload
    })
  }

  forgetPassword = payload => {
    return apiAdapter({
      url: 'auth/recovery',
      method: 'POST',
      data: {
        ...payload
      }
    }).then(resp => {
      return resp
    })
  }

  loginWithEmailAndPassword = (email, password) => {
    return apiAdapter({
      url: 'auth/login',
      method: 'POST',
      data: {
        email, password, appType: 'ADMIN'
      }
    }).then(({ data, status }) => {
      if ([200, 202].includes(status)) {
        this.setSession(data.token);
        this.user = { ...data.user };
        this.setUser({ ...this.user });
        return this.user;
      }

      NotificationManager.error('Something went wrong, Please try agian');
      throw Error('invalid login');
    })
      .catch(e => {
        console.log(e)
      });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(localStorageService.getItem('auth_user'));
      }, 100);
    }).then(data => {
      this.setSession(localStorage.getItem('jwt_token'));
      this.setUser(localStorageService.getItem('auth_user'));
      return data;
    });
  };



  logout = () => {
    apiAdapter({
      url: 'auth/logout',
      method: 'POST',
      data: {
        bearerToken: localStorage.getItem('jwt_token')
      }
    }).then(resp => {
      if (resp.data.message === 'Successfully logged out') {
        this.setSession(null);
        this.removeUser();
      }
    })
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      // apiAdapter.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      // delete apiAdapter.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
