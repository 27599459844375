import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import AppModal from '../modal/Modal';
import { bulkDiamondImagesUpload } from '../../../services/DiamondService';
import { NotificationManager } from 'react-notifications';
import Spin from '@gull/components/Spin';
import FileUpload from '../fileupload/FileUpload';
import { FILE_FORMATS, VIDEO_FORMATS } from '@utils'

class BulkDiamondImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openUploadModal: false,
            bulkDiamondImageUploadLoading: false,
            filepath: 'diamond'
        }
    }
    openImageUploadModal = () => {
        this.setState({
            openImageUploadModal: !this.state.openImageUploadModal
        })
    }
    bulkDiamondImageUpload = (fileurl, filepath) => {
        this.setState({
            bulkDiamondImageUploadLoading: true
        }, () => {
            setTimeout(() => {

                const payload = new FormData();
                Array.from(fileurl).forEach(file => {
                    payload.append('file[]', file);
                })
                payload.append('filepath', filepath);
                bulkDiamondImagesUpload(payload)
                    .then(resp => {
                        if (resp.data.success) {
                            this.openImageUploadModal();
                            if (this.props.getDiamondData)
                                this.props.getDiamondData();
                            NotificationManager.success('new diamonds images uploaded', 'SUCCESS');
                        }
                        this.setState({
                            bulkDiamondImageUploadLoading: false
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            bulkDiamondImageUploadLoading: false
                        })
                        NotificationManager.error('something went wrong. Please contact admin');
                    })
            }, 500)
        })
    }
    render() {
        const { openImageUploadModal, bulkDiamondImageUploadLoading } = this.state
        const { className } = this.props;
        return (
            <div>
                <Button
                    key={99}
                    variant={`outline-primary`}
                    className={`btn-rounded m-1 text-capitalize right-float-patch ${className}`}
                    onClick={e => this.openImageUploadModal()}
                >
                    Upload Diamond Images
            </Button>
                <AppModal
                    show={openImageUploadModal}
                    title={'Upload Diamonds Images'}
                    size="md"
                    onHide={() => this.setState({ openImageUploadModal: false })}
                >
                    <Spin loading={bulkDiamondImageUploadLoading}>
                        <p>What are you uploading?</p>
                        <div className="form-group">
                            <select
                                id="picker1"
                                className="form-control"
                                name="featured"
                                onChange={e => { this.setState({ filepath: e.target.value }) }}
                                value={this.state.filepath}
                            >
                                <option value="diamond">Diamond Image</option>
                                <option value="video">diamond Video</option>
                                <option value="certi">diamond Certi</option>
                            </select>
                        </div>
                        <FileUpload fileSizeLimitInMb={2} allowedFiles={(this.state.filepath === 'video') ? VIDEO_FORMATS : FILE_FORMATS} isMultiple={true} isServerUpload={false} onUploadFinish={this.bulkDiamondImageUpload} filepath={this.state.filepath} />
                    </Spin>
                </AppModal>
            </div>
        )
    }
}


export default BulkDiamondImageUpload