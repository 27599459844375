import React from 'react'
import { useState } from 'react'

export default function Image(props) {
    const [isDefaultImg, setIsDefaultImg] = useState(false)
    return !isDefaultImg
        ? (
            <img alt="" onError={e => { setIsDefaultImg(true) }} {...props} />
        )
        : (
            <img width="100px" {...props} src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
        )
}
