import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import AppModal from '../modal/Modal';
import { bulkDiamondUpload } from '../../../services/DiamondService';
import { NotificationManager } from 'react-notifications';
import Spin from '@gull/components/Spin';
import FileUpload from '../fileupload/FileUpload';
import { storageUrl } from 'environment/env';

class BulkDiamondUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openUploadModal: false,
            bulkDiamondUploadLoading: false
        }
    }
    openUploadModal = () => {
        this.setState({
            openUploadModal: !this.state.openUploadModal
        })
    }
    bulkDiamondUpload = (fileurl, filepath) => {
        this.setState({
            bulkDiamondUploadLoading: true
        }, () => {
            setTimeout(() => {
                const payload = new FormData();
                payload.append('file', fileurl[0]);
                bulkDiamondUpload(payload)
                    .then(resp => {
                        if (resp.data.data.success) {
                            this.openUploadModal();
                            if (this.props?.getDiamondData)
                                this.props.getDiamondData();
                            NotificationManager.success('new diamonds uploaded', 'SUCCESS');
                        } else {
                            NotificationManager.error(resp.data?.data?.error ? resp.data.data.error : 'something went wrong. Please contact admin');
                        }
                        this.setState({
                            bulkDiamondUploadLoading: false
                        })
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            bulkDiamondUploadLoading: false
                        })
                        NotificationManager.error('something went wrong. Please contact admin');
                    })
            }, 500)
        })
    }
    render() {
        const { openUploadModal } = this.state;
        const { className } = this.props
        return (
            <div>
                <Button
                    key={99}
                    variant={`outline-primary`}
                    className={`btn-rounded m-1 text-capitalize right-float-patch ${className}`}
                    onClick={e => this.openUploadModal()}
                >
                    Upload Diamond List
            </Button>
                <AppModal
                    show={openUploadModal}
                    title={'Upload Diamonds'}
                    size="md"
                    onHide={() => this.setState({ openUploadModal: false })}
                >
                    <Spin loading={this.state.bulkDiamondUploadLoading}>
                        <p className="m-1 ml-2 h5">Download Sample format: <a href={`${storageUrl}/storage/samples/diamond-upload-sample-format.xlsx`} download>here</a></p>
                        <small className="m-1 ml-2" style={{ color: 'red' }}>[Note: Please upload only <b>.csv</b> file]</small>
                        <FileUpload allowedFiles={['.csv']} isMultiple={false} isServerUpload={false} onUploadFinish={this.bulkDiamondUpload} />
                    </Spin>
                </AppModal>
            </div>
        )
    }
}


export default BulkDiamondUpload