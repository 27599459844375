import _Princess from './princess.jpeg'
import _Pear from './pear.jpeg'
import _Cushion_modified from './cushion_modified.jpeg'
import _Long_radiant from './long_radiant.jpeg'
import _Emerald from './emerald.jpeg'
import _Sq_emerald from './sq_emerald.jpeg'
import _Marquise from './marquise.jpeg'
import _Heart from './heart.jpeg'
import _Oval from './oval.jpeg'
import _Round from './round.jpg'
import _Sq_radiant from './sq_radiant.jpg'

export default {
    'round': _Round,
    'rbc': _Round,
    'princess': _Princess,
    'prin': _Princess,
    'prn': _Princess,
    'princesses': _Princess,
    'pear': _Pear,
    'ps': _Pear,
    'cushion modified brilliant': _Cushion_modified,
    'cmb': _Cushion_modified,
    'cush': _Cushion_modified,
    'cu': _Cushion_modified,
    'long radiant': _Long_radiant,
    'l - rdt': _Long_radiant,
    'l_rd': _Long_radiant,
    'l-rdt': _Long_radiant,
    'radiant': _Long_radiant,
    'rad': _Long_radiant,
    'square radiant': _Sq_radiant,
    'sq-rdt': _Sq_radiant,
    'sq - rdt': _Sq_radiant,
    'sq.rad': _Sq_radiant,
    'emerald': _Emerald,
    'em': _Emerald,
    'square emerald': _Sq_emerald,
    'sq-em': _Sq_emerald,
    'sq_e': _Sq_emerald,
    'sq - em': _Sq_emerald,
    'accher': _Sq_emerald,
    'marquise': _Marquise,
    'mq': _Marquise,
    'heart': _Heart,
    'hrt': _Heart,
    'ht': _Heart,
    'oval': _Oval,
    'ov': _Oval
};