/* eslint-disable */
import React, { Component } from "react";
import './AdminUsersStyle.scss';
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import { fetchApprovalUsers, updateApprovalUsers } from '../../../redux/actions/UserActions';
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { createRecommendedDiamonds, getSearchedDiamonds } from 'app/services/DiamondService'
import { NotificationManager } from "react-notifications";
import Image from "app/GullLayout/SharedComponents/Image";
import { storageUrl } from "environment/env";

class UserApprovalList extends Component {
    state = {
        windowWidth: window.innerWidth,
        columns: [
            {
                title: 'Details',
                dataIndex: 'Details',
                render: (data, row) => (<>
                    {
                        Number(row.status) === 0 && <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Accept User
                                    </Tooltip>
                            }
                        >
                            <i onClick={e => this.toggleAlertBox(row, false)} className="fa fa-check ml-1"></i>
                        </OverlayTrigger>
                    }
                    {
                        (Number(row.status) === 0) && <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    Reject User
                                    </Tooltip>
                            }
                        >
                            <i onClick={e => this.toggleAlertBox(row, true)} className="fa fa-times ml-1"></i>
                        </OverlayTrigger>
                    }
                    {
                        (Number(row.status) !== 0) && <>-</>
                    }
                </>)
            },
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: "Email",
                dataIndex: 'email',
            },
            {
                title: "Mobile No",
                dataIndex: 'mobile_no',
            },
            {
                title: "Company Name",
                dataIndex: 'company_name',
            },
            {
                title: "Owner Proof",
                dataIndex: 'owner_proof',
                render: (data, row) => row.owner_proof ? (<Button onClick={e => this.openDiamondRecommendationModal(row.owner_proof)} className="p-0" variant="link">View</Button>) : 'NA'
            },
            {
                title: "Visiting Card",
                dataIndex: 'visiting_card',
                render: (data, row) => row.visiting_card ? (<Button onClick={e => this.openDiamondRecommendationModal(row.visiting_card)} className="p-0" variant="link">View</Button>) : 'NA'
            },
            {
                title: "Rejected Reason",
                dataIndex: 'reason',
                render: (data, row) => data || 'NA'
            },
            {
                title: "Status",
                dataIndex: 'status',
                className: "text-center",
                render: data => Number(data) === 0
                    ? <Badge key={1} className={`badge-light text-white p-2`}>
                        {'PENDING'}
                    </Badge>
                    : Number(data) === 1
                        ? <Badge key={1} className={`badge-success text-white p-2`}>
                            {'ACCEPT'}
                        </Badge> : <Badge key={2} className={`badge-danger text-white p-2`}>
                            {'REJECTED'}
                        </Badge>
            },
            {
                title: "Created On",
                dataIndex: 'created_at',
            },
            {
                title: "Updated On",
                dataIndex: 'updated_at',
            },
        ],
        openDiamondRecommendationModal: false,
        selectedRow: {},
        selectedDiamonds: [],
        errors: {
            reason: ''
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserData()
    }

    componentDidUpdate(prevProps) {
    }

    getUserData = (filters = {}) => {
        this.props.fetchApprovalUsers({ page: 1, ...filters })
    }

    onPageChange = page => {
        this.getUserData({ page: (page + 1) })
    }

    searchUser = e => {
        e.preventDefault();
        if (isNaN(this.state.searchVal)) {
            this.getUserData({ name: this.state.searchVal })
        } else {
            this.getUserData({ mobile_no: this.state.searchVal })
        }
    }
    resetSearchUser = e => {
        e.preventDefault();
        this.setState({
            searchVal: ''
        }, () => this.getUserData({}))
    }

    blockUser = row => {
        const payload = {
            id: row.id,
            status: !Number(row.status) ? 'active' : 'block'
        }
        this.props.updateApprovalUsers(payload).then(resp => {
            this.getUserData()
        })
    }

    openDiamondRecommendationModal = row => {
        this.setState({
            selectedRow: row,
            openDiamondRecommendationModal: !this.state.openDiamondRecommendationModal,
        })
    }

    toggleAlertBox = (row, isRejectModal = false) => {
        this.setState({
            selectedRow: row,
            isOpenAlertBox: !this.state.isOpenAlertBox,
            isRejectModal,
            errors: {
                reason: ''
            }
        })
    }

    searchDiamond = e => {
        e.preventDefault();
        getSearchedDiamonds(1, { stone_id: this.state.searchDVal }).then(resp => {
            if (resp.data?.data?.data?.length > 0) {
                const diamonds = resp.data?.data?.data
                const selectedDiamonds = this.state.selectedDiamonds

                if (!selectedDiamonds.find(i => i.id === diamonds[0]?.id)) {
                    selectedDiamonds.push(diamonds[0])
                }

                this.setState({
                    selectedDiamonds,
                    searchDVal: ''
                })
            }
        })
    }

    confirmAction = () => {
        const payload = {
            id: this.state.selectedRow.id,
            status: 'active'
        }
        if (this.state.isRejectModal) {
            payload.status = 'block'
            payload['reason'] = this.state.reason
        }
        this.props.updateApprovalUsers(payload).then(resp => {
            this.setState({
                actionLoading: false,
            }, () => {
                this.toggleAlertBox({}, false)
                this.getUserData()
            })
        })
    }

    render() {
        const { columns, openDiamondRecommendationModal, selectedRow, selectedDiamonds } = this.state;
        const { loading, userList, currentPage, totalRow, perPage } = this.props;
        const buttonList = [
            //     <Button
            //         key={1}
            //         variant={`outline-primary`}
            //         className="btn-rounded m-1 text-capitalize right-float-patch"
            //         style={{ float: 'right' }}
            //         onClick={e => this.props.history.push('/user/form')}
            //     >
            //         Create User
            //   </Button>
        ]
        const buttonListmerge = [
            //     <Button
            //         key={1}
            //         variant={`outline-primary`}
            //         className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
            //         style={{ float: 'right' }}
            //         onClick={e => this.props.history.push('/user/form')}
            //     >
            //         Create User
            //   </Button>
        ]
        let switchlist = []
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="admin-users">
                <Breadcrumb
                    routeSegments={[
                        { name: 'User Approval List', path: "/user/approval" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <div className="row pad-2rem-petch">
                    <div className="col-md-4 form-group mb-3">
                        <form onSubmit={e => this.searchUser(e)}>
                            <div className="input-group border rounded-pill">
                                <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Name or Phone No" className="form-control form-control-rounded bg-none border-0" />
                                <div className="input-group-append border-0">
                                    {
                                        !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} className="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                    }
                                    <button type="submit" className="btn btn-primary btn-rounded">Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <Spin loading={loading}>
                            <Table
                                dataSource={userList}
                                bordered={true}
                                columns={columns}
                                // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                paginate={false}
                            />
                        </Spin>
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
                <AppModal
                    show={openDiamondRecommendationModal}
                    title={'Proof View'}
                    size="md"
                    onHide={() => this.openDiamondRecommendationModal({})}
                >
                    {
                        this.state?.selectedRow && <Image src={`${storageUrl}${this.state?.selectedRow}`} />
                    }
                </AppModal>
                <AppModal
                    show={this.state.isOpenAlertBox}
                    title={'Alert!'}
                    size="md"
                    onHide={() => this.toggleAlertBox({}, this.state.isRejectModal)}
                    footer={
                        <Spin loading={this.state.actionLoading}>
                            <Button variant={`outline-primary`} onClick={(e) => this.toggleAlertBox({}, this.state.isRejectModal)} className="ml-1">NO</Button>
                            <Button className="ml-1" onClick={(e) => {
                                if (!this.state.reason && this.state.isRejectModal) {
                                    this.setState({
                                        errors: {
                                            reason: 'Reason can\'t be blank'
                                        }
                                    })
                                    return
                                }
                                this.setState({
                                    actionLoading: true
                                }, () => this.confirmAction())
                            }}>YES</Button>
                        </Spin>
                    }
                >
                    Are you sure, you want to perform this action?
                    {
                        this.state.isRejectModal && <>
                            <br />
                            <label><b>Reason to Reject:</b></label>
                            <div className="form-group">
                                <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="reason"
                                    placeholder="Reason"
                                    onChange={e => this.setState({
                                        reason: e.target.value
                                    })}
                                />
                                {(this.state?.errors?.reason) && (
                                    <div className="text-danger mt-1 ml-1">
                                        {this.state?.errors?.reason}
                                    </div>
                                )}
                            </div>
                        </>
                    }
                </AppModal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.user.loading,
    userList: state.user.userApprovalList?.data,
    currentPage: state.user.userApprovalList?.current_page,
    totalRow: state.user.userApprovalList?.total,
    perPage: state.user.userApprovalList?.per_page,
});
const mapDispatchToProps = {
    fetchApprovalUsers,
    updateApprovalUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserApprovalList));