/* eslint-disable */
import React, { Component } from "react";
import './AdminUserDemandsStyle.scss';
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import { fetchUserDemands } from '../../../redux/actions/UserActions';

class AdminUserDemands extends Component {
    state = {
        windowWidth: window.innerWidth,
        columns: [
            // {
            //     title: 'Details',
            //     dataIndex: 'Details',
            //     render: (data, row) => (<>
            //         <OverlayTrigger
            //             placement={'top'}
            //             overlay={
            //                 <Tooltip id={`tooltip-top`}>
            //                     Edit
            //             </Tooltip>
            //             }
            //         >
            //             <Link className="link" to={`/user/form/${window.btoa(row.id)}`}><i className="far fa-edit"></i></Link>
            //         </OverlayTrigger>
            //         <OverlayTrigger
            //             placement={'top'}
            //             overlay={
            //                 <Tooltip id={`tooltip-top`}>
            //                     Delete
            //             </Tooltip>
            //             }
            //         >
            //             <i className={`fa fa-trash`}></i>

            //         </OverlayTrigger>
            //     </>)
            // },
            {
                title: 'Demand ID',
                dataIndex: 'id',
                className: 'pl-2'
            },
            {
                title: 'User Name',
                dataIndex: 'user',
                render: (data, row) => (row.user.name)
            },
            {
                title: 'Mobile No',
                dataIndex: 'user',
                render: (data, row) => (row.user.mobile_no)
            },
            {
                title: "Requirements",
                dataIndex: 'requirements',
            },
            {
                title: "Status",
                dataIndex: 'status',
                render: data => data ? 'Active' : 'Inactive',
            },
            {
                title: "Created At",
                dataIndex: 'created_at',
            },
        ],
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserDemandsData()
    }

    componentDidUpdate(prevProps) {
    }

    getUserDemandsData = (filters = {}) => {
        this.props.fetchUserDemands({ page: 1 })
    }

    onPageChange = page => {
        this.getUserDemandsData({ page: (page + 1) })
    }

    render() {
        const { columns } = this.state;
        const { loading, demandsList, currentPage, totalRow, perPage } = this.props;
        // const buttonList = [
        //     <Button
        //         key={1}
        //         variant={`outline-primary`}
        //         className="btn-rounded m-1 text-capitalize right-float-patch"
        //         style={{ float: 'right' }}
        //         onClick={e => this.props.history.push('/user/form')}
        //     >
        //         Create User
        //   </Button>
        // ]
        // const buttonListmerge = [
        //     <Button
        //         key={1}
        //         variant={`outline-primary`}
        //         className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        //         style={{ float: 'right' }}
        //         onClick={e => this.props.history.push('/user/form')}
        //     >
        //         Create User
        //   </Button>
        // ]
        // let switchlist = []
        // if (this.state.windowWidth > 600) {
        //     switchlist = [...switchlist, ...buttonListmerge]
        // }
        return (
            <div className="admin-demands">
                <Breadcrumb
                    routeSegments={[
                        { name: 'User Demands', path: "/demands" }
                    ]}
                // extra={switchlist}
                // patchingclass={'pad-2rem-petch'}
                // switchlist_bc={buttonList}
                ></Breadcrumb>
                <div className="row pad-2rem-petch">
                    {/* <div className="col-md-4 form-group mb-3">
                        <form onSubmit={e => this.searchUser(e)}>
                            <div class="input-group border rounded-pill">
                                <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Name or Phone No" class="form-control form-control-rounded bg-none border-0" />
                                <div class="input-group-append border-0">
                                    {
                                        !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} class="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                    }
                                    <button type="submit" class="btn btn-primary btn-rounded">Search</button>
                                </div>
                            </div>
                        </form>
                    </div> */}
                    <div className="col-lg-12 col-md-12">
                        <Spin loading={loading}>
                            <Table
                                dataSource={demandsList}
                                bordered={true}
                                columns={columns}
                                // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                paginate={false}
                            />
                        </Spin>
                    </div>
                </div>
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.user.userDemandsLoading,
    demandsList: state.user.userDemandsData?.data,
    currentPage: state.user.userDemandsData?.current_page,
    totalRow: state.user.userDemandsData?.total,
    perPage: state.user.userDemandsData?.per_page,
});
const mapDispatchToProps = {
    fetchUserDemands
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUserDemands));