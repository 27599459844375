import React, { Component } from 'react'
import { Breadcrumb } from '@gull';
import Table from 'app/GullLayout/SharedComponents/table/Table';
import { fetchUserOrders } from '../../../redux/actions/UserActions';
import { updateDiamondPlaceOrder } from '../../../redux/actions/DiamondActions';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Button, Badge } from 'react-bootstrap';
import { NotificationManager } from 'react-notifications';
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import Spin from '@gull/components/Spin';

class AdminOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    title: 'Action',
                    dataIndex: 'action',
                    render: (data, row) => {
                        return <>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Send Whatsapp message
                                    </Tooltip>
                                }
                            >
                                <i onClick={e => this.openWhatsapp(row)} className="fab fa-whatsapp ml-1"></i>
                            </OverlayTrigger>
                            {
                                Number(row.status) === 1 && <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Accept Order
                                    </Tooltip>
                                    }
                                >
                                    <i onClick={e => this.toggleAlertBox(row, false)} className="fa fa-check ml-1"></i>
                                </OverlayTrigger>
                            }
                            {
                                (Number(row.status) === 1 || Number(row.status) === 2) && <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Reject Order
                                    </Tooltip>
                                    }
                                >
                                    <i onClick={e => this.toggleAlertBox(row, true)} className="fa fa-times ml-1"></i>
                                </OverlayTrigger>
                            }
                            {
                                (Number(row.status) === 2) && <OverlayTrigger
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`tooltip-top`}>
                                            Receivable Amount
                                    </Tooltip>
                                    }
                                >
                                    <i onClick={e => this.toggleRecievableAmt(row)} className="fas fa-receipt ml-1"></i>
                                </OverlayTrigger>
                            }

                        </>
                    }
                },
                {
                    title: 'Order ID',
                    dataIndex: 'id',
                },
                {
                    title: 'Ordered By',
                    dataIndex: 'user',
                    render: (data) => (data?.length ? <Link to={`/user/${window.btoa(data[0].id)}`}>{data[0].name}</Link> : '-')
                },
                {
                    title: 'Supplier Name',
                    dataIndex: 'diamonds',
                    render: (data) => data?.length ? (data[0].supplier_name) : '-'
                },
                {
                    title: 'Stone No',
                    dataIndex: 'diamonds',
                    render: (data) => (data?.length ? <Link to={`/diamond/${window.btoa(data[0].id)}`}>{data[0].stone_id}</Link> : '-')
                },
                {
                    title: 'Email',
                    dataIndex: 'user',
                    render: (data) => (data?.length ? data[0].email : '-')
                },
                {
                    title: 'Mobile No',
                    dataIndex: 'user',
                    render: (data) => (data?.length ? data[0].mobile_no : '-')
                },
                {
                    title: 'Pending Amount',
                    dataIndex: 'total_pending',
                    render: (data, row) => (Number(data) - Number(row.total_paid))
                },
                {
                    title: 'Paid Amount',
                    dataIndex: 'total_paid',
                    render: (data) => data
                },
                {
                    title: 'Remark',
                    dataIndex: 'remark'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (data, row) => {
                        let badge = '';
                        switch (Number(data)) {
                            case 1:
                                badge = <Badge key={1} className={`badge-warning text-white p-2`}>
                                    {'PENDING'}
                                </Badge>
                                break;
                            case 2:
                                badge = <Badge key={2} className={`badge-primary p-2`}>
                                    {'ACCEPTED'}
                                </Badge>
                                break;
                            case 3:
                                badge = <Badge key={0} className={`badge-danger p-2`}>
                                    {'REJCTED'}
                                </Badge>
                                break;
                            case 4:
                                badge = <Badge key={4} className={`badge-danger p-2`}>
                                    {'CANCELED'}
                                </Badge>
                                break;
                            case 0:
                                badge = <Badge key={0} className={`badge-success p-2`}>
                                    {'COMPLETED'}
                                </Badge>
                                break;
                            default:
                                badge = <Badge key={1} className={`badge-warning text-white p-2`}>
                                    {'PENDING'}
                                </Badge>
                                break;
                        }
                        if (Number(row.total_pending) === Number(row.total_paid))
                            badge = <Badge key={0} className={`badge-success p-2`}>
                                {'COMPLETED'}
                            </Badge>
                        return badge
                    }
                },
                {
                    title: 'Last Update On',
                    dataIndex: 'updated_at'
                },
                {
                    title: 'Order Date',
                    dataIndex: 'created_at'
                },
            ],
            isOpenAlertBox: false,
            selectedRow: {},
            page: 1,
            actionLoading: false,
            isOpenRecievableAmt: false,
            receivableAmtForm: {
                orderid: '',
                amount: ''
            }
        }
        const payload = { page: 1 }
        if (this.props.location.state?.orderId) {
            payload['id'] = this.props.location.state?.orderId
        }
        if (this.props.location.state?.userId) {
            payload['userid'] = this.props.location.state?.userId
        }
        this.props.fetchUserOrders(payload, true)
    }

    toggleAlertBox = (row, isRejectModal = false) => {
        this.setState({
            selectedRow: row,
            isOpenAlertBox: !this.state.isOpenAlertBox,
            isRejectModal
        })
    }

    toggleRecievableAmt = (row) => {
        this.setState({
            selectedRow: row,
            isOpenRecievableAmt: !this.state.isOpenRecievableAmt,
        })
    }

    confirmAction = () => {
        if (!this.state.selectedRow) {
            NotificationManager.error('No order selected');
            return 0
        }
        const row = this.state.selectedRow;
        this.props.updateDiamondPlaceOrder({ id: row.id, status: this.state.isRejectModal ? 3 : 2 }).then(resp => {
            if (resp?.data?.data) {
                this.setState({
                    isOpenAlertBox: false,
                    actionLoading: false
                })
                NotificationManager.success('Record Updated', 'Success')
                this.props.fetchUserOrders({ page: this.state.page }, true)
            } else {
                this.setState({
                    actionLoading: false,
                    isOpenAlertBox: false,
                })
            }
        }).catch(err => {
            console.log(err);
            this.setState({
                actionLoading: false
            })
            NotificationManager.error(err?.data?.error ? err.data.error : 'Could\'nt able to Update Record, Try again')
        })
    }

    onPageChange = page => {
        this.setState({
            page
        }, () => this.props.fetchUserOrders({ page: (page + 1) }, true))

    }

    openWhatsapp = row => {
        if (!row.user.length) {
            NotificationManager.error('whatsapp no is not available');
            return
        }

        window.open(`https://api.whatsapp.com/send?phone=${row.user[0].whatsapp_no}&app_absent=true`)
    }

    searchOrder = e => {
        e.preventDefault();
        if (!isNaN(this.state.searchVal)) {
            // this.getUserData({ mobile_no: this.state.searchVal })
            this.props.fetchUserOrders({ page: 1, id: this.state.searchVal }, true)
        } else {
            NotificationManager.error('Please a valid number')
        }
    }

    resetSearchUser = e => {
        e.preventDefault();
        this.setState({
            searchVal: ''
        }, () => this.props.fetchUserOrders({ page: 1 }, true))
    }

    recievedAmountAction = e => {
        e.preventDefault();
        const orderid = this.state.selectedRow?.id || this.state.receivableAmtForm.orderid
        if (!orderid) {
            NotificationManager.error('Order ID is required');
            return
        }
        if (!this.state.receivableAmtForm.amount) {
            NotificationManager.error('amount is required');
            return
        }

        const id = this.state.selectedRow?.id || this.state.receivableAmtForm.orderid
        const paidAmount = this.state.receivableAmtForm.amount;
        const payload = {
            id,
            paidAmount,
        }
        this.props.updateDiamondPlaceOrder(payload)
            .then(resp => {
                if (resp?.data?.data) {
                    this.setState({
                        isOpenRecievableAmt: false,
                        amountLoading: false
                    })
                    NotificationManager.success('Record Updated', 'Success')
                    this.props.fetchUserOrders({ page: this.state.page }, true)
                } else {
                    this.setState({
                        amountLoading: false,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    amountLoading: false
                })
                NotificationManager.error(err?.data?.error ? err.data.error : 'Could\'nt able to Update Record, Try again')
            })
    }


    render() {
        const { columns, isOpenAlertBox, actionLoading, isOpenRecievableAmt, selectedRow, receivableAmtForm, amountLoading } = this.state;
        const { orderData, orderLoading, currentPage, totalRow, perPage } = this.props;
        return (
            <div className="admin-orders">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Order List', path: "/orders" }
                    ]}
                    extra={[]}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={false}
                    isBackButton={!!this.props.location.state?.orderId}
                ></Breadcrumb>
                <Spin loading={orderLoading}>
                    <div className="row">
                        <div className="col-md-4 form-group mb-3">
                            <form onSubmit={e => this.searchOrder(e)}>
                                <div className="input-group border rounded-pill">
                                    <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Order ID" className="form-control form-control-rounded bg-none border-0" />
                                    <div className="input-group-append border-0">
                                        {
                                            !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} className="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                        }
                                        <button type="submit" className="btn btn-primary btn-rounded">Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-8">
                            <Button
                                key={1}
                                variant={`outline-primary`}
                                className="btn-rounded m-1 text-capitalize right-float-patch"
                                style={{ float: 'right' }}
                                onClick={e => this.toggleRecievableAmt()}
                            >
                                <i className="fas fa-receipt mr-2"></i> Receivable Amount
                            </Button>
                        </div>
                    </div>
                    <Table
                        dataSource={orderData}
                        bordered={true}
                        columns={columns}
                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                        paginate={false}
                    />
                    <div className="sticky-footer">
                        <div className="container">
                            <div className="d-flex justify-content-center mt-3 mb-3">
                                <ReactPaginate
                                    previousLabel={<i className="i-Previous"></i>}
                                    nextLabel={<i className="i-Next1"></i>}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={5}
                                    onPageChange={(page) => this.onPageChange(page.selected)}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                    forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                                />
                            </div>
                        </div>
                    </div>
                </Spin>
                <AppModal
                    show={isOpenAlertBox}
                    title={'Alert!'}
                    size="md"
                    onHide={() => this.setState({ isOpenAlertBox: false })}
                    footer={
                        <Spin loading={actionLoading}>
                            <Button variant={`outline-primary`} onClick={(e) => this.setState({ isOpenAlertBox: false })} className="ml-1">NO</Button>
                            <Button className="ml-1" onClick={e => this.confirmAction()}>YES</Button>
                        </Spin>
                    }
                >
                    Are you sure, you want to perform this action?
                </AppModal>
                <AppModal
                    show={isOpenRecievableAmt}
                    title={'Recievable Amount'}
                    size="md"
                    onHide={() => this.setState({ isOpenRecievableAmt: false })}
                    footer={
                        orderData?.length !== 0 &&
                        <Spin loading={amountLoading}>
                            <Button variant={`outline-primary`} onClick={() => this.setState({ isOpenRecievableAmt: false })} className="ml-1">Cancel</Button>
                            <Button className="ml-1" onClick={e => this.recievedAmountAction(e)}>Save</Button>
                        </Spin>
                    }
                >
                    {
                        orderData?.length !== 0
                            ? <Spin loading={amountLoading}>
                                <div className="form-group">
                                    <input
                                        className="form-control position-relative"
                                        type="text"
                                        name="orderid"
                                        onChange={e => { this.setState({ receivableAmtForm: { ...this.state.receivableAmtForm, [e.target.name]: e.target.value } }) }}
                                        value={selectedRow ? selectedRow?.id : receivableAmtForm.orderid}
                                        placeholder="Order Id"
                                        disabled={selectedRow?.id}
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control position-relative"
                                        type="number"
step="any"
                                        name="amount"
                                        onChange={e => { this.setState({ receivableAmtForm: { ...this.state.receivableAmtForm, [e.target.name]: e.target.value } }) }}
                                        value={receivableAmtForm.amount}
                                        placeholder="Received Amount"
                                    />
                                </div>
                            </Spin>
                            : <div className="text-center">
                                There is no orders
                            </div>
                    }
                </AppModal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderData: state.user?.userOrdersData?.data ? state.user?.userOrdersData?.data.length ? state.user?.userOrdersData?.data : Object.keys(state.user?.userOrdersData?.data).map(item => (state.user?.userOrdersData?.data[item])) : [],
    orderLoading: state.user?.userOrdersLoading,
    currentPage: state.user?.userOrdersData?.current_page,
    totalRow: state.user?.userOrdersData?.total,
    perPage: state.user?.userOrdersData?.per_page,
})

const mapDispatchToProps = {
    fetchUserOrders,
    updateDiamondPlaceOrder
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminOrders);
