import React, { Component } from "react";
import "./styles.scss";
import { Breadcrumb } from "@gull";
import { Button, Tab, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import FileUpload from "app/GullLayout/SharedComponents/fileupload/FileUpload";
import { fetchConfigs, updateConfig } from "../../../redux/actions/MiscActions";
import Spin from "@gull/components/Spin";
import { NotificationManager } from "react-notifications";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formLoading: false,
      slidersCount: ["slider1", "slider2", "slider3"],
      config: {
          count: 3
      },
      configType: "HOME_SLIDER",
    };
  }

  componentDidMount() {
    this.props.fetchConfigs({
      configType: this.state.configType,
    });
  }

  onUploadFinish = (url, type) => {
    this.setState({
      config: {
        ...this.state.config,
        [`${type}url`]: url,
      },
    });
  };

  onInputChange = (e) => {
    this.setState({
      config: {
        ...this.state.config,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const that = this;
    this.setState(
      {
        formLoading: true,
      },
      () => {
        const propConfig = this.props?.config?.length ? JSON.parse(this.props?.config[0]?.config) : {}
        const payload = {
          configType: this.state.configType,
          config: JSON.stringify({ ...propConfig, ...that.state.config }),
        };
        that.props
          .updateConfig(payload)
          .then((resp) => {
            if (resp) {
              NotificationManager.success(
                "Config Successfully updated",
                "Success"
              );
              that.setState({
                formLoading: false,
              });
            } else {
              that.setState({
                formLoading: false,
              });
            }
          })
          .catch((e) => {
            console.error(e);
            that.setState({
              formLoading: false,
            });
          });
      }
    );
  };
  render() {
    const { config, formLoading, slidersCount } = this.state;
    const propConfig = JSON.parse(this.props?.config[0]?.config ?? "{}");
    return (
      <div>
        <Breadcrumb
          routeSegments={[{ name: "Settings", path: "/settings" }]}
        ></Breadcrumb>
        <Spin loading={formLoading}>
          <div className="profile row">
            <div className="col-md-12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="d-flex  px-2">
                  <Nav.Item className="mr-2 flex-grow-1 text-center">
                    <Nav.Link eventKey="first">Home Slider</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                  <Nav.Item className="mr-2 flex-grow-1 text-center"></Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="row">
                      {slidersCount.map((item) => (
                        <div key={item} className="col-md-4">
                          <div
                            style={{
                              display: "flex",
                              alignContent: "center",
                              justifyContent: "center",
                            }}
                          >
                            <b>{item} </b>
                          </div>
                          <FileUpload
                            initalData={
                              config[`${item}url`] ??
                              propConfig[`${item}url`] ??
                              ""
                            }
                            isServerUpload={true}
                            onUploadFinish={this.onUploadFinish}
                            filepath={`${item}`}
                            fileSizeLimitInMb={2}
                          />
                          <input
                            onChange={this.onInputChange}
                            type="text"
                            className="form-control"
                            id="inputEmail3"
                            placeholder="Enter Text"
                            value={
                              config[`${item}text`] ??
                              propConfig[`${item}text`] ??
                              ""
                            }
                            name={`${item}text`}
                          />
                        </div>
                      ))}
                      <div className="col-md-12 mt-4">
                        <div className="col-md-3">
                          <Button
                            block
                            key={"primary"}
                            variant={`outline-primary`}
                            className="btn-rounded m-1 text-capitalize"
                            style={{ float: "right" }}
                            onClick={this.handleSubmit}
                            disabled={
                              JSON.stringify(propConfig) ===
                              JSON.stringify(config)
                            }
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  config: state.misc?.config,
});
const mapDispatchToProps = {
  fetchConfigs,
  updateConfig,
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
