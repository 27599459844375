import React, { Component } from "react";
import "./style.scss";
import { Breadcrumb } from "@gull";
import SimpleCard from "@gull/components/cards/SimpleCard";
import { Tab, Nav, Badge,Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getSavedSearch,
  getRecentSearch,
} from "../../../redux/actions/SearchDiamondAction";
import { AdminDashboardStats } from "../../../redux/actions/MiscActions";
import moment from "moment";
import Spin from "@gull/components/Spin";
import { fetchFeaturedDiamond } from "../../../redux/actions/DiamondActions";
import BulkDiamondUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkDiamondUpload";
import BulkDiamondImageUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkDiamondImageUpload";

class Dashboard extends Component {
  state = {
    windowWidth: window.innerWidth,
    cardList: [
      {
        icon: "total-enquiries-icon",
        subtitle: "Total Enquiries",
        title: "NA",
        onClick: (e) => this.props.history.push("enquiries"),
      },
      {
        icon: "doller-icon",
        title: `$ NA`,
        subtitle: "Pending Amount",
        onClick: (e) => this.openBankModal(),
      },
      {
        icon: "new-diamonds-icon",
        subtitle: "Total Diamonds",
        title: "NA",
        onClick: (e) => this.props.history.push("diamonds"),
      },
      {
        icon: "recommended-icond",
        subtitle: "Total Users",
        title: "NA",
        onClick: (e) => this.props.history.push("users"),
      },
      {
        icon: "necklace",
        subtitle: "Total Jewellery",
        title: "NA",
        onClick: (e) => this.props.history.push("jewellery"),
      },
    ],
    openBankModal: false,
    openEnquiryModal: false,
    enquiryPayload: {
      remark: "",
    },
  };
  componentDidMount() {
    this.props.AdminDashboardStats();
    this.props.fetchFeaturedDiamond({ featured: true, limit: 5 });
    if (!this.props.searchList) {
      this.props.getSavedSearch();
    }
    if (!this.props.recentList) {
      this.props.getRecentSearch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.adminStats !== prevProps.adminStats) {
      const { adminStats } = this.props;
      this.setState({
        cardList: [
          {
            icon: "total-enquiries-icon",
            subtitle: "Total Enquiries",
            title: adminStats.totalEnquiries || "NA",
            onClick: (e) => this.props.history.push("enquiries"),
          },
          {
            icon: "doller-icon",
            title: `$ ${adminStats.totalPendingAmount || "NA"}`,
            subtitle: "Pending Amount",
            onClick: (e) => this.openBankModal(),
          },
          {
            icon: "new-diamonds-icon",
            subtitle: "Total Diamonds",
            title: adminStats.diamondCount || "NA",
            onClick: (e) => this.props.history.push("diamonds"),
          },
          {
            icon: "recommended-icond",
            subtitle: "Total Users",
            title: adminStats.usersCount || "NA",
            onClick: (e) => this.props.history.push("users"),
          },
          {
            icon: "necklace",
            subtitle: "Total Jewellery",
            title: adminStats.jewelleryCount || "NA",
            onClick: (e) => this.props.history.push("jewellery"),
          },
        ],
      });
    }
  }

  handlePageClick = (data) => {
    let pageNumber = data.selected;
    let offset = Math.ceil(pageNumber * this.props.perPage);
    this.setState({ offset: offset }, () => {
      console.log(data);
    });
  };

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "delivered":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "not delivered":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  getStatusTextColor = (status) => {
    switch (status) {
      case "In Stock":
        return "text-success";
      case "Low Stock":
        return "text-warning";
      case "Out of Stock":
        return "text-danger";
      default:
        return "text-primary";
    }
  };

  openBankModal = () => {
    this.setState({
      openBankModal: !this.state.openBankModal,
    });
  };

  openEnquiryModal = () => {
    this.setState({
      openEnquiryModal: true,
    });
  };

  handleEnquirySubmit = (value, { isSubmitting }) => {
    const payload = {
      requirements: value.remark,
    };
    this.setState(
      {
        demandLoader: true,
      },
      () => this.props.createDiamondEnquiry(payload)
    );
  };

  getOrderStatus = (row) => {
    let badge = "";
    switch (Number(row.status)) {
      case 1:
        badge = (
          <Badge key={1} className={`badge-warning text-white p-2`}>
            {"PENDING"}
          </Badge>
        );
        break;
      case 2:
        badge = (
          <Badge key={2} className={`badge-primary p-2`}>
            {"ACCEPTED"}
          </Badge>
        );
        break;
      case 3:
        badge = (
          <Badge key={0} className={`badge-danger p-2`}>
            {"REJCTED"}
          </Badge>
        );
        break;
      case 4:
        badge = (
          <Badge key={4} className={`badge-danger p-2`}>
            {"CANCELED"}
          </Badge>
        );
        break;
      case 0:
        badge = (
          <Badge key={0} className={`badge-success p-2`}>
            {"COMPLETED"}
          </Badge>
        );
        break;
      default:
        badge = (
          <Badge key={1} className={`badge-warning text-white p-2`}>
            {"PENDING"}
          </Badge>
        );
        break;
    }
    if (Number(row.total_pending) === Number(row.total_paid))
      badge = (
        <Badge key={0} className={`badge-success p-2`}>
          {"COMPLETED"}
        </Badge>
      );
    return badge;
  };

  render() {
    let {
      cardList = [],
      // lastMonthSummery = [],
      // topAuthorList = [],
      // newUserList = [],
      // topProductList = []
    } = this.state;
    const { user, adminStatsloading, adminStats } = this.props;
    const buttonList = [
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push('/settings')}
      >
        {"Settings"}
      </Button>,
      <BulkDiamondUpload />,
      <BulkDiamondImageUpload />,
    ];
    const buttonListmerge = [
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push('/settings')}
      >
        {"Settings"}
      </Button>,
      <BulkDiamondUpload className={"parchdisplay"} />,
      <BulkDiamondImageUpload className={"parchdisplay"} />,
    ];
    let switchlist = [];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="report dashboard">
        <Breadcrumb
          routeSegments={[{ name: "Dashboard", path: "/dashboard" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-4 greet-card">
                  <div className="card-body">
                    <div className="card-text">
                      <p className="greeting-text">Welcome Back,</p>
                      <div className="row">
                        <div className="col-md-6 h4">{user.name}</div>
                        <div className="col-md-6 text-right login-time">
                          Last logged in :{" "}
                          {moment(this.props.user.last_login_date).format(
                            "DD MMM, YYYY hh:mm A"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {cardList.map((card, index) => (
                <div key={index} className="col-md-6">
                  <Spin loading={adminStatsloading}>
                    <div
                      className="card card-icon-bg card-icon-bg-primary o-hidden mb-4"
                      style={{ cursor: "pointer" }}
                      {...{ onClick: card.onClick }}
                    >
                      <div className="card-body text-center">
                        <div className="imagecard">
                          {card.usei ? (
                            <i className={card.icon}></i>
                          ) : (
                            <img
                              className="nav-icon"
                              alt="loading"
                              src={`${process.env.PUBLIC_URL}/assets/images/dashboard-icon/${card.icon}.svg`}
                            />
                          )}
                        </div>
                        <div className="ml-5">
                          <p className="text-muted mt-2 mb-0 text-capitalize">
                            {card.subtitle}
                          </p>
                          <p className="lead text-primary text-24 mb-2 text-capitalize">
                            {card.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              ))}
              <div className="col-md-12">
                <SimpleCard>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Nav variant="pills" className="d-flex px-2">
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        <Nav.Link eventKey="first">Today's Enquries</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        {/* <Nav.Link eventKey="s"></Nav.Link> */}
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Spin loading={adminStatsloading}>
                          {adminStats?.todayEnquiries?.length ? (
                            adminStats?.todayEnquiries.map((item, index) => (
                              <div
                                onClick={(e) =>
                                  this.props.history.push("/enquiries", {
                                    enquiryId: item.id,
                                  })
                                }
                                key={index}
                                className="d-flex border justify-content-between p-3 mb-3"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    <b>
                                      {item?.user?.length
                                        ? item.user[0].name
                                        : ""}
                                    </b>{" "}
                                    <br></br>
                                    {item.remark}
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <span className="text-small">
                                    {moment(item.updated_at).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <span className="text-small">
                                    {moment(item.updated_at).format(
                                      "hh : ss A"
                                    )}
                                  </span>
                                </div>
                                {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                              </div>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="text-center">
                                <svg
                                  width="184"
                                  height="152"
                                  viewBox="0 0 184 152"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <g transform="translate(24 31.67)">
                                      <ellipse
                                        fillOpacity=".8"
                                        fill="#F5F5F7"
                                        cx="67.797"
                                        cy="106.89"
                                        rx="67.797"
                                        ry="12.668"
                                      ></ellipse>
                                      <path
                                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                        fill="#AEB8C2"
                                      ></path>
                                      <path
                                        d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                        fill="url(#linearGradient-1)"
                                        transform="translate(13.56)"
                                      ></path>
                                      <path
                                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                        fill="#F5F5F7"
                                      ></path>
                                      <path
                                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                        fill="#DCE0E6"
                                      ></path>
                                    </g>
                                    <path
                                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                      fill="#DCE0E6"
                                    ></path>
                                    <g
                                      transform="translate(149.65 15.383)"
                                      fill="#FFF"
                                    >
                                      <ellipse
                                        cx="20.654"
                                        cy="3.167"
                                        rx="2.849"
                                        ry="2.815"
                                      ></ellipse>
                                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          )}
                        </Spin>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </SimpleCard>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <Spin loading={adminStatsloading}>
                  <div className="card mb-4">
                    <div className="card-header featured-header">
                      Amount Pending List
                    </div>
                    <div className="card-body">
                      {adminStats?.users_pending_amt?.length !== 0 ? (
                        <div
                          className="card-text tab-content pt-0"
                          style={{ maxHeight: 250, minHeight: 250 }}
                        >
                          <div
                            key={"head-1"}
                            className="d-flex border justify-content-between p-3 mb-3"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="flex-grow-1 w-50">
                              <span className="text-small">
                                <b>Customer Name</b> <br></br>
                              </span>
                            </div>
                            <div className="flex-grow-1 w-50">
                              <span className="text-small">
                                <b>Customer Phone</b> <br></br>
                              </span>
                            </div>
                            <div className="flex-grow-1 w-50">
                              <span className="text-small">
                                <b>Total Pending Amount</b>
                              </span>
                            </div>
                            <div className="flex-grow-1 w-50">
                              <span className="text-small">
                                <b>Total Paid Amount</b>
                              </span>
                            </div>
                            {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                          </div>
                          {adminStats?.users_pending_amt?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex border justify-content-between p-3 mb-3"
                                onClick={(e) =>
                                  this.props.history.push("/orders", {
                                    userId: item.userid,
                                  })
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    {item?.user?.length
                                      ? item.user[0].name
                                      : "-"}
                                  </span>
                                </div>
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    {item?.user?.length
                                      ? item.user[0].mobile_no
                                      : "-"}
                                  </span>
                                </div>
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    ${" "}
                                    {item.total_pending_amt -
                                      item.total_paid_amt}
                                  </span>
                                </div>
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    $ {item.total_paid_amt}
                                  </span>
                                </div>
                                {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="card-text">
                          <div className="text-center m-5">
                            <svg
                              width="184"
                              height="152"
                              viewBox="0 0 184 152"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <g transform="translate(24 31.67)">
                                  <ellipse
                                    fillOpacity=".8"
                                    fill="#F5F5F7"
                                    cx="67.797"
                                    cy="106.89"
                                    rx="67.797"
                                    ry="12.668"
                                  ></ellipse>
                                  <path
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                    fill="#AEB8C2"
                                  ></path>
                                  <path
                                    d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                    fill="url(#linearGradient-1)"
                                    transform="translate(13.56)"
                                  ></path>
                                  <path
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                    fill="#F5F5F7"
                                  ></path>
                                  <path
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                    fill="#DCE0E6"
                                  ></path>
                                </g>
                                <path
                                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                  fill="#DCE0E6"
                                ></path>
                                <g
                                  transform="translate(149.65 15.383)"
                                  fill="#FFF"
                                >
                                  <ellipse
                                    cx="20.654"
                                    cy="3.167"
                                    rx="2.849"
                                    ry="2.815"
                                  ></ellipse>
                                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Spin>
              </div>

              <div className="col-md-12">
                <SimpleCard>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Nav variant="pills" className="d-flex px-2">
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        <Nav.Link eventKey="first">Today's Orders</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="mr-2 flex-grow-1 text-center">
                        {/* <Nav.Link eventKey="s"></Nav.Link> */}
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Spin loading={adminStatsloading}>
                          {adminStats?.todayOrders?.length ? (
                            adminStats?.todayOrders.map((item, index) => (
                              <div
                                onClick={(e) =>
                                  this.props.history.push("/orders", {
                                    orderId: item.id,
                                  })
                                }
                                key={index}
                                className="d-flex border justify-content-between p-3 mb-3"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="flex-grow-1 w-50">
                                  <span className="text-small">
                                    <b>
                                      {item?.user?.length
                                        ? item.user[0].name
                                        : ""}
                                    </b>{" "}
                                    <br></br>
                                    {item.remark}
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <span className="text-small">
                                    {this.getOrderStatus(item)}
                                  </span>
                                </div>
                                <div className="flex-grow-1">
                                  <span className="text-small">
                                    {moment(item.updated_at).format(
                                      "DD-MM-YYYY hh:ss A"
                                    )}
                                  </span>
                                </div>
                                {/* <div className="flex-grow-1">
                                  <span className="text-small">
                                    <i className="i-Remove" />
                                  </span>
                                </div> */}
                              </div>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="text-center">
                                <svg
                                  width="184"
                                  height="152"
                                  viewBox="0 0 184 152"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <g transform="translate(24 31.67)">
                                      <ellipse
                                        fillOpacity=".8"
                                        fill="#F5F5F7"
                                        cx="67.797"
                                        cy="106.89"
                                        rx="67.797"
                                        ry="12.668"
                                      ></ellipse>
                                      <path
                                        d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                        fill="#AEB8C2"
                                      ></path>
                                      <path
                                        d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                        fill="url(#linearGradient-1)"
                                        transform="translate(13.56)"
                                      ></path>
                                      <path
                                        d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                        fill="#F5F5F7"
                                      ></path>
                                      <path
                                        d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                        fill="#DCE0E6"
                                      ></path>
                                    </g>
                                    <path
                                      d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                      fill="#DCE0E6"
                                    ></path>
                                    <g
                                      transform="translate(149.65 15.383)"
                                      fill="#FFF"
                                    >
                                      <ellipse
                                        cx="20.654"
                                        cy="3.167"
                                        rx="2.849"
                                        ry="2.815"
                                      ></ellipse>
                                      <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          )}
                        </Spin>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </SimpleCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  searchList: state.searchDiamond.savedSearches,
  savedSeachloading: state.searchDiamond.savedSeachloading,
  recentList: state.searchDiamond.recentSearches,
  recentSeachloading: state.searchDiamond.recentSeachloading,
  settings: state.layout.settings,
  adminStats: state.misc?.adminStats,
  adminStatsloading: state.misc?.adminStatsloading,
  featuredLoading: state.diamond.loading,
  featuredDiamondList: state.diamond.data,
});
export default connect(mapStateToProps, {
  getSavedSearch,
  AdminDashboardStats,
  getRecentSearch,
  fetchFeaturedDiamond,
})(Dashboard);
