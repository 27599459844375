import React, { Component } from 'react'
import { Breadcrumb } from '@gull';
import { Tab, Nav, Button } from "react-bootstrap";
import Table from 'app/GullLayout/SharedComponents/table/Table';
import { connect } from 'react-redux';
import { GetCategory, CreateCategory, UpdateCategory, DeleteCategory, CreateSubCategory, UpdateSubCategory, DeleteSubCategory } from '../../../redux/actions/JewelleryAction';
// import ReactPaginate from 'react-paginate';
import Spin from '@gull/components/Spin';
// import RightArrowAccordion from '@gull/components/accordions/RightArrowAccordion';
// import moment from 'moment'
import { NotificationManager } from 'react-notifications';

class AdminCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            columns: [
                {
                    title: "Code",
                    dataIndex: 'id'
                },
                {
                    title: 'Sub-Category Name',
                    dataIndex: 'name',
                    render: (data, row) => {
                        return !(row.id === this.state.subcategoryEditId)
                            ? row.name
                            : <div className="form-group">
                                <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="name"
                                    onChange={e => this.setState({ subcategoryName: e.target.value })}
                                    placeholder="Enter Sub Category Name"
                                    defaultValue={row.name}
                                />
                            </div>
                    }
                },
                {
                    title: 'Created On',
                    dataIndex: 'created_at',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    render: (data, row) => {
                        return !(row.id === this.state.subcategoryEditId)
                            ? <div
                                onClick={e => this.setState({ selectedSubCategory: row })}
                                key={row.id}
                                className={`d-flex justify-content-between`}
                            >
                                <div className="flex-grow-1" onClick={e => this.setState({ subcategoryEditId: row.id })} >
                                    <span className="text-small">
                                        <i className="fa fa-edit" />
                                    </span>
                                </div>
                                <div className="flex-grow-1" onClick={e => {
                                    this.props.DeleteSubCategory({ id: row.id }).then(resp => this.props.GetCategory())
                                }}>
                                    <span className="text-small text-danger">
                                        <i className="fa fa-trash" />
                                    </span>
                                </div>
                            </div>
                            : <div
                                className={`d-flex justify-content-between`}
                            >
                                <div className="flex-grow-1" onClick={e => {
                                    const { subcategoryName } = this.state;
                                    if (!subcategoryName) {
                                        NotificationManager.error('sub category name can\'t be blank')
                                        return
                                    }
                                    this.props.UpdateSubCategory({ name: subcategoryName, id: row.id }).then(resp => this.setState({ subcategoryName: '', subcategoryEditId: undefined }, () => this.props.GetCategory()))
                                }} >
                                    <span className="text-small">
                                        <i className="fa fa-check" />
                                    </span>
                                </div>
                                <div className="flex-grow-1" onClick={e => {
                                    this.setState({ subcategoryEditId: undefined })
                                }}>
                                    <span className="text-small text-danger">
                                        <i className="fa fa-times" />
                                    </span>
                                </div>
                            </div>
                    }
                },
            ],
            selectedCategoryIndex: 0,
            isCategoryCreating: false,
            isSubCategoryCreating: false,
            categoryEditId: undefined,
            subcategoryEditId: undefined,
        }

        this.props.GetCategory()
    }

    componentDidUpdate(oldProps) {
        if (oldProps.categoryList !== this.props.categoryList) {
            this.setState({
                selectedCategory: this.props.categoryList[this.state.selectedCategoryIndex]
            })
        }
    }


    render() {
        const { columns, selectedCategory, selectedCategoryIndex, isCategoryCreating, isSubCategoryCreating, categoryEditId } = this.state;
        const { categoryList, loading } = this.props;
        return <div className="admin-category">
            <Breadcrumb
                routeSegments={[
                    { name: 'Manage Category & Sub-Category', path: "/category" }
                ]}
                extra={[]}
                patchingclass={'pad-2rem-petch'}
                switchlist_bc={[]}
            ></Breadcrumb>
            <Spin loading={loading}>
                <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                    <div className="row">
                        <div className="col-md-4 p-2" style={{ height: '70vh', overflowY: 'scroll' }}>
                            <div
                                onClick={e => this.setState({ isCategoryCreating: !isCategoryCreating })}
                                key={'index-0'}
                                className={`d-flex justify-content-between p-3 mb-3 ${!isCategoryCreating ? '' : 'text-danger'}`}
                                style={{ cursor: 'pointer', border: `1px solid ${!isCategoryCreating ? '#dee2e6' : 'red'}`, borderRadius: 10 }}
                            >
                                <div className="flex-grow-1 w-100 text-center">

                                    <span className="text-small">
                                        {!isCategoryCreating ? <b>+ Add New Category</b> : <b>Cancel</b>}
                                    </span>
                                </div>
                            </div>
                            {
                                isCategoryCreating && <div
                                    key={'index-1'}
                                    className={`d-flex justify-content-between p-3 mb-3`}
                                    style={{ border: '1px solid #dee2e6', borderRadius: 10 }}
                                >
                                    <div className="flex-grow-1 w-100 text-center">

                                        <div className="form-group">
                                            <input
                                                className="form-control position-relative"
                                                type="text"
                                                name="name"
                                                onChange={e => this.setState({ categoryName: e.target.value })}
                                                placeholder="Enter Category Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1 ml-2">
                                        <Button onClick={e => {

                                            const { categoryName } = this.state;
                                            if (!categoryName) {
                                                NotificationManager.error('category name can\'t be blank')
                                                return
                                            }
                                            this.props.CreateCategory({ name: categoryName }).then(resp => this.setState({ categoryName: '', isCategoryCreating: false }, () => this.props.GetCategory()))
                                        }}>+ Add</Button>
                                    </div>
                                </div>
                            }
                            {
                                categoryList?.length > 0
                                    ? categoryList?.map((item, index) => {

                                        return !(item.id === categoryEditId)
                                            ? <div
                                                onClick={e => this.setState({ selectedCategoryIndex: index, selectedCategory: item })}
                                                key={index}
                                                className={`d-flex justify-content-between p-3 mb-3`}
                                                style={{ cursor: 'pointer', border: (selectedCategoryIndex === index) ? '2px solid var(--primary)' : '1px solid #dee2e6', borderRadius: 10 }}
                                            >
                                                <div className="flex-grow-1 w-100">
                                                    <span className="text-small">
                                                        <b>{item.name || '-'} (CODE:- {item.id || '-'})</b> <br></br>
                                                        {item.updated_at || '-'}
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 m-2" onClick={e => this.setState({ categoryEditId: item.id })} >
                                                    <span className="text-small">
                                                        <i className="fa fa-edit" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 m-2" onClick={e => {
                                                    this.props.DeleteCategory({ id: item.id }).then(resp => this.setState({ selectedCategoryIndex: 0 }, () => this.props.GetCategory()))
                                                }}>
                                                    <span className="text-small text-danger">
                                                        <i className="fa fa-trash" />
                                                    </span>
                                                </div>
                                            </div>
                                            : <div
                                                key={index}
                                                className={`d-flex justify-content-between p-3 mb-3`}
                                                style={{ cursor: 'pointer', border: '2px solid var(--primary)', borderRadius: 10 }}
                                            >
                                                <div className="flex-grow-1 w-100">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control position-relative"
                                                            type="text"
                                                            name="name"
                                                            onChange={e => this.setState({ categoryName: e.target.value })}
                                                            placeholder="Enter Category Name"
                                                            defaultValue={item.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 m-2" onClick={e => {
                                                    const { categoryName } = this.state;
                                                    if (!categoryName) {
                                                        NotificationManager.error('category name can\'t be blank')
                                                        return
                                                    }
                                                    this.props.UpdateCategory({ name: categoryName, id: item.id }).then(resp => this.setState({ categoryName: '', categoryEditId: undefined }, () => this.props.GetCategory()))
                                                }} >
                                                    <span className="text-small">
                                                        <i className="fa fa-check" />
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 m-2" onClick={e => {
                                                    this.setState({ categoryEditId: undefined })
                                                }}>
                                                    <span className="text-small text-danger">
                                                        <i className="fa fa-times" />
                                                    </span>
                                                </div>
                                            </div>
                                    })
                                    : <Nav.Item className="w-100" key={1}>
                                        <Nav.Link style={{ color: 'black' }} eventKey={0}>
                                            <div className="text-center m-5">
                                                <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                            }
                        </div>
                        <div className="col-md-8">
                            <Tab.Content className="p-0">
                                <div
                                    onClick={e => this.setState({ isSubCategoryCreating: !isSubCategoryCreating })}
                                    key={'index-2'}
                                    className={`d-flex justify-content-between p-3 mb-3 ${!isSubCategoryCreating ? '' : 'text-danger'}`}
                                    style={{ cursor: 'pointer', border: `1px solid ${!isSubCategoryCreating ? '#dee2e6' : 'red'}`, borderRadius: 10 }}
                                >
                                    <div className="flex-grow-1 w-100 text-center">

                                        <span className="text-small">
                                            {!isSubCategoryCreating ? <b>+ Add New Sub Category</b> : <b>Cancel</b>}
                                        </span>
                                    </div>
                                </div>
                                {
                                    isSubCategoryCreating && <div
                                        key={'index-3'}
                                        className={`d-flex justify-content-between p-3 mb-3`}
                                        style={{ border: '1px solid #dee2e6', borderRadius: 10 }}
                                    >
                                        <div className="flex-grow-1 w-100 text-center">

                                            <div className="form-group">
                                                <input
                                                    className="form-control position-relative"
                                                    type="text"
                                                    name="name"
                                                    onChange={e => this.setState({ subcategoryName: e.target.value })}
                                                    placeholder="Enter Sub Category Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ml-2" >
                                            <Button onClick={e => {
                                                const { subcategoryName } = this.state;
                                                if (!subcategoryName) {
                                                    NotificationManager.error('Sub Category Name can\'t be blank')
                                                    return
                                                }
                                                this.props.CreateSubCategory({ name: subcategoryName, categoryId: selectedCategory.id }).then(resp => this.setState({ subcategoryName: '', isSubCategoryCreating: false }, () => this.props.GetCategory()))
                                            }}>+ Add</Button>
                                        </div>
                                    </div>
                                }
                                {
                                    categoryList?.length !== 0
                                        ? <Tab.Pane key={selectedCategory ? selectedCategory?.id : categoryList && categoryList[0]['id']} eventKey={0}>
                                            <div
                                                key={0}
                                                className={`d-flex justify-content-between p-3 mb-3`}
                                                style={{ border: '1px solid #dee2e6', borderRadius: 10 }}
                                            >
                                                <div className="flex-grow-1 w-100 text-center">
                                                    <Table
                                                        dataSource={selectedCategory ? selectedCategory?.subCategory : categoryList && categoryList[0]['subCategory']}
                                                        bordered={true}
                                                        columns={columns}
                                                        // onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                                        paginate={false}
                                                    />
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        : <div className="text-center m-5">
                                            <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                        </div>
                                }
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
                {/* <div className="sticky-footer">
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <ReactPaginate
                            previousLabel={<i className="i-Previous"></i>}
                            nextLabel={<i className="i-Next1"></i>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={(page) => this.onPageChange(page.selected)}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                        />
                    </div>
                </div>
            </div> */}
            </Spin>
        </div >
    }
}
const mapStateToProps = (state) => ({
    loading: state.jewellery.categoryloading,
    categoryList: state.jewellery?.category?.filter(c => c.name !== 'Diamond'),
})

const mapDispatchToProps = {
    GetCategory,
    CreateCategory,
    UpdateCategory,
    DeleteCategory,
    CreateSubCategory,
    UpdateSubCategory,
    DeleteSubCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCategory);