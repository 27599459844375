import {
    GET_FEATURED_DIAMOND_BEGIN,
    GET_FEATURED_DIAMOND_SUCESS,
    GET_NEW_DIAMOND_BEGIN,
    GET_NEW_DIAMOND_SUCESS,
    CREATE_ENQUIRY_BEGIN,
    CREATE_ENQUIRY_SUCESS,
    GET_DIAMOND_BY_ID_BEGIN,
    GET_DIAMOND_BY_ID_SUCESS,
    GET_DIAMOND_BY_ID_FAILED,
    GET_LAYOUTS_BEGIN,
    GET_LAYOUTS_SUCESS,
    GET_LAYOUTS_FAILED,
} from "../actions/DiamondActions";

const initialState = {
    loading: false,
    formList: []
};

const DiamondReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_FEATURED_DIAMOND_BEGIN: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_FEATURED_DIAMOND_SUCESS: {
            return {
                ...state,
                loading: false,
                ...{
                    ...action.payload,
                    data: action.payload.data.map(item=> { item.discount= `-${item.discount}`; item.j_discount= `-${item.j_discount}`; return item })
                }
            };
        }
        case GET_DIAMOND_BY_ID_BEGIN: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_DIAMOND_BY_ID_SUCESS: {
            return {
                ...state,
                loading: false,
                diamondDetails: [...action.payload]
            };
        }
        case GET_DIAMOND_BY_ID_FAILED: {
            return {
                ...state,
                loading: false,
                diamondDetails: [...action.payload]
            };
        }
        case GET_LAYOUTS_BEGIN: {
            return {
                ...state,
                loadingLayouts: true
            };
        }
        case GET_LAYOUTS_SUCESS: {
            return {
                ...state,
                loadingLayouts: false,
                layoutList: { ...action.payload }
            };
        }
        case GET_LAYOUTS_FAILED: {
            return {
                ...state,
                loadingLayouts: false,
                layoutList: {},
                layoutError: action.payload
            };
        }
        case GET_NEW_DIAMOND_BEGIN: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_NEW_DIAMOND_SUCESS: {
            return {
                ...state,
                loading: false,
                ...{
                    ...action.payload,
                    data: action.payload.data.map(item=> { item.discount= `-${item.discount}`; item.j_discount= `-${item.j_discount}`; return item })
                }
            };
        }
        case CREATE_ENQUIRY_BEGIN: {
            return {
                ...state,
                enquiryLoading: true
            };
        }
        case CREATE_ENQUIRY_SUCESS: {
            return {
                ...state,
                enquiryLoading: false,
                enquiryData: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default DiamondReducer;