const { apiAdapter } = require("./ApiAdapter")

const getCountries = (params) => {
    return apiAdapter({
        url: 'misc/countries',
        method: 'GET',
        params
    })
}
const getStates = (params) => {
    return apiAdapter({
        url: 'misc/states',
        method: 'GET',
        params
    })
}
const getCities = (params) => {
    return apiAdapter({
        url: 'misc/cities',
        method: 'GET',
        params
    })
}
const getUsers = (params) => {
    return apiAdapter({
        url: 'users',
        method: 'GET',
        params
    })
}
const getApprovalUsers = (params) => {
    return apiAdapter({
        url: 'user/approval',
        method: 'GET',
        params
    })
}
const getUserEnquiry = (params) => {
    return apiAdapter({
        url: 'diamond/enquiry',
        method: 'GET',
        params
    })
}
const getEnquiries = (params) => {
    return apiAdapter({
        url: 'diamond/enquiries',
        method: 'GET',
        params
    })
}
const getOrders = (params) => {
    return apiAdapter({
        url: 'diamond/orders',
        method: 'GET',
        params
    })
}
const createUser = (data) => {
    return apiAdapter({
        url: 'user/create',
        method: 'POST',
        data
    })
}
const updateUser = (data) => {
    return apiAdapter({
        url: 'user/update',
        method: 'PUT',
        data
    })
}
const updateApprovalUser = (data) => {
    return apiAdapter({
        url: 'user/approval/update',
        method: 'PUT',
        data
    })
}

const getDemands = (params = {}) => {
    return apiAdapter({
        url: 'user/demands',
        method: 'GET',
        params
    })
}
const userDashboardStats = () => {
    return apiAdapter({
        url: 'misc/stats/user/dashboard',
        method: 'GET'
    })
}
const adminDashboardStats = () => {
    return apiAdapter({
        url: 'misc/stats/admin/dashboard',
        method: 'GET'
    })
}
const getConfig = (data) => {
    return apiAdapter({
        url: 'misc/config',
        method: 'POST',
        data
    })
}
const setConfig = (data) => {
    return apiAdapter({
        url: 'misc/config',
        method: 'PUT',
        data
    })
}

module.exports = {
    getUsers,
    createUser,
    updateUser,
    getCountries,
    getStates,
    getCities,
    getUserEnquiry,
    getEnquiries,
    getOrders,
    getDemands,
    userDashboardStats,
    adminDashboardStats,
    getApprovalUsers,
    updateApprovalUser,
    getConfig,
    setConfig
}