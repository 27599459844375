import {
    GET_JEWELLERY_BEGIN,
    GET_JEWELLERY_SUCESS,
    GET_JEWELLERY_FAILED,
    GET_CATEGORY_BEGIN,
    GET_CATEGORY_SUCESS,
    GET_CATEGORY_FAILED,
} from "../actions/JewelleryAction";

const initialState = {
    loading: false,
};

const JewelleryReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_JEWELLERY_BEGIN: {
            return {
                ...state,
                loading: true
            };
        }
        case GET_JEWELLERY_SUCESS: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }
        case GET_JEWELLERY_FAILED: {
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            };
        }
        case GET_CATEGORY_BEGIN: {
            return {
                ...state,
                categoryloading: true
            };
        }
        case GET_CATEGORY_SUCESS: {
            return {
                ...state,
                categoryloading: false,
                category: action.payload
            };
        }
        case GET_CATEGORY_FAILED: {
            return {
                ...state,
                categoryloading: false,
                category: [],
                error: action.payload
            };
        }

        default: {
            return state;
        }
    }
};

export default JewelleryReducer;