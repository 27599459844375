import React from 'react'
import { useState } from 'react'

export default function Video(props) {
    const [isDefaultVideo, setIsDefaultVideo] = useState(false)
    return !isDefaultVideo
        ? (
            <video muted onError={e => { setIsDefaultVideo(true) }} {...props} />
        )
        : (
            <img width="100px" {...props} src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" style={{width: 100}} />
        )
}
