import React, { Component } from 'react'
import { Breadcrumb } from "@gull";
import { Tab, Nav, Button } from 'react-bootstrap';
import Spin from '@gull/components/Spin';
import Video from 'app/GullLayout/SharedComponents/Video';
import FileUpload from 'app/GullLayout/SharedComponents/fileupload/FileUpload';
import { VIDEO_FORMATS } from '@utils';
import { bulkDiamondImagesUpload } from 'app/services/DiamondService';
import { videoStory, deleteVideoStory } from 'app/services/FileStorageService';
import { NotificationManager } from 'react-notifications';
import { storageUrl } from 'environment/env';

export default class AdminUploadVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            isUploadActive: false,
            tabKey: 'Video Story',
            loading: true,
            videoUrls: []
        }
    }

    componentDidMount() {
        this.getVideoUrls();
    }

    getVideoUrls = () => {
        const videoSetType = this.state.tabKey.split(' ').join('_').toLowerCase();
        videoStory({ videoSetType }).then(({ data }) => {
            this.setState({
                videoUrls: data?.data || [],
                loading: false
            })
        })
    }
    bulkDiamondImageUpload = (fileurl, filepath) => {
        this.setState({
            loading: true
        }, () => {
            setTimeout(() => {

                const payload = new FormData();
                Array.from(fileurl).forEach(file => {
                    payload.append('file[]', file);
                })
                payload.append('filepath', filepath);
                bulkDiamondImagesUpload(payload)
                    .then(resp => {
                        if (resp.data.success) {
                            NotificationManager.success('new video uploaded', 'SUCCESS');
                        }
                        this.setState({
                            loading: true,
                            isUploadActive: false
                        }, () => this.getVideoUrls())
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            loading: false,
                            isUploadActive: false
                        })
                        NotificationManager.error('something went wrong. Please contact admin');
                    })
            }, 500)
        })
    }
    deleteVideoStorys = (id) => {
        deleteVideoStory({ id }).then(({ data }) => {
            if (data.success)
                this.getVideoUrls();
            else {
                if (data?.error) {
                    NotificationManager.error(data?.error);
                }
                this.setState({
                    loading: false
                })
            }
        })
    }
    render() {
        const { loading, videoUrls, isUploadActive, tabKey } = this.state
        const buttonList = [
            <Button
                key={5}
                variant={`outline-${isUploadActive ? 'danger' : 'primary'}`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                onClick={e => this.setState({ isUploadActive: !isUploadActive })}
            >
                {isUploadActive && 'Cancel'} Upload {tabKey}
            </Button>
        ]
        const buttonListmerge = [
            <Button
                key={5}
                variant={`outline-${isUploadActive ? 'danger' : 'primary'}`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                onClick={e => this.setState({ isUploadActive: !isUploadActive })}
            >
                {isUploadActive && 'Cancel'} Upload {tabKey}
            </Button>
        ]
        let switchlist = []
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="upload-video">
                <Breadcrumb
                    routeSegments={[
                        { name: "Upload Videos", path: "/upload/videos" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <Tab.Container id="left-tabs-example" activeKey={tabKey}>
                    <Nav variant="pills" className="d-flex px-2" onSelect={e => this.setState({ tabKey: e, loading: true }, () => this.getVideoUrls())}>
                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                            <Nav.Link eventKey="Video Story">Video Story</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                            <Nav.Link eventKey="Customer Product Video">Customer Product Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                            {/* <Nav.Link eventKey="s"></Nav.Link> */}
                        </Nav.Item>
                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                            {/* <Nav.Link eventKey="s"></Nav.Link> */}
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={tabKey}>
                            {
                                !isUploadActive
                                    ? <Spin loading={loading}>
                                        <div className="row">
                                            {
                                                videoUrls?.length ? videoUrls.map((video, key) => (
                                                    <div key={key} className="col-lg-3 col-md-3">
                                                        <div className="card mb-4 o-hidden ">
                                                            <div className="card-img-top align-items-center">
                                                                <Video
                                                                    className="card-img-top"
                                                                    src={`${storageUrl}${video.imageUrl}`}
                                                                    alt=""
                                                                    autoPlay
                                                                    controls
                                                                    style={{ maxHeight: 200, minHeight: 200 }}
                                                                    muted
                                                                    loop
                                                                />
                                                            </div>
                                                            <div className="card-body align-items-left">
                                                                <Button block variant={`outline-danger`} onClick={e => this.setState({ loading: true }, () => this.deleteVideoStorys(video.id))} > <i className="fa fa-trash"></i> Delete </Button>
                                                                {/* <label>Add On:-{
                                                            moment().format('D-mm-yyyy hh:mm')
                                                        }</label> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                    : <div className="col-lg-3 col-md-3 offset-lg-4">
                                                        <div className="d-flex justify-content-md-center align-items-center">
                                                            <svg width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g transform="translate(24 31.67)"><ellipse fillOpacity=".8" fill="#F5F5F7" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z" fill="#AEB8C2"></path><path d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" fill="url(#linearGradient-1)" transform="translate(13.56)"></path><path d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill="#F5F5F7"></path><path d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z" fill="#DCE0E6"></path></g><path d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z" fill="#DCE0E6"></path><g transform="translate(149.65 15.383)" fill="#FFF"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </Spin>
                                    : <Spin loading={loading}>
                                        <div className="d-flex justify-content-md-center align-items-center">
                                            <FileUpload
                                                allowedFiles={VIDEO_FORMATS}
                                                isMultiple={false}
                                                isServerUpload={false}
                                                onUploadFinish={this.bulkDiamondImageUpload}
                                                fileSizeLimitInMb={10}
                                                filepath={tabKey.split(' ').join('_').toLowerCase()} />
                                        </div>
                                    </Spin>
                            }
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        )
    }
}
