import React from 'react'
import './ribbon.scss';

export const Ribbon = ({ children, placement, className, color, style = {} }) => {
    return (
        <div style={style} class={`ribbon ribbon-${placement || 'top-left'} ${className || ''}`}>
            <span style={{ backgroundColor: color || '' }}>{children}</span>
        </div>
    )
}
