import {
    getJewelleries,
    createJewellery,
    updateJewellery,
    deleteJewellery,
    getCategory,
    createCategory,
    updateCategory,
    deleteCategory,
    createSubCategory,
    updateSubCategory,
    deleteSubCategory,
} from '../../services/JewelleryService';
import { NotificationManager } from 'react-notifications';

export const GET_JEWELLERY_BEGIN = 'GET_JEWELLERY_BEGIN';
export const GET_JEWELLERY_SUCESS = 'GET_JEWELLERY_SUCESS';
export const GET_JEWELLERY_FAILED = 'GET_JEWELLERY_FAILED';

export const GET_CATEGORY_BEGIN = 'GET_CATEGORY_BEGIN';
export const GET_CATEGORY_SUCESS = 'GET_CATEGORY_SUCESS';
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED';

export const getJewellery = (payload) => dispatch => {
    dispatch({
        type: GET_JEWELLERY_BEGIN
    });
    getJewelleries(payload).then((resp) => {
        if (resp?.data?.data) {
            dispatch({
                type: GET_JEWELLERY_SUCESS,
                payload: resp.data.data
            })
        } else {
            dispatch({
                type: GET_JEWELLERY_FAILED,
                payload: []
            })
        }
    })
};

export const CreateJewellery = (payload) => dispatch => {
    return createJewellery(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('Record added successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to add record, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to add record, Please try again.')

        })
};

export const UpdateJewellery = (payload) => dispatch => {
    return updateJewellery(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('Record updated successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to update record, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to update record, Please try again.')

        })
};

export const DeleteJewellery = (payload) => dispatch => {
    return deleteJewellery(payload)
        .then((resp) => {
            if (resp?.data?.success) {
                NotificationManager.success('Record deleted successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to delete record, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to delete record, Please try again.')

        })
};

export const GetCategory = (payload = {}) => dispatch => {
    dispatch({
        type: GET_CATEGORY_BEGIN
    });
    getCategory(payload)
        .then((resp) => {
            if (resp?.data?.data) {
                dispatch({
                    type: GET_CATEGORY_SUCESS,
                    payload: resp.data.data
                })
            } else {
                dispatch({
                    type: GET_CATEGORY_FAILED,
                    payload: []
                })
            }
        })
        .catch(e => {
            dispatch({
                type: GET_CATEGORY_FAILED,
                payload: []
            })
        })
};

export const CreateCategory = (payload) => dispatch => {
    return createCategory(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('Category added successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to add Category, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to add Category, Please try again.')

        })
};

export const UpdateCategory = (payload) => dispatch => {
    return updateCategory(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('Category updated successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to update Category, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to update Category, Please try again.')

        })
};

export const DeleteCategory = (payload) => dispatch => {
    return deleteCategory(payload)
        .then((resp) => {
            if (resp?.data?.success) {
                NotificationManager.success('Category deleted successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to delete Category, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to delete Category, Please try again.')

        })
};

export const CreateSubCategory = (payload) => dispatch => {
    return createSubCategory(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('SubCategory added successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to add SubCategory, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to add SubCategory, Please try again.')

        })
};

export const UpdateSubCategory = (payload) => dispatch => {
    return updateSubCategory(payload)
        .then((resp) => {
            if (resp?.data) {
                NotificationManager.success('SubCategory updated successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to update SubCategory, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to update SubCategory, Please try again.')

        })
};

export const DeleteSubCategory = (payload) => dispatch => {
    return deleteSubCategory(payload)
        .then((resp) => {
            if (resp?.data?.success) {
                NotificationManager.success('SubCategory deleted successfully')
                return resp?.data
            } else {
                console.log(resp?.data?.error);
                NotificationManager.error('Fail to delete SubCategory, Please try again.')
            }
        })
        .catch(e => {
            NotificationManager.success('Fail to delete SubCategory, Please try again.')

        })
};