/* eslint-disable */
import React, { Component } from "react";
import './AdminJewellery.scss';
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import { Button, OverlayTrigger, Tooltip, Badge, Form } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import { getJewellery, UpdateJewellery, DeleteJewellery } from '../../../redux/actions/JewelleryAction';
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import Switch from "app/GullLayout/SharedComponents/switch/Switch";
import { Ribbon } from "app/GullLayout/SharedComponents/ribbon";
import { storageUrl } from "environment/env";
import Image from "app/GullLayout/SharedComponents/Image";
import { FILE_FORMATS } from "@utils";

class AdminJewellery extends Component {
    state = {
        windowWidth: window.innerWidth,
        columns: [
            {
                title: 'Action',
                dataIndex: 'details',
                render: (data, row) => (<>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                {Number(row.is_featured) ? 'Remove from featured jewellery list' : 'Add to featured jewellery list'}
                            </Tooltip>
                        }
                    >
                        <i onClick={e => this.toggleFeatured({ ...row })} className={`fa${Number(row.is_featured) ? 's' : 'r'} fa-star`}></i>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Edit
                        </Tooltip>
                        }
                    >
                        <Link className="link" to={`/jewellery/form/${window.btoa(row.id)}`}><i className="ml-1 far fa-edit"></i></Link>
                    </OverlayTrigger>
                    {/* <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Delete
                            </Tooltip>
                        }
                    >
                        <i onClick={e => this.setState({ isOpenDeleteBox: true, selectedRow: row })} className={`fa fa-trash text-danger`}></i>

                    </OverlayTrigger> */}
                </>)
            },
            {
                title: 'ID',
                dataIndex: 'id',
            },
            {
                title: 'Stock-ID',
                dataIndex: 'stockId',
            },
            {
                title: 'Supplier Name',
                dataIndex: 'supplier_name',
                // render: (data, row) => (<Link to={`/user/${window.btoa(row.id)}`}>{data}</Link>)
            },
            {
                title: 'Title',
                dataIndex: 'title',
                // render: (data, row) => (<Link to={`/user/${window.btoa(row.id)}`}>{data}</Link>)
            },
            {
                title: "Description",
                dataIndex: 'description',
            },
            {
                title: "Category",
                dataIndex: 'categoryId',
                render: (data, row) => row?.category?.name || '-'
            },
            {
                title: "Sub-Category",
                dataIndex: 'sub_categoryId',
                render: (data, row) => row?.subCategory?.name || '-'
            },
            {
                title: "Gold Weight",
                dataIndex: 'gold_weight',
            },
            {
                title: "Gold Color",
                dataIndex: 'gold_color',
            },
            {
                title: "Diamond Weight",
                dataIndex: 'diamond_weight',
            },
            {
                title: "Diamond Shape",
                dataIndex: 'diamond_shape',
            },
            {
                title: "Diamond Color",
                dataIndex: 'diamond_color',
            },
            {
                title: "Diamond Clarity",
                dataIndex: 'diamond_clarity',
            },
            {
                title: "Cost Price",
                dataIndex: 'cost_price',
            },
            {
                title: "Selling Price",
                dataIndex: 'selling_price',
            },
            {
                title: "Discount",
                dataIndex: 'discount',
            },
            {
                title: "J-Selling Price",
                dataIndex: 'selling_price',
            },
            {
                title: "J-Discount",
                dataIndex: 'j_discount',
            },
            {
                title: "Status",
                dataIndex: 'status',
                className: "text-center",
                render: data => Number(data)
                    ? <Badge key={1} className={`badge-success text-white p-2`}>
                        {'ACTIVE'}
                    </Badge> : <Badge key={2} className={`badge-danger text-white p-2`}>
                        {'INACTIVE'}
                    </Badge>
            },
            {
                title: "Created At",
                dataIndex: 'created_at',
            },
        ],
        isOpenDeleteBox: false,
        selectedRow: {},
        selectedJewellery: [],
        sortWith: '',
        sortMethod: true,
        isListView: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getJewelleryData()
    }

    componentDidUpdate(prevProps) {
    }

    getJewelleryData = (filters = {}) => {
        this.props.getJewellery({ page: 1, ...filters })
    }

    onPageChange = page => {
        this.getJewelleryData({ page: (page + 1) })
    }

    onSort = (col) => {
        if (['details', 'categoryId', 'sub_categoryId'].includes(col)) return false
        this.setState({
            sortWith: col,
            sortMethod: col === this.state.sortWith ? !this.state.sortMethod : true
        }, () => this.getJewelleryData({
            sortWith: this.state.sortWith,
            sortMethod: this.state.sortMethod
        }))
    }

    searchUser = e => {
        e.preventDefault();
        this.getJewelleryData({ stockId: this.state.searchVal })
        // if (isNaN(this.state.searchVal)) {
        // } else {
        //     this.getJewelleryData({ mobile_no: this.state.searchVal })
        // }
    }
    resetSearchUser = e => {
        e.preventDefault();
        this.setState({
            searchVal: ''
        }, () => this.getJewelleryData({}))
    }

    toggleFeatured = row => {
        const payload = {
            id: row.id,
            is_featured: !row.is_featured
        }
        this.props.UpdateJewellery(payload).then(resp => {
            this.getJewelleryData()
        })
    }

    deletejewellery = () => {
        const payload = {
            id: this.state.selectedJewellery.map(item => (item.id)).join(','),
        }
        this.props.DeleteJewellery(payload).then(resp => {
            this.setState({
                selectedJewellery: []
            }, () => this.getJewelleryData())
        })
    }

    openDiamondRecommendationModal = row => {
        this.setState({
            selectedRow: row,
            openDiamondRecommendationModal: !this.state.openDiamondRecommendationModal,
            selectedDiamonds: [],
            searchDVal: ''
        })
    }

    onRowSelected = (isChecked, diamond) => {
        let selectedJewellery = this.state.selectedJewellery
        if (isChecked) {
            selectedJewellery.push(diamond)
        } else {
            selectedJewellery = selectedJewellery.filter(item => item.id !== diamond.id);
        }
        this.setState({
            selectedJewellery
        })
    }

    render() {
        const { columns, isOpenDeleteBox, isListView, selectedJewellery } = this.state;
        const { loading, jewelleryList, currentPage, totalRow, perPage } = this.props;
        const buttonList = [
            <Button
                key={1}
                variant={`outline-danger`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                style={{ float: 'right' }}
                onClick={e => this.setState({ isOpenDeleteBox: true })}
            >
                Delete Jewelleries
          </Button>,
            <Button
                key={2}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/jewellery/form')}
            >
                Add Jewellery
          </Button>,
            <Button
                key={3}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/category')}
            >
                Mange Category
          </Button>,
        ]
        const buttonListmerge = [
            <Button
                key={1}
                variant={`outline-danger`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                style={{ float: 'right' }}
                onClick={e => this.setState({ isOpenDeleteBox: true })}
            >
                Delete Jewelleries
          </Button>,
            <Button
                key={2}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/jewellery/form')}
            >
                Add Jewellery
          </Button>,
            <Button
                key={3}
                variant={`outline-primary`}
                className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
                style={{ float: 'right' }}
                onClick={e => this.props.history.push('/category')}
            >
                Mange Category
          </Button>,
        ]
        let switchlist = [
            <Switch key={0} value={isListView} onChange={val => this.setState({ isListView: val })} />
        ]
        if (this.state.windowWidth > 600) {
            switchlist = [...switchlist, ...buttonListmerge]
        }
        return (
            <div className="admin-jewelry">
                <Breadcrumb
                    routeSegments={[
                        { name: 'Jewellery List', path: "/jewellery" }
                    ]}
                    extra={switchlist}
                    patchingclass={'pad-2rem-petch'}
                    switchlist_bc={buttonList}
                ></Breadcrumb>
                <Spin loading={loading}>
                    {
                        isListView
                            ? <div className="row pad-2rem-petch">
                                <div className="col-md-4 form-group mb-3">
                                    <form onSubmit={e => this.searchUser(e)}>
                                        <div class="input-group border rounded-pill">
                                            <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Stock Id" class="form-control form-control-rounded bg-none border-0" />
                                            <div class="input-group-append border-0">
                                                {
                                                    !!this.state.searchVal && <button type="button" onClick={this.resetSearchUser} class="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                                }
                                                <button type="submit" class="btn btn-primary btn-rounded">Search</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <Table
                                        dataSource={jewelleryList}
                                        bordered={true}
                                        columns={columns}
                                        isCheckbox={true}
                                        onCheckboxSelect={(isChecked, row) => this.onRowSelected(isChecked, row)}
                                        paginate={false}
                                        selectedRows={this.state.selectedJewellery}
                                        onSort={this.onSort}
                                        sortedOn={this.state.sortWith}
                                        sortMethod={this.state.sortMethod}
                                    />
                                </div>
                            </div>
                            : <div className="row pad-2rem-petch">
                                {
                                    jewelleryList?.length && jewelleryList.map(jewl => (
                                        <div className="col-lg-3 col-md-3">
                                            <div className="card mb-4 o-hidden">
                                                {/* <Image
                                        className="card-img-top p-4"
                                        src={jewl.imageUrl ? `${storageUrl}${jewl.imageUrl}` : `${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                                        alt=""
                                    /> */}
                                                {/* {
                                        !!jewl?.images?.length > 0 && FILE_FORMATS.find(i => { return jewl?.images?.imageUrl ? jewl?.images?.imageUrl.includes(i) : false })
                                            ? <Image src={`${storageUrl}${jewl?.images?.imageUrl}`} className="card-img-top" alt={jewl?.stone_id} />
                                            : !!jewl?.images?.imageUrl ? <iframe title="image" className="card-img-top" src={jewl?.images?.imageUrl} width={'100%'} /> : <Image className="card-img-top" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                    } */}
                                                {
                                                    (jewl?.images?.length > 0 && FILE_FORMATS.find(i => { return jewl?.images[0]?.imageUrl ? jewl?.images[0]?.imageUrl.includes(i) : false }))
                                                        ? <Image src={`${storageUrl}${jewl?.images[0]?.imageUrl}`} className="card-img-top" alt={jewl?.stone_id} />
                                                        : <Image className="card-img-top" src={'hello'} alt="Jinagna logo" />
                                                }
                                                {/* {
                                                    !!jewl.j_discount && <div className="card-img-overlay text-left">
                                                        <Ribbon placement="top-left">
                                                            {jewl.j_discount} {!jewl.j_discount.includes('%') && '%'} off
                                        </Ribbon>
                                                    </div>
                                                } */}

                                                <div className="card-img-overlay text-right">
                                                    <Form.Check
                                                        name="checkbox1"
                                                        onChange={e => this.onRowSelected(e.target.checked, jewl)}
                                                        type="checkbox"
                                                        checked={selectedJewellery.find(d => d.stone_id === jewl.stone_id)}
                                                        value={jewl}
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="title">
                                                                <Link to={`/jewellery/form/${window.btoa(jewl.id)}`}>
                                                                    <b>{jewl.stockId}</b>
                                                                </Link>
                                                            </label>
                                                        </div>
                                                        <div className="col-12 small-desc">
                                                            <b>{jewl.title || '-'}</b>
                                                        </div>
                                                        <div className="col-12 small-desc" title="Category - subCategory">
                                                            {jewl?.category?.name || '-'} - {jewl?.subCategory?.name || '-'}
                                                        </div>
                                                        {/* <div className="col-12 small-desc" title="carat shape color clarity">
                                                            {jewl.diamond_weight || '-'} {jewl.diamond_shape || '-'} {jewl.diamond_color || '-'} {jewl.diamond_clarity || '-'}
                                                        </div> */}
                                                        <div className="col-8 mt-3">
                                                            $ {Number(jewl.j_selling_price)?.toFixed(2) || '-'}
                                                        </div>
                                                        {/* <div className="col-1 text-right mt-3">
                                                            <OverlayTrigger
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {Number(jewl.is_featured) ? 'Remove from featured jewellery list' : 'Add to featured jewellery list'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i onClick={e => this.toggleFeatured({ ...jewl })} className={`fa${Number(jewl.is_featured) ? 's' : 'r'} fa-star`}></i>
                                                            </OverlayTrigger>
                                                        </div> */}
                                                        <div className="col-4 text-right mt-3">
                                                            <OverlayTrigger
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        {Number(jewl.is_featured) ? 'Remove from featured jewellery list' : 'Add to featured jewellery list'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i onClick={e => this.toggleFeatured({ ...jewl })} className={`mr-2 fa${Number(jewl.is_featured) ? 's' : 'r'} fa-star`}></i>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                placement={'top'}
                                                                overlay={
                                                                    <Tooltip id={`tooltip-top`}>
                                                                        Edit
                        </Tooltip>
                                                                }
                                                            >
                                                                <Link className="link" to={`/jewellery/form/${window.btoa(jewl.id)}`}>Edit</Link>
                                                            </OverlayTrigger>
                                                            {/* <Link to={`/jewellery/form/${window.btoa(jewl.id)}`} >Edit</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="col-md-12">

                                </div>
                            </div>
                    }
                </Spin>
                <div className="breaker"></div>
                <div className="sticky-footer">
                    <div className="container">
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <ReactPaginate
                                previousLabel={<i className="i-Previous"></i>}
                                nextLabel={<i className="i-Next1"></i>}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={(Math.round((totalRow / perPage) >= 1 ? (totalRow / perPage) : 1))}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={(page) => this.onPageChange(page.selected)}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={(currentPage - 1) ? (currentPage - 1) : 0}
                            />
                        </div>
                    </div>
                </div>
                <AppModal
                    show={isOpenDeleteBox}
                    title={'Alert!'}
                    size="md"
                    onHide={() => this.setState({ isOpenDeleteBox: false })}
                    footer={
                        <Spin>
                            <Button variant={`outline-primary`} onClick={(e) => this.setState({ isOpenDeleteBox: false })} className="ml-1">NO</Button>
                            <Button className="ml-1" onClick={e => this.setState({ isOpenDeleteBox: false }, this.deletejewellery())}>YES</Button>
                        </Spin>
                    }
                >
                    Are you sure, you want to delete selected diamonds?
                </AppModal>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loading: state.jewellery.loading,
    jewelleryList: state.jewellery?.data,
    currentPage: state.jewellery?.current_page,
    totalRow: state.jewellery?.total,
    perPage: state.jewellery?.per_page,
});
const mapDispatchToProps = {
    getJewellery,
    UpdateJewellery,
    DeleteJewellery
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminJewellery));