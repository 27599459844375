import React, { Component } from 'react'
import './diamond-detail/DiamondDetail.scss';
import { SimpleCard } from '@gull'
import { Tab, Nav } from 'react-bootstrap';
import { MEDIA_TAB } from './search-report/SearchReport';
import Spin from '@gull/components/Spin';
import { apiUrl } from 'environment/env';
import Axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { storageUrl } from 'environment/env';
import Image from 'app/GullLayout/SharedComponents/Image';

export class PublicDiamondDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            diamondId: this.props?.match?.params?.stoneid,
            loading: true,
            diamondDetails: []
        }
    }
    componentDidMount() {
        if (this.state.diamondId) {
            Axios({
                url: `${apiUrl}public/diamond/details`,
                method: 'GET',
                params: {
                    userid: this.state.diamondId
                }
            }).then(resp => {
                this.setState({
                    loading: false,
                    diamondDetails: resp.data?.data?.data,
                })
            }).catch(error => {
                const errMsg = (error.response.data.error);
                NotificationManager.error(errMsg)
                this.setState({
                    loading: false
                })
            })
        }
    }
    render() {
        const { loading, diamondDetails } = this.state
        let diamond = {};
        if (diamondDetails?.length) {
            diamond = diamondDetails[0];
        }
        return (
            <div className="m-5 mt-3 diamond-page">
                <h1>Diamond Details</h1>
                <Spin loading={loading}>
                    <div className="row">
                        <div className="col-md-6">
                            <SimpleCard title="Basic Info" className="mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Stone No</b></div>
                                            <div className="col-md-6">{diamond?.stone_id ? diamond?.stone_id : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Shape</b></div>
                                            <div className="col-md-6">{diamond?.shape ? diamond?.shape : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Color</b></div>
                                            <div className="col-md-6">{diamond?.color ? diamond?.color : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Clarity</b></div>
                                            <div className="col-md-6">{diamond?.clarity ? diamond?.clarity : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Carat</b></div>
                                            <div className="col-md-6">{diamond?.carat ? diamond?.carat : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Cut</b></div>
                                            <div className="col-md-6">{diamond?.cut ? diamond?.cut : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Polish</b></div>
                                            <div className="col-md-6">{diamond?.polish ? diamond?.polish : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Symmetry</b></div>
                                            <div className="col-md-6">{diamond?.symmetry ? diamond?.symmetry : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Fluorescence</b></div>
                                            <div className="col-md-6">{diamond?.fluo ? diamond?.fluo : '-'}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6"><b>Price/Carat</b></div>
                                            <div className="col-md-6">{diamond?.price ? diamond?.price : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Amount</b></div>
                                            <div className="col-md-6">{diamond?.total_amount ? diamond?.total_amount : '-'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6"><b>Lab</b></div>
                                            <div className="col-md-6">{diamond?.lab ? diamond?.lab : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                            </SimpleCard>
                            <SimpleCard title="Additional Info" className="mb-3">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6"><b>Certificate No</b></div>
                                        <div className="col-md-6">{diamond?.certificate_no ? diamond?.certificate_no : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Location</b></div>
                                        <div className="col-md-6">{diamond?.location ? diamond?.location : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Measurement</b></div>
                                        <div className="col-md-6">{diamond?.measurement ? diamond?.measurement : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Depth</b></div>
                                        <div className="col-md-6">{diamond?.depth ? diamond?.depth : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Table</b></div>
                                        <div className="col-md-6">{diamond?.table ? diamond?.table : '-'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6"><b>Ratio</b></div>
                                        <div className="col-md-6">{diamond?.ratio ? diamond?.ratio : '-'}</div>
                                    </div>
                                </div>
                            </SimpleCard>
                        </div>
                        <div className="col-md-6">
                            <SimpleCard>
                                <Tab.Container id="left-tabs-example" defaultActiveKey="IMAGE">
                                    <Nav variant="pills" className="d-flex px-2">
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Video</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="mr-2 flex-grow-1 text-center">
                                            <Nav.Link eventKey={MEDIA_TAB.CERTI}>Certificate</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey={MEDIA_TAB.IMAGE} className="text-center">
                                            <Image src={`${storageUrl}${diamond?.img_url}`} className="img-fluid" alt={diamond?.stone_id} />

                                        </Tab.Pane>
                                        <Tab.Pane eventKey={MEDIA_TAB.VIDEO} className="text-center">
                                            <video width={200} muted className="img-fluid" src={`${storageUrl}${diamond?.video_url}`} autoPlay loop />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey={MEDIA_TAB.CERTI} className="text-center">
                                            <Image src={`${storageUrl}${diamond?.certi_url}`} className="img-fluid" alt={diamond?.stone_id} />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </SimpleCard>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}

export default PublicDiamondDetail;
