/* eslint-disable */
import React, { Component } from "react";
import "./AdminDiamondStyle.scss";
import { Breadcrumb } from "@gull";
import Table from "app/GullLayout/SharedComponents/table/Table";
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Tab,
  Nav,
  Form,
} from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Spin from "@gull/components/Spin";
import { connect } from "react-redux";
import {
  fetchFeaturedDiamond,
  fetchRecommendedDiamond,
  fetchSearchedDiamond,
  fetchNewDiamond,
  createDiamondEnquiry,
  createDiamondPlaceOrder,
  updateNewDiamond,
  exportDiamonds,
  deleteDiamond,
} from "../../../redux/actions/DiamondActions";
import { NotificationManager } from "react-notifications";
import BulkDiamondUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkDiamondUpload";
import BulkDiamondImageUpload from "app/GullLayout/SharedComponents/bulk-upload/BulkDiamondImageUpload";
import { storageUrl } from "environment/env";
import AppModal from "app/GullLayout/SharedComponents/modal/Modal";
import { MEDIA_TAB } from "../search-report/SearchReport";
import { FILE_FORMATS, VIDEO_FORMATS } from "@utils";
import Image from "app/GullLayout/SharedComponents/Image";
import Switch from "app/GullLayout/SharedComponents/switch/Switch";
import DiamondShapeImg from "./shapes-image";
import { Ribbon } from "app/GullLayout/SharedComponents/ribbon";

class AdminDiamond extends Component {
  state = {
    windowWidth: window.innerWidth,
    filepath: "diamond",
    columns: [
      {
        title: "Details",
        dataIndex: "details",
        render: (data, row) => (
          <>
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip id={`tooltip-top`}>Edit</Tooltip>}
            >
              <Link
                className="link"
                to={`/diamond/form/${window.btoa(row.id)}`}
              >
                <i className="far fa-edit"></i>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {Number(row.status) === 2
                    ? "Remove from HOT DEAL List"
                    : "Add to HOT DEAL List"}
                </Tooltip>
              }
            >
              <i
                onClick={(e) =>
                  this.updateFeatureStatus({
                    ...row,
                    status: Number(row.status) === 2 ? 1 : 2,
                  })
                }
                className={`fa${Number(row.status) === 2 ? "s" : "r"} fa-star`}
              ></i>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {Number(row.featured)
                    ? "Remove from featured diamond list"
                    : "Add to featured diamond list"}
                </Tooltip>
              }
            >
              <i
                onClick={(e) =>
                  this.updateFeatureStatus({
                    ...row,
                    featured: Number(row.featured) ? 0 : 1,
                  })
                }
                className={`fa${Number(row.featured) ? "s" : "r"} fa-star`}
              ></i>
            </OverlayTrigger>
            {/* <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Delete
                        </Tooltip>
                        }
                    >
                        <i className={`fa fa-trash`}></i>

                    </OverlayTrigger> */}
          </>
        ),
      },
      {
        title: "Media",
        dataIndex: "media",
        render: (data, row) => (
          <>
            {row.video_url ? (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>{MEDIA_TAB.VIDEO}</Tooltip>
                }
              >
                <i
                  onClick={(e) => {
                    this.setState({
                      selectedRow: row,
                      openModal: true,
                      selectedKey: MEDIA_TAB.VIDEO,
                    });
                  }}
                  className="i-Video-5 mr-1"
                ></i>
              </OverlayTrigger>
            ) : null}
            {row.img_url ? (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>{MEDIA_TAB.IMAGE}</Tooltip>
                }
              >
                <i
                  onClick={(e) => {
                    this.setState({
                      selectedRow: row,
                      openModal: true,
                      selectedKey: MEDIA_TAB.IMAGE,
                    });
                  }}
                  className="i-Camera mr-1"
                ></i>
              </OverlayTrigger>
            ) : null}
            {row.certi_url ? (
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>{MEDIA_TAB.CERTI}</Tooltip>
                }
              >
                <i
                  onClick={(e) => {
                    this.setState({
                      selectedRow: row,
                      openModal: true,
                      selectedKey: MEDIA_TAB.CERTI,
                    });
                  }}
                  className="i-Loading-3"
                ></i>
              </OverlayTrigger>
            ) : null}
          </>
        ),
      },
      {
        title: "Supplier Name",
        dataIndex: "supplier_name",
      },
      {
        title: "Stone No",
        dataIndex: "stone_id",
        render: (data, row) => (
          <Link to={`/diamond/form/${window.btoa(row.id)}`}>{data}</Link>
        ),
      },
      {
        title: "Shape",
        dataIndex: "shape",
      },
      {
        title: "Carat",
        dataIndex: "carat",
      },
      {
        title: "Color",
        dataIndex: "color",
      },
      {
        title: "Clarity",
        dataIndex: "clarity",
      },
      // {
      //     title: 'Finish',
      //     dataIndex: 'Finish',
      // },
      {
        title: "Cut",
        dataIndex: "cut",
      },
      {
        title: "Polish",
        dataIndex: "polish",
      },
      {
        title: "Sym",
        dataIndex: "symmetry",
      },
      {
        title: "Fluo.",
        dataIndex: "fluo",
      },
      {
        title: "Lab",
        dataIndex: "lab",
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Supplier Discount",
        dataIndex: "discount",
      },
      {
        title: "Supplier Price/Ct.",
        dataIndex: "price",
      },
      {
        title: "Supplier Total Amt.",
        dataIndex: "total_amount",
      },
      {
        title: "Jinagna Discount",
        dataIndex: "j_discount",
      },
      {
        title: "Jinagna Price/Ct.",
        dataIndex: "j_price",
      },
      {
        title: "Jinagna Total Amt.",
        dataIndex: "j_total_amount",
      },
      {
        title: "Jinagna Retail Discount",
        dataIndex: "retail_discount",
      },
      {
        title: "Jinagna Retail Price/Ct.",
        dataIndex: "retail_price",
      },
      {
        title: "Jinagna Retail Total Amt.",
        dataIndex: "retail_total_amount",
      },
      {
        title: "Measurements",
        dataIndex: "measurement",
      },
      {
        title: "Table%",
        dataIndex: "table",
      },
      {
        title: "Depth%",
        dataIndex: "depth",
      },
      {
        title: "Ratio",
        dataIndex: "ratio",
      },
      {
        title: "Certificate No.",
        dataIndex: "certificate_no",
      },
    ],
    openUploadModal: false,
    openImageUploadModal: false,
    selectedList: [],
    isDiamondSelected: false,
    searchVal: "",
    bulkDiamondUploadLoading: false,
    exportloading: false,
    isOpenDeleteBox: false,
    pageNo: 1,
    openModal: false,
    sortWith: "",
    sortMethod: true,
    isListView: true,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getDiamondData();
  }

  componentDidUpdate(prevProps) {}

  getDiamondData = () => {
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.props.fetchSearchedDiamond(1, filters);
  };

  updateFeatureStatus = (row) => {
    const payload = {
      id: row.id,
      featured: row.featured,
      status: row.status,
    };
    const that = this;
    this.props.updateNewDiamond(payload).then((resp) => {
      if (resp?.length > 0) {
        that.getDiamondData();
      }
    });
  };

  onPageChange = (page) => {
    this.setState({ pageNo: page + 1 });
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.props.fetchSearchedDiamond(page + 1, filters ? filters : {});
  };

  showSelectedDiamonds = (isShowing) => {
    let isDiamondSelected = !isShowing;
    if (this.state.selectedList.length === 0) {
      NotificationManager.warning(
        "Please select any diamond to show",
        "Warning",
        5000
      );
      isDiamondSelected = false;
    }

    this.setState({
      isDiamondSelected,
    });
  };

  onRowSelected = (isChecked, diamond) => {
    let selectedList = this.state.selectedList;
    if (isChecked) {
      selectedList.push(diamond);
    } else {
      selectedList = selectedList.filter((item) => item.id !== diamond.id);
    }
    this.setState({
      selectedList,
    });
  };

  searchDiamond = (e) => {
    e.preventDefault();
    this.props.fetchSearchedDiamond(1, { stone_id: this.state.searchVal });
  };
  resetSearchDiamond = (e) => {
    e.preventDefault();
    this.setState(
      {
        searchVal: "",
      },
      () => this.props.fetchSearchedDiamond(1, {})
    );
  };

  exportDiamond = (e) => {
    e.preventDefault();
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    const { selectedList } = this.state;
    const payout = {
      ...filters,
      stone_ids: selectedList?.map((diamond) => diamond?.stone_id),
    };
    this.setState(
      {
        exportloading: true,
      },
      () => {
        this.props
          .exportDiamonds(payout)
          .then((resp) => {
            if (resp?.data?.success) {
              window.open(`${storageUrl}${resp?.data?.url}`);
            } else {
              console.log("");
            }

            this.setState(
              {
                exportloading: false,
              },
              () => this.getDiamondData()
            );
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              exportloading: false,
            });
          });
      }
    );
  };

  openDeleteBox = () => {
    if (this.state.selectedList.length <= 0) {
      NotificationManager.warning(
        "Please select 1 or more diamond",
        "Warning",
        5000
      );
      return;
    } else {
      this.setState({
        isOpenDeleteBox: true,
      });
    }
  };

  confirmDeleteAction = () => {
    this.setState(
      {
        deleteLoading: true,
      },
      () => {
        const payload = {
          ids: btoa(this.state.selectedList.map((item) => item.id).join(",")),
        };
        this.props.deleteDiamond(payload).then((resp) => {
          this.setState({
            deleteLoading: false,
            isOpenDeleteBox: false,
          });
          const { filters } = this.props.location.state
            ? this.props.location.state
            : { filters: {} };
          this.props.fetchSearchedDiamond(
            this.state.pageNo,
            filters ? filters : {}
          );
        });
      }
    );
  };

  onSort = (col) => {
    if (["media", "details"].includes(col)) return false;
    const { filters } = this.props.location.state
      ? this.props.location.state
      : { filters: {} };
    this.setState(
      {
        sortWith: col,
        sortMethod: col === this.state.sortWith ? !this.state.sortMethod : true,
      },
      () =>
        this.props.fetchSearchedDiamond(1, {
          ...filters,
          sortWith: this.state.sortWith,
          sortMethod: this.state.sortMethod,
        })
    );
  };

  render() {
    const {
      columns,
      exportloading,
      isOpenDeleteBox,
      deleteLoading,
      openModal,
      selectedRow,
      selectedKey,
      isListView,
      selectedList,
    } = this.state;
    const { loading, diamondList, currentPage, totalRow, perPage } = this.props;
    const buttonList = [
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.openDeleteBox()}
      >
        {"Delete Diamonds"}
      </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/diamond/form")}
      >
        {"Add New Diamond"}
      </Button>,
      <BulkDiamondUpload />,
      <BulkDiamondImageUpload />,
      <Button
        key={2}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch"
        onClick={(e) =>
          this.props.history.push("/search/diamonds", {
            filters: this?.props?.location?.state?.filters,
          })
        }
      >
        {"Modify Search"}
      </Button>,
      <Spin loading={exportloading}>
        <Button
          key={3}
          variant={`outline-primary`}
          className="btn-rounded m-1 text-capitalize right-float-patch"
          onClick={this.exportDiamond}
        >
          {"Export Diamonds"}
        </Button>
      </Spin>,
    ];
    const buttonListmerge = [
      <Button
        key={4}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.openDeleteBox()}
      >
        {"Delete Diamonds"}
      </Button>,
      <Button
        key={1}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        style={{ float: "right" }}
        onClick={(e) => this.props.history.push("/diamond/form")}
      >
        {"Add New Diamond"}
      </Button>,
      <BulkDiamondUpload className={"parchdisplay"} />,
      <BulkDiamondImageUpload className={"parchdisplay"} />,
      <Button
        key={2}
        variant={`outline-primary`}
        className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
        onClick={(e) =>
          this.props.history.push("/search/diamonds", {
            filters: this?.props?.location?.state?.filters,
          })
        }
      >
        {"Modify Search"}
      </Button>,
      <Spin loading={exportloading}>
        <Button
          key={2}
          variant={`outline-primary`}
          className="btn-rounded m-1 text-capitalize right-float-patch parchdisplay"
          onClick={this.exportDiamond}
        >
          {"Export Diamonds"}
        </Button>
      </Spin>,
    ];
    let switchlist = [
      <Switch
        key={0}
        value={isListView}
        onChange={(val) => this.setState({ isListView: val })}
      />,
    ];
    if (this.state.windowWidth > 600) {
      switchlist = [...switchlist, ...buttonListmerge];
    }
    return (
      <div className="admin-diamond">
        <Breadcrumb
          routeSegments={[{ name: "Diamonds", path: "/diamonds" }]}
          extra={switchlist}
          patchingclass={"pad-2rem-petch"}
          switchlist_bc={buttonList}
        ></Breadcrumb>
        <Spin loading={loading}>
          {isListView ? (
            <div className="row">
              {/* <div className="col-md-4 form-group mb-3">
                        <form onSubmit={e => this.searchDiamond(e)}>
                            <div class="input-group border rounded-pill">
                                <input type="search" value={this.state.searchVal} onChange={e => this.setState({ searchVal: e.target.value })} placeholder="Search by Stone No" class="form-control form-control-rounded bg-none border-0" />
                                <div class="input-group-append border-0">
                                    {
                                        !!this.state.searchVal && <button type="button" onClick={this.resetSearchDiamond} class="btn btn-outline-primary btn-rounded"> <i className="fa fa-times" /> </button>
                                    }
                                    <button type="submit" class="btn btn-primary btn-rounded">Search</button>
                                </div>
                            </div>
                        </form>
                    </div> */}
              <div className="col-lg-12 col-md-12">
                <Table
                  dataSource={diamondList}
                  bordered={true}
                  columns={columns}
                  isCheckbox={true}
                  onCheckboxSelect={(isChecked, row) =>
                    this.onRowSelected(isChecked, row)
                  }
                  paginate={false}
                  selectedRows={this.state.selectedList}
                  onSort={this.onSort}
                  sortedOn={this.state.sortWith}
                  sortMethod={this.state.sortMethod}
                />
              </div>
            </div>
          ) : (
            <div className="row">
              {diamondList?.length &&
                diamondList.map((diamond) => (
                  <div className="col-lg-3 col-md-3">
                    <div className="card mb-4 o-hidden">
                      {/* <Image
                                            className="card-img-top p-4"
                                            src={diamond.img_url ? `${storageUrl}${diamond.img_url}` : `${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                                            alt=""
                                        /> */}
                      {/* {
                                            !!diamond?.img_url && FILE_FORMATS.find(i => { return diamond?.img_url ? diamond?.img_url.includes(i) : false })
                                                ? <Image src={`${storageUrl}${diamond?.img_url}`} className="card-img-top" alt={diamond?.stone_id} />
                                                : !!diamond?.img_url ? <iframe title="image" className="card-img-top" src={diamond?.img_url} width={'100%'} /> : <Image className="card-img-top" src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} alt="Jinagna logo" />
                                        } */}
                      {!!diamond?.img_url &&
                      FILE_FORMATS.find((i) => {
                        return diamond?.img_url
                          ? diamond?.img_url.includes(i)
                          : false;
                      }) ? (
                        <Image
                          src={`${storageUrl}${diamond?.img_url}`}
                          className="card-img-top"
                          alt={diamond?.stone_id}
                        />
                      ) : (
                        <Image
                          className="card-img-top"
                          src={
                            DiamondShapeImg[diamond?.shape?.toLowerCase()] ||
                            "hello"
                          }
                          alt="Jinagna logo"
                        />
                      )}
                      {!!diamond.j_discount && (
                        <div className="card-img-overlay text-left">
                          <Ribbon placement="top-left">
                            {diamond.j_discount}{" "}
                            {!diamond.j_discount.includes("%") && "%"} off
                          </Ribbon>
                        </div>
                      )}

                      <div className="card-img-overlay text-right">
                        <Form.Check
                          name="checkbox1"
                          onChange={(e) =>
                            this.onRowSelected(e.target.checked, diamond)
                          }
                          type="checkbox"
                          checked={selectedList.find(
                            (d) => d.stone_id === diamond.stone_id
                          )}
                          value={diamond}
                        />
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-8">
                            <label className="title">
                              <Link
                                to={`/diamond/form/${window.btoa(diamond.id)}`}
                              >
                                <b>{diamond.stone_id}</b>
                              </Link>
                            </label>
                          </div>
                          <div className="col-4 text-right set-color">
                            <span className="text-cirle-dot"></span>{" "}
                            <b>{diamond.lab}</b>
                          </div>
                          <div className="col-12 small-desc">
                            <b>
                              {diamond.carat || "-"} - Carat{" "}
                              {diamond.shape || "-"}
                            </b>
                          </div>
                          <div
                            className="col-12 small-desc"
                            title="color clarity cut polish symmetry"
                          >
                            {diamond.color || "-"} {diamond.clarity || "-"}{" "}
                            {diamond.cut || "-"} {diamond.polish || "-"}{" "}
                            {diamond.symmetry || "-"}
                          </div>
                          <div className="col-12 small-desc">
                            RAP: ${Number(diamond.rap).toFixed(2) || "-"}
                          </div>
                          <div className="col-12 small-desc">
                            Price/Carat: $
                            {Number(diamond.j_price)?.toFixed(2) || "-"}
                          </div>
                          <div className="col-8 mt-3">
                            ${" "}
                            {Number(diamond.j_total_amount)?.toFixed(2) || "-"}{" "}
                            {/* <span style={{ textDecoration: 'line-through' }}>{diamond.rap * diamond.carat}</span> */}
                          </div>
                          <div className="col-4 text-right mt-3">
                            <Link
                              to={`/diamond/form/${window.btoa(diamond.id)}`}
                            >
                              Edit
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="col-md-12"></div>
            </div>
          )}
        </Spin>

        <div className="breaker"></div>
        <div className="sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-center mt-3 mb-3">
              <ReactPaginate
                previousLabel={<i className="i-Previous"></i>}
                nextLabel={<i className="i-Next1"></i>}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(
                  totalRow / perPage >= 1 ? totalRow / perPage : 1
                )}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={(page) => this.onPageChange(page.selected)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={currentPage - 1 ? currentPage - 1 : 0}
              />
            </div>
          </div>
        </div>
        <AppModal
          show={openModal}
          title={selectedRow ? selectedRow.stone_id : ""}
          footer={false}
          size="md"
          onHide={() => this.setState({ openModal: false })}
        >
          <Tab.Container
            id="left-tabs-example"
            activeKey={selectedKey}
            defaultActiveKey="IMAGE"
            onSelect={(k) => this.setState({ selectedKey: k })}
          >
            <Nav variant="pills" className="d-flex px-2">
              {selectedRow?.img_url ? (
                <Nav.Item className="mr-2 flex-grow-1 text-center">
                  <Nav.Link eventKey={MEDIA_TAB.IMAGE}>Image</Nav.Link>
                </Nav.Item>
              ) : null}
              {selectedRow?.video_url ? (
                <Nav.Item className="mr-2 flex-grow-1 text-center">
                  <Nav.Link eventKey={MEDIA_TAB.VIDEO}>Video</Nav.Link>
                </Nav.Item>
              ) : null}
              {selectedRow?.certi_url ? (
                <Nav.Item className="mr-2 flex-grow-1 text-center">
                  <Nav.Link eventKey={MEDIA_TAB.CERTI}>Certificate</Nav.Link>
                </Nav.Item>
              ) : null}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={MEDIA_TAB.IMAGE} className="text-center">
                {!!selectedRow?.img_url &&
                FILE_FORMATS.find((i) => {
                  return selectedRow?.img_url
                    ? selectedRow?.img_url.includes(i)
                    : false;
                }) ? (
                  <Image
                    src={`${storageUrl}${selectedRow?.img_url}`}
                    class="img-fluid"
                    alt={selectedRow?.stone_id}
                  />
                ) : !!selectedRow?.img_url ? (
                  <iframe
                    title="image"
                    src={selectedRow?.img_url}
                    width={"100%"}
                    style={{ height: "60vh" }}
                  />
                ) : (
                  <Image
                    width="100px"
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                    alt="Jinagna logo"
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={MEDIA_TAB.VIDEO} className="text-center">
                {!!selectedRow?.video_url &&
                VIDEO_FORMATS.find((i) => {
                  return selectedRow?.video_url
                    ? selectedRow?.video_url.includes(i)
                    : false;
                }) ? (
                  <video
                    width={200}
                    muted
                    className="img-fluid"
                    src={`${storageUrl}${selectedRow?.video_url}`}
                    autoPlay
                    loop
                  />
                ) : !!selectedRow?.video_url ? (
                  <iframe
                    title="video"
                    src={selectedRow?.video_url}
                    width={"100%"}
                    style={{ height: "60vh" }}
                  />
                ) : (
                  <Image
                    width="100px"
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                    alt="Jinagna logo"
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey={MEDIA_TAB.CERTI} className="text-center">
                {!!selectedRow?.certi_url &&
                FILE_FORMATS.find((i) => {
                  return selectedRow?.certi_url
                    ? selectedRow?.certi_url.includes(i)
                    : false;
                }) ? (
                  <Image
                    src={`${storageUrl}${selectedRow?.certi_url}`}
                    class="img-fluid"
                    alt={selectedRow?.stone_id}
                  />
                ) : !!selectedRow?.certi_url ? (
                  <iframe
                    title="certi"
                    src={
                      !!selectedRow?.certi_url.includes(".pdf")
                        ? `${storageUrl}${selectedRow?.certi_url}`
                        : selectedRow?.certi_url
                    }
                    width={"100%"}
                    style={{ height: "60vh" }}
                  />
                ) : (
                  <Image
                    width="100px"
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`}
                    alt="Jinagna logo"
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </AppModal>
        <AppModal
          show={isOpenDeleteBox}
          title={"Alert!"}
          size="md"
          onHide={() => this.setState({ isOpenDeleteBox: false })}
          footer={
            <Spin loading={deleteLoading}>
              <Button
                variant={`outline-primary`}
                onClick={(e) => this.setState({ isOpenDeleteBox: false })}
                className="ml-1"
              >
                NO
              </Button>
              <Button
                className="ml-1"
                onClick={(e) => this.confirmDeleteAction()}
              >
                YES
              </Button>
            </Spin>
          }
        >
          Are you sure, you want to delete selected diamonds?
        </AppModal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.diamond.loading,
  diamondList: state.diamond.data,
  currentPage: state.diamond.current_page,
  totalRow: state.diamond.total,
  perPage: state.diamond.per_page,
  enquiryLoading: state.diamond.enquiryLoading,
  enquiryData: state.diamond.enquiryData,
});
const mapDispatchToProps = {
  fetchFeaturedDiamond,
  fetchRecommendedDiamond,
  fetchSearchedDiamond,
  fetchNewDiamond,
  createDiamondEnquiry,
  createDiamondPlaceOrder,
  updateNewDiamond,
  exportDiamonds,
  deleteDiamond,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminDiamond));
